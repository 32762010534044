import { put, takeEvery, call } from 'redux-saga/effects';
import post from '../postApiCaller';

const API_DATA = (payload) => {
  return post('audience/activate', 'POST', payload, true).then((response) => {
    return response;
  });
};

export const ACTIVATE_EXTERNAL_CHANNEL_SAGA =
  function* perform_audienceCountAction() {
    yield takeEvery('ACTIVATE_EXTERNAL_CHANNEL', function* (action) {
      yield put({ type: 'ACTIVATE_EXTERNAL_CHANNEL_STARTED' });
      try {
        const DATA = yield call(API_DATA.bind(this, action.payload));
        yield put({ type: 'ACTIVATE_EXTERNAL_CHANNEL_SUCCESS', payload: DATA });
      } catch (error) {
        yield put({ type: 'ACTIVATE_EXTERNAL_CHANNEL_FAILED', payload: error });
      }
    });
  };
