import { combineReducers } from 'redux';
// import loginData from "./loginReducer";
import audienceCount from './audienceCountReducer';
import autoCompleteDiscoverAudience from './autoCompleteDiscoverAudience';
import audienceByType from './audienceByTypeReducer';
import activateChannelList from './activateChannelListReducer';
import activateChannel from './activateAudienceChannelReducer';
import segmentDefinition from './getSegmentDefinitionReducer';
import ioFileUpload from './ioFileUpload';
import creativeFileUpload from './creativeFileUpload';
import activateExternalChannel from './activateExternalChannelReducer';
import saveSegmentDefinition from './saveSegmentDefinition';
import categories from './getCategoriesListReducer';
import brands from './getBrandsListReducer';
import audienceByBrandAndCategory from './getAudienceByBrandAndCategoryReducer';
import brandsOrCategories from './getBrandsOrCategoriesReducer';
import cities from './getCitiesReducer';
import states from './getStatesReducer';
import campaignPlaces from './getPlacesCreateCampaignMadalReduces';
import createCampaign from './createCampaignReducer';
import discoverAudienceByCategory from './discoverAudience/discoverAudienceByCategoryReducer';
import deletedFile from './deleteUploadedFileReducer';
import insightsData from './getInsightsData';

// Wrap all reducers in a container
export default combineReducers({
  // loginData,
  autoCompleteDiscoverAudience,
  audienceCount,
  audienceByType,
  activateChannelList,
  activateChannel,
  segmentDefinition,
  ioFileUpload,
  creativeFileUpload,
  activateExternalChannel,
  saveSegmentDefinition,
  categories,
  brands,
  audienceByBrandAndCategory,
  brandsOrCategories,
  states,
  cities,
  campaignPlaces,
  discoverAudienceByCategory,
  createCampaign,
  deletedFile,
  insightsData,
});
