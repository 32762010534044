import {
  ATTR_INSIGHT_PERFORMANCE,
  ATTR_INSIGHT_SEGMENTS,
  ATTR_INSIGHT_GEOGRAPHIC,
  ATTR_INSIGHT_DEMOGRAPHIC,
  ATTR_INSIGHT_ADVANCE_METRICS,
  ATTR_INSIGHT_BENCHMARK,
  ATTR_INSIGHT_CLEAR,
} from './insightConstants';

let initialState = {};

export default function (state, action) {
  state = state || initialState;

  switch (action.type) {
    case ATTR_INSIGHT_PERFORMANCE:
      return { ...state, performance: action.data };

    case ATTR_INSIGHT_SEGMENTS:
      return { ...state, audienceSegments: action.data };

    case ATTR_INSIGHT_GEOGRAPHIC:
      return { ...state, geographyAnalysis: action.data };

    case ATTR_INSIGHT_DEMOGRAPHIC:
      return { ...state, demographicAnalysis: action.data };

    case ATTR_INSIGHT_ADVANCE_METRICS:
      return { ...state, advanceMetrics: action.data };

    case ATTR_INSIGHT_BENCHMARK:
      return {
        ...state,
        benchmarks: action.data.benchmarks,
        quarterlyBenchmakingMetrics: action.data.quarterlyBenchmakingMetrics,
      };

    case ATTR_INSIGHT_CLEAR:
      return {};

    default:
      return state;
  }
}
