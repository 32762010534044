import {
  fetch,
  store2 as store,
  store3,
  destroy,
  getFileContent,
} from 'utils/httpUtil';
import { API_URL } from 'config/config';

const BASE_URL = API_URL + '/ooh';

export function fetchMyPlans(accountId) {
  return fetch(BASE_URL, `/plans/${accountId}`);
}

export const fetchMyPlan = (planId) => {
  return fetch(BASE_URL, `/plan/${planId}`);
};
export const validatePlanName = (planName) => {
  return fetch(BASE_URL, `/validate/planName?name=${planName}`);
};

export const fetchMyActivations = (customerId, accountId, countryId) => {
  return fetch(
    BASE_URL,
    `/activation/list?customerId=${customerId}&accountId=${accountId}&countryId=${countryId}`
  );
};

export const fetchActivationInsights = (payload) => {
  return store(
    `${API_URL}/audience/insight/activated-ad-group-status`,
    payload
  );
};

export const upDateTtdStatus = (planId) => {
  return store(`${BASE_URL}/status/update/${planId}`);
};

export const validateTTD = (campaignId, countryId, ttdSeatType) => {
  let countryIdQueryParam = '',
    ttdSeatTypeQueryParam = '';
  if (countryId) {
    countryIdQueryParam = `?countryId=${countryId}`;
  }
  if (!countryIdQueryParam && ttdSeatType) {
    ttdSeatTypeQueryParam = `?ttdSeatType=${ttdSeatType}`;
  } else {
    ttdSeatTypeQueryParam = `&ttdSeatType=${ttdSeatType}`;
  }
  return fetch(
    BASE_URL,
    `/ttd/validate/campaign/${campaignId}${countryIdQueryParam}${ttdSeatTypeQueryParam}`
  );
};
export function fetchAggregateInsights(payload) {
  return store(`${API_URL}/audience/insight/aggregate`, payload);
}

export function fetchTTDAdGroups(payload) {
  return store(`${API_URL}/audience/insight/ad-group`, payload);
}

export const fetchFootfallTrend = (payload) => {
  return store(`${BASE_URL}/footfall/trend`, payload);
};

export const fetchInsightsExcel = (payload) => {
  return store3(`${API_URL}/audience/insight/report`, payload);
};

export const fetchActivationPlan = (oohId) => {
  return fetch(BASE_URL, `/plan/download/${oohId}`);
};

export const fetchPoiScreenPlan = (oohId) =>
  getFileContent(
    `${API_URL}/screeniq/excel/report/poi-screen-data?planId=${oohId}`
  );

export const fetchOohChannels = (customerId, channel, countryId) => {
  return fetch(
    BASE_URL,
    `/channels?customerId=${customerId}${
      channel === 'ttd' ? `&channel=${channel}&countryId=${countryId}` : ``
    }`
  );
};

export const fetchPostCodeFilters = (countryId, customerId, type) => {
  return fetch(
    BASE_URL,
    `/filter/planner?countryId=${countryId}&customerId=${customerId}&type=${type}`
  );
};

export const deletePlan = (planId) => {
  return destroy(BASE_URL, `/plan/${planId}`);
};

export const fetchPostCodePackage = (countryId, customerId) => {
  return fetch(API_URL, `/screeniq/packages/postcode`);
};

export const fetchPoiCount = ({ packageId, searchText }) =>
  fetch(API_URL, `/screeniq/poi/count/${packageId}?searchText=${searchText}`);

export const uploadPackage = (payload) =>
  store(`${API_URL}/screeniq/package/poi`, payload);

export const validatePackageName = (poiPackageName) =>
  fetch(API_URL, `/screeniq/validate/poiPackageName?name=${poiPackageName}`);

export const fetchPoiPackageList = ({ size, pageNumber, searchText }) =>
  fetch(
    API_URL,
    `/screeniq/poiPackages?size=${size}&pageNo=${pageNumber}&searchText=${searchText}`
  );

export const fetchPoiPackagesCount = (searchText) =>
  fetch(API_URL, `/screeniq/poiPackage/count?searchText=${searchText}`);

export const fetchPoiList = ({ packageId, size, pageNumber, searchText }) =>
  fetch(
    API_URL,
    `/screeniq/pois/${packageId}?pageNo=${pageNumber}&size=${size}&searchText=${searchText}`
  );

export const deletePackage = (packageId) =>
  destroy(API_URL, `/screeniq/poiPackage/${packageId}`);
