export const checkCampaignPageBrandValues = (value) => {
  if (
    value &&
    value.changingStatus === 'success' &&
    value.campaignPageBrandData.status
  ) {
    return value.campaignPageBrandData.id;
  }
  return [];
};

export const checkCampaignPageStateValues = (value) => {
  if (value && value.changingStatus === 'success' && value.stateData.status) {
    return value.stateData.id;
  }
  return [];
};

export const checkCampaignCityValues = (value, id) => {
  if (value && value.changingStatus === 'success') {
    if (
      value.cityData &&
      Object.hasOwn(value.cityData, id) &&
      value.cityData[id].status
    ) {
      return value.cityData[id].id;
    }
    return [];
  }
  return [];
};

export const checkAudienceData = (value, id) => {
  if (value && value.changingStatus === 'success') {
    if (
      value.audienceData &&
      Object.hasOwn(value.audienceData, id) &&
      value.audienceData[id].status
    ) {
      return value.audienceData[id].id;
    }
    return [];
  }
  return [];
};

export const getMarkerDataForMap = (searchedPlaces, id) => {
  if (
    searchedPlaces &&
    searchedPlaces.searchPlaceData !== null &&
    Object.hasOwn(searchedPlaces.searchPlaceData, id) &&
    searchedPlaces.searchPlaceData[id] &&
    searchedPlaces.searchPlaceData[id].status &&
    searchedPlaces.searchPlaceData[id].id &&
    searchedPlaces.searchPlaceData[id].id.length
  ) {
    return searchedPlaces.searchPlaceData[id].id;
  }
  return [];
};

export const getSearchedPlaceCount = (searchedPlaces, id) => {
  if (
    searchedPlaces &&
    searchedPlaces.searchPlaceData !== null &&
    Object.hasOwn(searchedPlaces.searchPlaceData, id) &&
    searchedPlaces.searchPlaceData[id] &&
    searchedPlaces.searchPlaceData[id].status &&
    searchedPlaces.searchPlaceData[id].id
  ) {
    return searchedPlaces.searchPlaceData[id].id.length;
  }
  return 0;
};

export const isSearchedPlaceDataAvailable = (searchedPlaces, id) => {
  return !!(
    searchedPlaces &&
    searchedPlaces.searchPlaceData !== null &&
    Object.hasOwn(searchedPlaces.searchPlaceData, id) &&
    searchedPlaces.searchPlaceData[id] &&
    searchedPlaces.searchPlaceData[id].status &&
    searchedPlaces.searchPlaceData[id].id &&
    searchedPlaces.searchPlaceData[id].id.length > 0
  );
};

// Check For object already exist in an array of object

export const checkIfPlaceAlreadyExist = (list, value) => {
  const objectIndex = list.findIndex(
    (obj) => obj.placeDBId === value.placeDBId
  );
  return objectIndex >= 0;
};

// Remove object that already exist in an array of object

export const removeAlreadyExistedValue = (list, value) => {
  const objectIndex = list.findIndex(
    (obj) => obj.placeDBId === value.placeDBId
  );
  if (objectIndex >= 0) {
    list.splice(objectIndex, 1);
    return list;
  }
};

// Get checked status for selected places
export const getCheckedStatus = (selectedSearchedPlace, place) => {
  if (selectedSearchedPlace) {
    const index = selectedSearchedPlace.findIndex(
      (obj) => obj.placeDBId === place.placeDBId
    );
    return index >= 0;
  }
  return false;
};

// Get search params for count api
export const getIncludeParams = (values) => {
  const obj = { type: '', includeValues: [] };
  if (values.type === 'BRAND' || values.type === 'CATEGORY') {
    obj.type = values.type;
    obj.includeValues.push(values.filterId);
  }
  return [obj];
};
