import { put, takeEvery, call } from 'redux-saga/effects';
import plannerPostApiCaller from '../../postApiCaller';
import plannerGetApiCaller from '../../getApiCaller';

const API_DATA = (payload) => {
  return plannerPostApiCaller(
    'api/user/authenticate',
    'POST',
    payload,
    true
  ).then((response) => {
    return response;
  });
};

const API_DATA_USER = (payload) => {
  return plannerGetApiCaller(
    `api/user/email/${payload.email}`,
    'GET',
    true
  ).then((response) => {
    return response.id.accountDataList[1].countryId;
  });
};

export const LOGIN_SAGA = function* perform_loginAction() {
  yield takeEvery('LOGIN', function* (action) {
    yield put({ type: 'LOGIN_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      if (DATA.status === true)
        DATA.id.countryId = yield call(
          API_DATA_USER.bind(this, action.payload)
        );
      yield put({ type: 'LOGIN_SUCCESS', payload: DATA });
    } catch (error) {
      yield put({ type: 'LOGIN_FAILED', payload: error });
    }
  });
};
