export default function reducer(
  state = {
    insightsData: '',
  },
  action
) {
  switch (action.type) {
    case 'GET_INSIGHTS_METRICS_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_INSIGHTS_METRICS_SUCCESS': {
      const updatedData = action.payload;
      if (Object.keys(action.payload.id).length !== 0) {
        updatedData.impressions = action.payload.id.impressions;
        updatedData.frequency = action.payload.id.frequency;
        updatedData.mediaCPM = action.payload.id.mediaCPM;
        updatedData.planPricePerTargetAudienceReach =
          action.payload.id.planPricePerTargetAudienceReach;
        updatedData.performanceIndex = action.payload.id.performanceIndex;
        updatedData.id = action.payload.id;
      }
      return {
        ...state,
        changingStatus: 'success',
        metricsData: updatedData,
        latLngUpdate: false,
      };
    }
    case 'GET_INSIGHTS_METRICS_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        metricsData: action.payload,
      };
    }
    case 'GET_METRIS_DATA_STATUS': {
      return {
        ...state,
        latLngUpdate: true,
      };
    }
    default: {
      return state;
    }
  }
}
