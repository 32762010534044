export default function reducer(
  state = {
    estimatedReach: {},
  },
  action
) {
  switch (action.type) {
    case 'GET_ESTIMATED_REACH_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_ESTIMATED_REACH_SUCCESS': {
      const estimatedReach = action.payload.DATA;
      return {
        ...state,
        changingStatus: 'success',
        estimatedReach: {
          ...state.estimatedReach,
          [action.payload.id]: estimatedReach,
        },
      };
    }
    case 'GET_ESTIMATED_REACH_FAILED': {
      const estimatedReach = action.payload.error;
      return {
        ...state,
        changingStatus: 'failed',
        estimatedReach: {
          ...state.estimatedReach,
          [action.payload.id]: estimatedReach,
        },
      };
    }
    case 'GET_ESTIMATED_REACH_RESET': {
      return {
        ...state,
        changingStatus: 'reset',
        estimatedReach: {},
      };
    }
    default: {
      return state;
    }
  }
}
