export default function reducer(
  state = {
    brandsOrCategories: '',
  },
  action
) {
  switch (action.type) {
    case 'GET_BRANDS_OR_CATEGORIES_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_BRANDS_OR_CATEGORIES_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        brandsOrCategories: action.payload,
      };
    }
    case 'GET_BRANDS_OR_CATEGORIES_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        brandsOrCategories: action.payload,
      };
    }
    case 'CHECK_UNCHECK_BRANDS_OR_CATEGORIES': {
      return {
        ...state,
        changingStatus: 'failed',
        brandsOrCategories: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
