import { createSlice } from "@reduxjs/toolkit"
import { fulfilled, pending, rejected } from "commons/constants"
import { getPostcode } from "./postCode.action"


const skeleton = {
    loader: {
        pending: false,
        fulfilled: false,
        rejected: false
    },
    data: [],
    error: {}
}
const initialState = {
    postcode:skeleton
}
const Postcode = createSlice({
    name:"Postcode",
    initialState,
    reducers:{

    },
    extraReducers: builder=>{
        builder.addCase(getPostcode.pending,(state, action)=>{
            state.postcode.loader = pending
            state.postcode.data= []
        })
        builder.addCase(getPostcode.fulfilled,(state, action)=>{
            state.postcode.loader = fulfilled
            state.postcode.data =action.payload?.id ?? []
        })
        builder.addCase(getPostcode.rejected,(state, action)=>{
            state.postcode.loader = rejected
        })
    }
})


export default Postcode