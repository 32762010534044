const env = process.env.REACT_APP_ENV;
let API_ENV;
let CIP_ENV;

switch (env) {
  case 'dev':
    API_ENV = 'https://dev-be.app.journeysapac.lifesight.io';
    CIP_ENV = 'https://dev-developer.journeysapac.lifesight.io';
    break;
  case 'qa':
    API_ENV = 'https://qa-be.app.journeysapac.lifesight.io';
    CIP_ENV = 'https://qa-developer.journeysapac.lifesight.io';
    break;
  default:
    API_ENV = 'https://be.app.journeysapac.lifesight.io';
    CIP_ENV = 'https://developer.journeysapac.lifesight.io';
    break;
}

export const API_URL = API_ENV;
export const CIP_URL = CIP_ENV;
