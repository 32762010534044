export default function reducer(
  state = {
    screenProducts: [],
  },
  action
) {
  switch (action.type) {
    case 'GET_SCREEN_PRODUCTS_STARTED': {
      return {
        ...state,
        changingStatus: 'ongoing',
      };
    }
    case 'GET_SCREEN_PRODUCTS_SUCCESS': {
      return {
        ...state,
        screenProducts: action.payload.id,
        changingStatus: 'success',
      };
    }
    case 'GET_SCREEN_PRODUCTS_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
      };
    }
    default: {
      return state;
    }
  }
}
