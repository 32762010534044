import { getMediaFormatList } from '../../../utils/helper';

export default function reducer(
  state = {
    latLngData: '',
  },
  action
) {
  switch (action.type) {
    case 'GET_LAT_LNG_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_LAT_LNG_SUCCESS': {
      const data = action.payload.DATA;
      let billBoardsData = [];
      if (data.status && data.id && data.id.length) {
        billBoardsData = data.id.filter((billData) => {
          return billData.oohId;
        });
      }
      const mediaFormatData = getMediaFormatList(billBoardsData);

      data.id = billBoardsData;
      const temp1 = [];
      const mediaFormatArray = [
        ...new Set(data.id?.map((i) => i.mediaFormats[0])),
      ];
      mediaFormatArray
        .sort((a, b) => (a > b ? 1 : -1))
        .forEach((item, index) => {
          const obj = {};
          obj.item = item;
          obj.val = index;
          temp1.push(obj);
        });
      temp1?.forEach((item) => {
        data?.id?.forEach((item1) => {
          if (item1.mediaFormats[0] === item.item) {
            item1.numberMediaFormat = item.val;
          }
        });
      });
      data.mediaFormatData = mediaFormatData;
      data.poiDistance = action.payload.poiDistance;
      return {
        ...state,
        changingStatus: 'success',
        latLngData: data,
      };
    }
    case 'GET_LAT_LNG_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        latLngData: action.payload.DATA,
      };
    }
    case 'GET_LAT_LNG_RESET_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        latLngData: '',
      };
    }
    default: {
      return state;
    }
  }
}
