import { all, takeLatest, call, put } from 'redux-saga/effects';
import { fetchPoiCount } from '../../../ooh/plansService';
import {
  fetchPoiCountAction,
  fetchPoiCountFailedAction,
  fetchPoiCountSuccessAction,
} from './poiCount.reducer';

function* getPoiCount({ payload }) {
  try {
    const poiCountResponse = yield call(() => fetchPoiCount(payload), payload);
    yield put(fetchPoiCountSuccessAction(poiCountResponse));
  } catch (error) {
    fetchPoiCountFailedAction();
  }
}

export function* poiCountWatcher() {
  yield all([takeLatest(fetchPoiCountAction, getPoiCount)]);
}
