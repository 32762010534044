export const LS_USER_LOADING = 'LS_USER_LOADING';
export const LS_USER_SUCCESS = 'LS_USER_SUCCESS';
export const LS_USER_FAILURE = 'LS_USER_FAILURE';
export const LS_CUSTOMER_LOADING = 'LS_CUSTOMER_LOADING';
export const LS_CUSTOMER_SUCCESS = 'LS_CUSTOMER_SUCCESS';
export const LS_CUSTOMER_FAILURE = 'LS_CUSTOMER_FAILURE';
export const LS_SELECTED_ACCOUNT = 'LS_SELECTED_ACCOUNT';
export const LS_ACCESS_DATA = 'LS_ACCESS_DATA';
export const LS_LOGOUT = 'LS_LOGOUT';
export const LS_UPDATE_UNAUTHORIZED_ERROR_MESSAGE =
  'LS_UPDATE_UNAUTHORIZED_ERROR_MESSAGE';
export const LS_RESET_UNAUTHORIZED_ERROR_MESSAGE =
  'LS_RESET_UNAUTHORIZED_ERROR_MESSAGE';
