import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  oohSegments: {},
  status: 'INITIAL',
};

const audienceSlice = createSlice({
  name: 'audience',
  initialState,
  reducers: {
    fetchOOHSegmentsAction(state) {
      state.oohSegments = initialState.oohSegments;
      state.status = 'PROGRESS';
    },
    fetchOOHSegmentsSuccessAction(state, action) {
      state.oohSegments = action.payload;
      state.status = 'SUCCESS';
    },
    fetchOOHSegmentsFailedAction(state) {
      state.oohSegments = initialState.oohSegments;
      state.status = 'FAILED';
    },
  },
});

export const {
  fetchOOHSegmentsAction,
  fetchOOHSegmentsSuccessAction,
  fetchOOHSegmentsFailedAction,
} = audienceSlice.actions;
export default audienceSlice.reducer;
