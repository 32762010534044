import { all, takeLatest, call, put } from 'redux-saga/effects';
import { validatePackageName } from '../../../ooh/plansService';
import {
  validatePoiPackageNameAction,
  validatePoiPackageNameFailedAction,
  validatePoiPackageNameSuccessAction,
} from './validatePoiPackageName.reducer';

function* validatePoiPackageName({ payload }) {
  try {
    const validatePoiPackageNameResponse = yield call(
      () => validatePackageName(payload),
      payload
    );
    yield put(
      validatePoiPackageNameSuccessAction(validatePoiPackageNameResponse)
    );
  } catch (error) {
    validatePoiPackageNameFailedAction();
  }
}

export function* validatePoiPackageNameWatcher() {
  yield all([takeLatest(validatePoiPackageNameAction, validatePoiPackageName)]);
}
