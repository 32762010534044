// Import actionType constants
import {
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  LOG_OUT_SUCCESS,
  EMAIL_DATA,
  SET_SELECTED_ACCOUNT,
} from '../constants/actionType';

export function loginSuccess(data) {
  return {
    type: LOG_IN_SUCCESS,
    data,
  };
}

export function loginFailure(error) {
  return {
    type: LOG_IN_FAILURE,
    error,
  };
}

export function logoutSuccess() {
  return {
    type: LOG_OUT_SUCCESS,
  };
}

export function setUserData(data) {
  return {
    type: EMAIL_DATA,
    data: data ? data['id'] : null,
  };
}

export function setSelectedAccount(data) {
  return {
    type: SET_SELECTED_ACCOUNT,
    data: data,
  };
}
