import { all } from 'redux-saga/effects';
import { poiCountWatcher } from './poiCount/poiCount.saga';
import { poiListWatcher } from './poiList/poiList.saga';
import { poiPackagesCountWatcher } from './poiPackagesCount/poiPackagesCount.saga';
import { poiPackagesListWatcher } from './poiPackagesList/poiPackagesList.saga';
import { uploadPoiPackageWatcher } from './uploadPoiPackage/uploadPoiPackage.saga';
import { deletePoiPackageWatcher } from './deletePoiPackage/deletePoiPackage.saga';
import { validatePoiPackageNameWatcher } from './validatePoiPackageName/validatePoiPackageName.saga';

export const oohSaga = function* rootSaga() {
  yield all([
    poiCountWatcher(),
    poiListWatcher(),
    poiPackagesCountWatcher(),
    poiPackagesListWatcher(),
    uploadPoiPackageWatcher(),
    deletePoiPackageWatcher(),
    validatePoiPackageNameWatcher(),
  ]);
};
