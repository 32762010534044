import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import store from './store/store';
import history from 'utils/history';
import LandingLayout from 'layouts/Landing/LandingLayout';
import MainLayout from 'layouts/Main/MainLayout';
import 'assets/scss/custom-bootstrap.scss';
import 'assets/scss/paper-dashboard.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import './styles/globals.css';

const INTERCOM_OPTIONS = {
  alignment: 'left',
  customLauncherSelector: '#ls-intercom-link',
  hideDefaultLauncher: true,
  horizontalPadding: 64,
  verticalPadding: 10,
  actionColor: '#DA4761',
  backgroundColor: '#2A0C66',
};

ReactDOM.render(
  <>
    <Provider store={store}>
      <IntercomProvider
        appId={'lcjnbo0d'}
        autoBoot={true}
        autoBootProps={INTERCOM_OPTIONS}
      >
        <Router history={history}>
          <Switch>
            <Route
              path="/auth"
              render={(props) => <LandingLayout {...props} />}
            />
            <Route path="/" render={(props) => <MainLayout {...props} />} />
          </Switch>
        </Router>
      </IntercomProvider>
    </Provider>
  </>,
  document.getElementById('root')
);

// serviceWorker.unregister();
