export default function reducer(
  state = {
    campaignPageBrandData: '',
  },
  action
) {
  switch (action.type) {
    case 'GET_CREATE_CAMPAIGN_BRAND_DATA_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_CREATE_CAMPAIGN_BRAND_DATA_SUCCESS': {
      const brandData = action.payload;
      const updatedBrandData = brandData.id.map((data) => {
        return {
          ...data,
          label: data.filterName,
          value: data.filterId,
        };
      });
      brandData.id = updatedBrandData;
      return {
        ...state,
        changingStatus: 'success',
        campaignPageBrandData: brandData,
      };
    }
    case 'GET_CREATE_CAMPAIGN_BRAND_DATA_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        campaignPageBrandData: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
