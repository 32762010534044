export default function reducer(
  state = {
    campaignPlaces: '',
  },
  action
) {
  switch (action.type) {
    case 'GET_PLACES_CAMPAIGN_MODAL_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_PLACES_CAMPAIGN_MODAL_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        campaignPlaces: action.payload,
      };
    }
    case 'GET_PLACES_CAMPAIGN_MODAL_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        campaignPlaces: action.payload,
      };
    }
    case 'GET_PLACES_CAMPAIGN_MODAL_RESET': {
      return {
        ...state,
        changingStatus: 'reset',
        campaignPlaces: '',
      };
    }
    default: {
      return state;
    }
  }
}
