import {
  COUNTRIES_DATA,
  FETCH_ACCOUNT_DATA,
  FETCH_ACCOUNT_DETAILS,
  FETCH_USER_DATA,
  UNMOUNT,
  ADD_CUSTOMER_DATA,
  LOG_OUT_SUCCESS,
} from 'constants/actionType';

const LOAD = 'redux-form-examples/account/LOAD';
let initialState = {
  countries: null,
  accountData: [],
  userData: [],
  accountDetails: [],
};

const accountReducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case COUNTRIES_DATA:
      return {
        ...state,
        countries: action.data[0].data['id'],
      };

    case FETCH_ACCOUNT_DETAILS:
      Object.assign({}, state, {});
      return {
        ...state,
        accountData: action.data,
      };

    case FETCH_USER_DATA:
      Object.assign({}, state, {});
      return {
        ...state,
        userData: action.data,
      };

    case FETCH_ACCOUNT_DATA:
      Object.assign({}, state, {});
      return {
        ...state,
        accountDetails: action.data,
      };
    case ADD_CUSTOMER_DATA:
      return {
        ...state,
        customerData: action.data,
      };

    case LOAD:
      Object.assign({}, state, {});
      return {
        ...state,
        data: action.data,
      };
    case LOG_OUT_SUCCESS:
      return initialState;

    case UNMOUNT:
      return {};

    default:
      return state;
  }
};

export const load = (data) => ({ type: LOAD, data });
export default accountReducer;
