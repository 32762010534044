import axios from 'axios';
import { checkStatus } from '../utils/responseHandler';
import { API_URL } from 'config/config';
const URL = API_URL + '/';
// import { URL } from "../common/constants";

export default async (url, method = 'post', body, autherization) => {
  var headers = {};
  if (autherization) {
    headers = {
      'content-type': 'multipart/form-data',
      Authorization: localStorage.getItem('token'),
    };
  } else {
    headers = {
      'content-type': 'multipart/form-data',
    };
  }
  return axios({
    method,
    url: `${URL}${url}`,
    data: body,
    headers,
  })
    .then(checkStatus)
    .then(function (response) {
      return JSON.parse(JSON.stringify(response.data));
    })
    .catch((error) => {
      throw error;
    });
};
