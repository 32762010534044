export default function reducer(
  state = {
    discoverAudienceByCategory: '',
    discoverValue: '',
  },
  action
) {
  switch (action.type) {
    case 'DISCOVER_AUDIENCE_BY_CATEGORY_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'DISCOVER_AUDIENCE_BY_CATEGORY_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        discoverAudienceByCategory: action.payload.DATA,
        discoverCategoryName: action.payload.name,
      };
    }
    case 'DISCOVER_AUDIENCE_BY_CATEGORY_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        discoverAudienceByCategory: action.payload.DATA,
      };
    }
    default: {
      return state;
    }
  }
}
