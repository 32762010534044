import { audienceFilterData } from '../../../utils/metaData';

export default function reducer(
  state = {
    filteredValues: '',
  },
  action
) {
  switch (action.type) {
    case 'FILTER_VALUES_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'FILTER_VALUES_SUCCESS': {
      let filterData;
      let filterData1;
      if (action.payload.searchFlag) {
        filterData = action.payload.DATA;
        return {
          ...state,
          changingStatus: 'success',
          filteredValues: filterData,
          type: action.payload.type,
          value: action.payload.value,
        };
      } else if (action.payload.unMount) {
        filterData = action.payload.DATA;
        return {
          ...state,
          changingStatus: 'success',
          firstLoadFilterValues: filterData,
          filteredValues: filterData,
          type: action.payload.type,
          value: action.payload.value,
        };
      } else {
        filterData1 = action.payload.DATA;
        let updateFilterValue = [];
        if (
          [
            'billBoards',
            'mediaOwner',
            'mediaFormat',
            'screenOperatingHours',
            'acceptedFormats',
            'aspectRatio',
            'orientation',
            'facingDirection',
            'screenFormat',
            'mediaVehicle',
            'mediaCategoryName',
            'mediaType',
            'aimScore',
            'package',
            'programmaticAvailability',
            'screenType_print_digital',
            'screenTypeLighted',
            'multiPanelCount',
            'blocklist',
            'sa2',
          ].includes(action.payload.type)
        ) {
          filterData1.totalCount = action.payload.DATA.id.totalCount;
          filterData1.id = action.payload.DATA.id.oohPlannerDataList;
        } else if (action.payload.type === 'screenBookingTime') {
          action.payload.DATA.id.oohPlannerDataList.forEach((data, index) => {
            action.payload.DATA.id.oohPlannerDataList[index].filterName =
              `${data.filterName} seconds`;
          });
          filterData1.id = action.payload.DATA.id.oohPlannerDataList;
        } else if (
          (action.payload.type === 'screenType' &&
            action.payload.value === undefined) ||
          (action.payload.type === 'postCode' &&
            action.payload.value === undefined)
        ) {
          const level1ScreenTypes = [];
          const level2ScreenTypes = [];
          const level3ScreenTypes = [];
          const level4ScreenTypes = [];
          action.payload.DATA.id.oohPlannerDataList.forEach((data) => {
            data.filterDetailList.forEach((parentScreenType) => {
              const allChildScreenId = [];
              parentScreenType.filterDetailList.forEach((childScreenType) => {
                const allGrandChildScreenId = [];
                childScreenType.filterDetailList.forEach(
                  (grandChildScreenType) => {
                    const allGreatGrandChildScreenId = [];
                    if (action.payload.type === 'screenType') {
                      grandChildScreenType.filterDetailList.forEach(
                        (greatGrandChildScreenType) => {
                          allGreatGrandChildScreenId.push(
                            greatGrandChildScreenType.filterId
                          );
                          level4ScreenTypes.push({
                            filterId: greatGrandChildScreenType.filterId,
                            filterName: greatGrandChildScreenType.filterName,
                            countryId: greatGrandChildScreenType.countryId,
                            screenParentId: grandChildScreenType.filterId,
                          });
                        }
                      );
                    }
                    allGrandChildScreenId.push(grandChildScreenType.filterId);
                    if (action.payload.type === 'screenType') {
                      level3ScreenTypes.push({
                        filterId: grandChildScreenType.filterId,
                        filterName: grandChildScreenType.filterName,
                        countryId: grandChildScreenType.countryId,
                        screenParentId: childScreenType.filterId,
                        allGreatGrandChildScreenId,
                      });
                    } else {
                      level3ScreenTypes.push({
                        filterId: grandChildScreenType.filterId,
                        filterName: grandChildScreenType.filterName,
                        countryId: grandChildScreenType.countryId,
                        screenParentId: childScreenType.filterId,
                      });
                    }
                  }
                );
                allChildScreenId.push(childScreenType.filterId);
                level2ScreenTypes.push({
                  filterId: childScreenType.filterId,
                  filterName: childScreenType.filterName,
                  countryId: childScreenType.countryId,
                  screenParentId: childScreenType.screenParentId,
                  allGrandChildScreenId,
                });
              });
              level1ScreenTypes.push({
                filterId: parentScreenType.filterId,
                filterName: parentScreenType.filterName,
                countryId: parentScreenType.countryId,
                screenParentId: parentScreenType.screenParentId,
                allChildScreenId,
              });
            });
          });
          filterData1.id = [];

          let uniqueTypes = [...new Set(level1ScreenTypes)];
          filterData1.id.push({
            data: uniqueTypes,
            title: 'parentScreenTypeFilters',
          });
          uniqueTypes = [...new Set(level2ScreenTypes)];
          filterData1.id.push({
            data: uniqueTypes,
            title: 'childScreenTypeFilters',
          });
          uniqueTypes = [...new Set(level3ScreenTypes)];
          filterData1.id.push({
            data: uniqueTypes,
            title: 'grandchildScreenTypeFilters',
          });
          if (action.payload.type === 'screenType') {
            uniqueTypes = [...new Set(level4ScreenTypes)];
            if (uniqueTypes?.length) {
              filterData1.id.push({
                data: uniqueTypes,
                title: 'greatGrandChildScreenTypeFilters',
              });
            }
          }
          if (
            action.payload.type === 'postCode' &&
            action?.payload?.postCodePackages?.length
          ) {
            let postCodePackagesData = [];
            action?.payload?.postCodePackages?.forEach((packageData, index) => {
              postCodePackagesData.push({
                filterId: index,
                filterName: packageData.packageName,
                screenParentIds: packageData.screenParentIds,
                screenTypeGrandChildIds: packageData.screenTypeGrandChildIds,
                postCodes: packageData.postCodes,
              });
            });
            filterData1.id.push({
              data: [...postCodePackagesData],
              title: 'postCodePackages',
            });
          }
          filterData1.count = filterData1.id.length;
        }
        if (
          action.payload.type === 'audiences' &&
          action.payload.value === undefined
        ) {
          const mediaFormat = [];
          const count = action.payload.DATA.id.length;
          action.payload.DATA.id.forEach((data) => {
            mediaFormat.push(data.type);
          });
          const uniqueTypes = [...new Set(mediaFormat)];
          let arrayList = [];
          action.payload.DATA.id.forEach((listVal) => {
            arrayList = [
              ...arrayList,
              {
                ...listVal,
              },
            ];
            const a = uniqueTypes.map((uniqVal) => {
              const objArray = arrayList.filter(
                (filterList) => filterList.type === uniqVal
              );
              const availableData = audienceFilterData.find(
                (audType) => audType.type === uniqVal
              );
              return {
                title: availableData !== undefined ? availableData.name : null,
                data: [...objArray].map((o) => ({
                  ...o,
                  uniqueFilterId: o.type + o.filterId,
                })),
              };
            });
            filterData1.id = a.filter((i) => i.title !== null);
          });
          const newArray = filterData1.id.filter(
            (val) => val.title !== 'age' && val.title !== 'gender'
          );
          const hasDemographics = filterData1.id.filter(
            (val) => val.title === 'age' || val.title === 'gender'
          );
          updateFilterValue = [
            ...newArray,
            { title: 'demographics', children: [...hasDemographics] },
          ];
          filterData1.id = updateFilterValue;
          filterData1.count = count;
        }
        return {
          ...state,
          changingStatus: 'success',
          firstLoadFilterValues: filterData1,
          filteredValues: filterData1,
          type: action.payload.type,
          value: action.payload.value,
        };
      }
    }
    case 'FILTER_VALUES_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        filteredValues: action.payload.DATA,
      };
    }
    default: {
      return state;
    }
  }
}
