import { put, takeLatest, call } from 'redux-saga/effects';
import plannerPostApiCaller from '../../postApiCaller';

const API_DATA = (payload) => {
  return plannerPostApiCaller(
    `places/nearByPlaces`,
    'POST',
    payload,
    true
  ).then((response) => {
    return response;
  });
};

export const GET_POI_POINTS = function* perform_getPOIPoints() {
  yield takeLatest('GET_POI_POINTS', function* (action) {
    yield put({ type: 'GET_POI_POINTS_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({ type: 'GET_POI_POINTS_SUCCESS', payload: DATA });
    } catch (error) {
      yield put({ type: 'GET_POI_POINTS_FAILED', payload: error });
    }
  });
};
