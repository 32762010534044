import { combineReducers } from 'redux';
import login from './loginReducer';
import filteredValues from './getFilterValues';
import latLngData from './getLatLng';
import insightsData from './getInsightsData';
import savedMediaPlan from './saveMediaPlan';
import metricsData from './getMetricsData';
import myActivations from './setMyActivations';
import poiPointsData from './getPOIPointsData';
import screenProductsData from './getScreenProductsData';
import poiPackageData from './getPoiPackage';

// Wrap all reducers in a container
export default combineReducers({
  login,
  filteredValues,
  latLngData,
  insightsData,
  metricsData,
  savedMediaPlan,
  myActivations,
  poiPointsData,
  screenProductsData,
  poiPackageData,
});
