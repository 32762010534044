import {
  BANNER_ICON,
  NATIVE_ICON,
  VIDEO_ICON,
  CREATIVE_TAG_ICON,
} from '../common/imagePath';

export const dayData = [
  { label: 'Day', value: 'day' },
  { label: 'Week', value: 'week' },
  { label: 'Campaign', value: 'campaign' },
];

export const MANDATORY_FIELD_MESSAGE = 'is mandatory.';

export const MANDATORY_PLACE_MESSAGE =
  'Please search place and add alteast one place to create campaign';

export const CAMPAIGN_CREATE_SUCCESS_MESSAGE = 'Campaign Created';

export const asapData = [{ label: 'ASAP', value: 'asap' }];

export const creativeTargeting = [
  {
    imageSrc: BANNER_ICON,
    name: 'banner',
    imageAlt: 'banner_icon',
    title: 'Banners',
    type: 'file',
    accept: 'image/jpg,.zip,.rar,.7zip',
  },
  {
    imageSrc: CREATIVE_TAG_ICON,
    name: 'creativeTags',
    imageAlt: 'creative_tag_icon',
    title: 'Creative Tags',
    type: 'file',
    accept: 'image/jpg,.zip,.rar,.7zip',
  },
  {
    imageSrc: NATIVE_ICON,
    name: 'nativeIcon',
    imageAlt: 'native_icon',
    title: 'Native',
    type: 'file',
    accept: 'image/jpg,.zip,.rar,.7zip',
  },
  {
    imageSrc: VIDEO_ICON,
    name: 'videoIcon',
    imageAlt: 'video_icon',
    title: 'Videos',
    type: 'file',
    accept: 'video/mp4,video/x-m4v,video/*',
  },
];

export const intialGeoData = [
  {
    id: 106,
    name: 'India',
    longitude: 82.58886,
    latitude: 22.546633,
    zoom: 3,
  },
  {
    id: 200,
    name: 'Singapore',
    longitude: 103.820556,
    latitude: 1.348376,
    zoom: 9,
  },
  {
    id: 134,
    name: 'Malaysia',
    longitude: 109.430669,
    latitude: 3.848045,
    zoom: 3.7,
  },
  {
    id: 14,
    name: 'Australia',
    longitude: 133.817201,
    latitude: -25.712759,
    zoom: 2.6,
  },
  {
    id: 107,
    name: 'Indonesia',
    longitude: 118.806032,
    latitude: -3.703943,
    zoom: 2.4,
  },
  {
    id: 172,
    name: 'Philippines',
    longitude: 122.205716,
    latitude: 11.994233,
    zoom: 4.8,
  },
  {
    id: 48,
    name: 'Hong Kong',
    longitude: 114.128342,
    latitude: 22.364069,
    zoom: 8.8,
  },
  {
    id: 221,
    name: 'Thailand',
    longitude: 101.358896,
    latitude: 13.45179,
    zoom: 4.6,
  },
  {
    id: 18,
    name: 'Bahrain',
    longitude: 50.561915,
    latitude: 26.070629,
    zoom: 9.3,
  },
  {
    id: 115,
    name: 'Japan',
    longitude: 139.219813,
    latitude: 37.426673,
    zoom: 3.3,
  },
  {
    id: 121,
    name: 'Kuwait',
    longitude: 47.527453,
    latitude: 29.309371,
    zoom: 7,
  },
  {
    id: 177,
    name: 'Qatar',
    longitude: 51.181838,
    latitude: 25.280103,
    zoom: 7.7,
  },
  {
    id: 165,
    name: 'Oman',
    longitude: 82.58886,
    latitude: 22.546633,
    zoom: 3,
  },
  {
    id: 195,
    name: 'Saudi Arabia',
    longitude: 44.978083,
    latitude: 24.079482,
    zoom: 3.5,
  },
  {
    id: 235,
    name: 'United Arab Emirates',
    longitude: 53.912805,
    latitude: 24.397934,
    zoom: 5.6,
  },
  {
    id: 245,
    name: 'Vietnam',
    longitude: 107.301843,
    latitude: 15.809445,
    zoom: 4.5,
  },
  {
    id: 157,
    name: 'New Zealand',
    longitude: 173.029707,
    latitude: -40.807074,
    zoom: 4.2,
  },
];

export const competitorData = [
  { label: 'Competitor 1', value: 'competitor1' },
  { label: 'Competitor 2', value: 'competitor2' },
  { label: 'Competitor 3', value: 'competitor3' },
];

export const languageData = [{ label: 'English', value: 'english' }];

export const osData = [
  { label: 'IOS', value: 'ios' },
  { label: 'Android', value: 'android' },
  { label: 'Others', value: 'others' },
];

export const deviceData = [
  { label: 'Mobile', value: 'mobile' },
  { label: 'Tablet', value: 'tablet' },
  { label: 'Desktop', value: 'desktop' },
];

export const dayTimePartingData = [
  {
    id: 'entireWeek',
    label: 'Entire Week',
    value: 'entireWeek',
  },
  { id: 'weekDays', label: 'Week Days', value: 'weekDays' },
  // {
  //   id: "customParting",
  //   label: "Custom Parting",
  //   value: "customParting",
  // },
];

export const weekDays = {
  0: [
    {
      fromhour: '0',
      tohour: '1440',
    },
  ],
  1: [
    {
      fromhour: '0',
      tohour: '1440',
    },
  ],
  2: [
    {
      fromhour: '0',
      tohour: '1440',
    },
  ],
  3: [
    {
      fromhour: '0',
      tohour: '1440',
    },
  ],
  4: [
    {
      fromhour: '0',
      tohour: '1440',
    },
  ],
};

export const entireWeek = {
  0: [
    {
      fromhour: '0',
      tohour: '1440',
    },
  ],
  1: [
    {
      fromhour: '0',
      tohour: '1440',
    },
  ],
  2: [
    {
      fromhour: '0',
      tohour: '1440',
    },
  ],
  3: [
    {
      fromhour: '0',
      tohour: '1440',
    },
  ],
  4: [
    {
      fromhour: '0',
      tohour: '1440',
    },
  ],
  5: [
    {
      fromhour: '0',
      tohour: '1440',
    },
  ],
  6: [
    {
      fromhour: '0',
      tohour: '1440',
    },
  ],
};

export const campaignFlightDateFields = [
  {
    label: 'CAMPAIGN FLIGHT',
    isLabel: true,
    labelCheckBox: false,
    locale: 'en-US',
    // timeFormat: false,
    dateFormat: 'DD-MM-YYYY',
    name: 'startDate',
    errorMsgName: 'Start date',
    inputProps: {
      placeholder: 'Start Date',
      name: 'startDate',
      // readOnly: true,
    },
  },
  {
    label: '.',
    isLabel: false,
    labelCheckBox: false,
    locale: 'en-US',
    // timeFormat: false,
    dateFormat: 'DD-MM-YYYY',
    name: 'endDate',
    errorMsgName: 'End date',
    inputProps: {
      placeholder: 'End Date',
      name: 'endDate',
      // readOnly: true
    },
  },
  {
    label: 'ATTRIBUTION FLIGHT',
    isLabel: true,
    labelCheckBox: false,
    locale: 'en-US',
    // timeFormat: false,
    dateFormat: 'DD-MM-YYYY',
    name: 'attrStartDate',
    errorMsgName: 'Attribution start date',
    inputProps: {
      placeholder: 'Attribution Start Date',
      name: 'attrStartDate',
      disabled: true,
      // readOnly: true,
    },
  },
  {
    label: '',
    isLabel: false,
    labelCheckBox: true,
    locale: 'en-US',
    // timeFormat: false,
    dateFormat: 'DD-MM-YYYY',
    name: 'attrEndDate',
    errorMsgName: 'Attribution end date',
    inputProps: {
      placeholder: 'Attribution End Date',
      name: 'attrEndDate',
      disabled: true,
      // readOnly: true,
    },
  },
];

export const budgetImpressionFields = [
  {
    name: 'budget',
    id: 'budget',
    type: 'text',
    placeholder: 'Budget',
    errorMsgName: 'Budget',
  },
  {
    name: 'impressions',
    id: 'impressions',
    type: 'text',
    placeholder: 'Impressions',
    errorMsgName: 'Impressions',
  },
];
