import { store2 as store, fetch } from 'utils/httpUtil';
import { API_URL } from 'config/config';
import { getToken } from 'utils/storageUtil';

const BASE_URL = API_URL + '/api/user';

export const isAuthenticatedNew = () => {
  return !!getToken();
};

export const loginNew = (payload) => {
  return store(BASE_URL + '/authenticate', payload);
};

export const sendPasswordResetLink = (email) => {
  return store(BASE_URL + '/password/forgot/' + email);
};

export const resetPassword = (token, payload) => {
  return store(BASE_URL + '/password/reset' + token, payload);
};

export const fetchUserData = (email) => {
  return fetch(BASE_URL, `/email/${email}`);
};

export const fetchCustomerData = (customerId) => {
  return fetch(API_URL, `/api/customer/${customerId}`);
};

export const switchAccount = (userId, accountId) => {
  return store(BASE_URL + `/switch/${userId}/${accountId}`, '');
};
