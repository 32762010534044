import { all, takeLatest, call, put } from 'redux-saga/effects';
import { fetchPoiPackagesCount } from '../../../ooh/plansService';
import {
  fetchPoiPackagesCountAction,
  fetchPoiPackagesCountFailedAction,
  fetchPoiPackagesCountSuccessAction,
} from './poiPackagesCount.reducer';

function* getPoiPackagesCount({ payload }) {
  try {
    const poiPackagesCountResponse = yield call(
      () => fetchPoiPackagesCount(payload),
      payload
    );
    yield put(fetchPoiPackagesCountSuccessAction(poiPackagesCountResponse));
  } catch (error) {
    fetchPoiPackagesCountFailedAction();
  }
}

export function* poiPackagesCountWatcher() {
  yield all([takeLatest(fetchPoiPackagesCountAction, getPoiPackagesCount)]);
}
