import axios from 'axios';
import { checkStatus } from '../utils/responseHandler';
import { URL } from '../common/constants';

export default async (url, method = 'post', body, autherization) => {
  let headers = {};
  if (autherization) {
    headers = {
      'content-type': 'multipart/form-data',
      Authorization: localStorage.getItem('token'),
    };
  } else {
    headers = {
      'content-type': 'multipart/form-data',
    };
  }
  return axios({
    method,
    url: `${URL}${url}`,
    data: body,
    headers,
  })
    .then(checkStatus)
    .then((response) => {
      return JSON.parse(JSON.stringify(response.data));
    })
    .catch((error) => {
      throw error;
    });
};
