import fetch from 'cross-fetch';
import { checkStatus, parseJSON } from '../utils/responseHandler';
// import {API_URL} from "config/config";
// const URL = API_URL + '/';
// Make an api call

export default async (url, method = 'get', authorization) => {
  // var headers = {};
  // if (authorization) {
  //   headers = {
  //     "Content-Type": "application/json",
  //     Authorization: localStorage.getItem("token")
  //   };
  // } else {
  //   headers = {
  //     "Content-Type": "application/json"
  //   };
  // }
  return fetch('/channelData.json', '')
    .then(checkStatus)
    .then(parseJSON)
    .catch((error) => {
      throw error;
    });
};
