import { put, takeEvery, call } from 'redux-saga/effects';
import post from '../../postFormData';

const getEndPoint = (name) => {
  switch (name) {
    case 'banner':
      return 'banners';
    case 'creativeTags':
      return 'creativeTags';
    case 'nativeIcon':
      return 'nativeIcon';
    case 'videoIcon':
      return 'videoIcon';
    default:
      return '';
  }
};

const API_DATA = (payload) => {
  const { name, file } = payload;
  // Formdata object
  const formData = new FormData();

  // Append the values with key, value pair
  formData.append('file', file);
  return post(
    `campaign/creative/upload/${getEndPoint(name)}`,
    'POST',
    formData,
    true
  ).then((response) => {
    return response;
  });
};

export const UPLOAD_FILE_SAGA = function* perform_uploadAction() {
  yield takeEvery('UPLOAD_FILE', function* (action) {
    yield put({
      type: `UPLOAD_FILE_STARTED`,
      payload: { id: action.payload.cardId, name: action.payload.name },
    });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({
        type: `UPLOAD_FILE_SUCCESS`,
        payload: {
          DATA,
          id: action.payload.cardId,
          name: action.payload.name,
          file: action.payload.file,
        },
      });
    } catch (error) {
      yield put({
        type: `UPLOAD_FILE_FAILED`,
        payload: {
          error: error.response.data,
          id: action.payload.cardId,
          name: action.payload.name,
        },
      });
    }
  });
};
