import { put, takeEvery, call } from 'redux-saga/effects';
import get from '../getApiCaller';

const API_DATA = (payload) => {
  const { brandOrCategory, city, recaptchaCode, state, enableSearchString } =
    payload;
  return get(
    `places/search?brandId=${brandOrCategory.filterId}&country=${
      brandOrCategory.countryId
    }&${
      enableSearchString
        ? state && state !== ''
          ? state + '&'
          : ''
        : 'stateIds=' + state.id + '&'
    }${
      enableSearchString
        ? city && city !== ''
          ? city + '&'
          : ''
        : 'cityIds=' + city.id + '&'
    }gtoken=${recaptchaCode}`,
    'GET',
    true
  ).then((response) => {
    return response;
  });
};

export const GET_PLACES_CAMPAIGN_MODAL_SAGA =
  function* perform_getPlaceForCampaignModalAction() {
    yield takeEvery('GET_PLACES_CAMPAIGN_MODAL', function* (action) {
      yield put({ type: 'GET_PLACES_CAMPAIGN_MODAL_STARTED' });
      try {
        const DATA = yield call(API_DATA.bind(this, action.payload));
        yield put({
          type: 'GET_PLACES_CAMPAIGN_MODAL_SUCCESS',
          payload: DATA,
        });
      } catch (error) {
        yield put({
          type: 'GET_PLACES_CAMPAIGN_MODAL_FAILED',
          payload: error,
        });
      }
    });
  };
