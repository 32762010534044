import SAVE_ICON from '../assets/images/save.svg';
import INSIGHT_ICON from '../assets/images/insight.svg';
import ACTIVATE_ICON from '../assets/images/activate.svg';
import SUB_MODAL_CROSS_ICON from '../assets/images/sub_modal_cross.svg';
import TV_COUNT_ICON from '../assets/images/modal_tv.svg';
import MARKER_SVG_ICON from '../assets/images/marker.svg';
import MARKER_BLUE_CROSS_ICON from '../assets/images/blue_cross.svg';
import CARET_RIGHT_ICON from '../assets/images/caret_right.svg';
import CARET_DOWN_ICON from '../assets/images/caret_down.svg';
import BLUE_DOT_ICON from '../assets/images/blue_dot.svg';
import UPDATE_PLAN from '../assets/images/updatePlan.svg';

export const UPDATE_PLAN_SVG = UPDATE_PLAN;
export const SAVE = SAVE_ICON;
export const INSIGHT = INSIGHT_ICON;
export const ACTIVATE = ACTIVATE_ICON;
export const SUB_MODAL_CROSS = SUB_MODAL_CROSS_ICON;
export const TV_COUNT = TV_COUNT_ICON;
export const MARKER_SVG = MARKER_SVG_ICON;
export const MARKER_BLUE_CROSS = MARKER_BLUE_CROSS_ICON;
export const CARET_RIGHT = CARET_RIGHT_ICON;
export const CARET_DOWN = CARET_DOWN_ICON;
export const BLUE_DOT = BLUE_DOT_ICON;

// export const EXPORT = `${require("../assets/images/export.svg")}`;
export const FLUX_ICON_WHITE = `${require('../assets/images/flux_icon_white.svg')}`;
export const PROFILE_PIC = `${require('../assets/images/profile_pic.svg')}`;
export const MAP_TRACKER = `${require('../assets/images/map_tracker.svg')}`;
export const SIDE_BAR = `${require('../assets/images/side_bar.svg')}`;
export const SIDE_TV = `${require('../assets/images/side_tv.svg')}`;
export const ICON5 = `${require('../assets/images/icon5.svg')}`;
export const ICON7 = `${require('../assets/images/icon7.svg')}`;

export const CROSS_BUTTON = `${require('../assets/images/cross_btn.svg')}`;

export const EXPORT_MODAL_HEAD = `${require('../assets/images/export.svg')}`;
export const MODAL_CROSS = `${require('../assets/images/bill_cross.svg')}`;
export const EXPORT_MODAL_QUESTION_MARK = `${require('../assets/images/question_mark.svg')}`;

export const SAVE_MODAL_ICON = `${require('../assets/images/save.svg')}`;

// export const MARKER_SVG_RED = `${require("../assets/images/marker_svg_red.svg")}`;
// export const RED_DOT = `${require("../assets/images/red_dot_img.png")}`;
