import { lazy } from 'react';

import { ReactComponent as ConnectionSvg } from 'assets/new-img/sidebar-opt/Connections.svg';
import { ReactComponent as CustomerSvg } from 'assets/new-img/sidebar-opt/Customers.svg';
// import { ReactComponent as CampaignSvg } from 'assets/new-img/sidebar-opt/Campaign.svg';
import { ReactComponent as AudienceSvg } from 'assets/new-img/sidebar-opt/Audience.svg';
import { ReactComponent as MeasurementSvg } from 'assets/new-img/sidebar-opt/Measure.svg';
import { ReactComponent as ScreenIQSvg } from 'assets/new-img/sidebar-opt/ScreenIQ.svg';
// import { ReactComponent as MyDataSvg } from 'assets/new-img/sidebar-opt/MyData.svg';

// Profile/Account routes
const Account = lazy(
  () => import('profile/accounts/Account' /* webpackChunkName: 'Profile' */)
);
const Users = lazy(
  () => import('profile/users/Users' /* webpackChunkName: 'Profile' */)
);
const Profile = lazy(
  () => import('profile/Profile' /* webpackChunkName: 'Profile' */)
);

// const Dashboard = lazy(() => import('dashboard/Dashboard' /* webpackChunkName: 'Dashboard' */));
const DashboardNew = lazy(
  () => import('dashboard/DashboardNew' /* webpackChunkName: 'DashboardNew' */)
);

// Customers routes
const CustomerList = lazy(
  () =>
    import(
      'Customers/CustomerList/CustomerList' /* webpackChunkName: 'CustomerList' */ /* webpackPrefetch: true */
    )
);
const CustAudiences = lazy(
  () =>
    import(
      'Customers/MyAudiences/MyAudiences' /* webpackChunkName: 'CustAudiences' */ /* webpackPrefetch: true */
    )
);
const CustomerBuilder = lazy(
  () =>
    import(
      'Customers/CreateAudience/CreateAudience' /* webpackChunkName: 'CustomerBuilder' */ /* webpackPrefetch: true */
    )
);
const CustInsights = lazy(
  () =>
    import(
      'Customers/CustInsights/CustInsights' /* webpackChunkName: 'CustInsights' */ /* webpackPrefetch: true */
    )
);

// Audience routes
const AllAudience = lazy(
  () =>
    import(
      'audience/AllAudience/AllAudience' /* webpackChunkName: 'AllAudience' */ /* webpackPrefetch: true */
    )
);
const MyAudiences = lazy(
  () =>
    import(
      'audience/MyAudiences/MyAudience' /* webpackChunkName: 'MyAudiences' */ /* webpackPrefetch: true */
    )
);
const CreateAudience = lazy(
  () =>
    import(
      'audience/CreateAudience/CreateAudience' /* webpackChunkName: 'CreateAudience' */ /* webpackPrefetch: true */
    )
);
const AudienceInsights = lazy(
  () =>
    import(
      'audience/Insights/AudienceInsights' /* webpackChunkName: 'AudienceInsights' */ /* webpackPrefetch: true */
    )
);

// ScreenIQ routes
const MyPlans = lazy(
  () => import('ooh/MyPlans/MyPlans' /* webpackChunkName: 'MyPlans' */)
);
const MyScreens = lazy(
  () => import('ooh/MyScreens/MyScreens' /* webpackChunkName: 'MyScreens' */)
);
const MyPOIPackages = lazy(
  () => import('ooh/MyPOIModule/MyPOIPackages/MyPOIPackages')
);
const MyPOI = lazy(() => import('ooh/MyPOIModule/MyPOI/MyPOI'));
const Planner = lazy(
  () => import('ooh/Planner/containers/home' /* webpackChunkName: 'Planner' */)
);
const OOHInsights = lazy(
  () =>
    import('ooh/OOHInsights/OOHInsights' /* webpackChunkName: 'OOHInsights' */)
);
const EditScreen = lazy(
  () =>
    import(
      'ooh/MyScreens/EditScreen/EditScreen' /* webpackChunkName: 'EditScreen' */
    )
);
const EditPackage = lazy(
  () =>
    import(
      'ooh/MyScreens/EditPackage/EditPackage' /* webpackChunkName: 'EditPackage' */
    )
);
const MyActivations = lazy(() => import('ooh/MyActivations/MyActivations'));
const ActivationInsights = lazy(
  () => import('ooh/MyActivations/ActivationInsights')
);

// Attribution routes
// const CreateCampaign = lazy(() => import('campaign/container/home/CreateCampaign' /* webpackChunkName: 'CreateCampaign' */));
const ReportNew = lazy(
  () =>
    import(
      'attribution/ReportNew/ReportNew' /* webpackChunkName: 'ReportNew' */
    )
);
const Tracker3 = lazy(
  () =>
    import('attribution/tracker/Tracker3' /* webpackChunkName: 'Tracker3' */)
);
const MyReports = lazy(
  () =>
    import(
      'attribution/MyReports/MyReports' /* webpackChunkName: 'MyReports' */
    )
);

// My data routes
// const MyData = lazy(() => import('MyData/MyData' /* webpackChunkName: 'MyData' */));
// const MyDataInsights = lazy(() => import('MyData/MyDataInsights/MyDataInsights' /* webpackChunkName: 'MyDataInsights' */));

const MyIntegrations = lazy(
  () =>
    import(
      'Integration/MyIntegrations/MyIntegrations' /* webpackChunkName: 'MyIntegrations' */
    )
);
const AddIntegration = lazy(
  () =>
    import(
      'Integration/AddIntegration/AddIntegration' /* webpackChunkName: 'AddIntegration' */
    )
);
const UnAuthorized = lazy(() => import('../unAuthorized/UnAuthorized'));

const routes = [
  {
    path: '/dashboard',
    component: DashboardNew,
  },
  {
    name: 'Integrations',
    icon: ConnectionSvg,
    isSidenav: true,
    path: '/integration',
    routes: [
      {
        name: 'Add Integration',
        path: '/add-integration',
        component: AddIntegration,
        isDefault: true,
        accessKey: 'isIntegration',
        accessMessage: 'Integration',
        fallback: '/dashboard',
      },
      {
        name: 'My Integrations',
        path: '',
        component: MyIntegrations,
        isDefault: false,
        accessKey: 'isIntegration',
        accessMessage: 'Integration',
        fallback: '/dashboard',
      },
      {
        path: '/:dsId/:readOnly/:dataType',
        component: AddIntegration,
        accessKey: 'isIntegration',
        accessMessage: 'CIP',
        fallback: '/dashboard',
      },
    ],
  },
  {
    name: 'Customers',
    icon: CustomerSvg,
    isSidenav: true,
    path: '/customers',
    routes: [
      {
        name: 'All Users',
        path: '',
        component: CustomerList,
        isDefault: true,
        accessKey: 'isIntegration',
        accessMessage: 'CIP',
        fallback: '/dashboard',
      },
      {
        name: 'My Audiences',
        path: '/audiences',
        component: CustAudiences,
        isDefault: false,
        accessKey: 'isIntegration',
        accessMessage: 'CIP',
        fallback: '/dashboard',
      },
      {
        name: 'Create Audience',
        path: '/create-audience',
        component: CustomerBuilder,
        isDefault: false,
        accessKey: 'isIntegration',
        accessMessage: 'CIP',
        fallback: '/dashboard',
      },
      {
        path: '/:customerId',
        component: CustInsights,
        accessKey: 'isIntegration',
        accessMessage: 'CIP',
        fallback: '/dashboard',
      },
      {
        path: '/insights/:segmentId',
        component: AudienceInsights,
        accessKey: 'isAudienceWrite',
        accessMessage: 'Audience Insights',
        fallback: '/dashboard',
      },
    ],
  },
  /*{
    name: 'Audiences',
    icon: AudienceSvg,
    isSidenav: true,
    path: '/audience',
    routes: [
      {
        name: 'Browse',
        path: '',
        component: AllAudience,
        isDefault: true,
        accessKey: 'isAudience',
        accessMessage: 'Audience',
        fallback: '/dashboard',
      },
      {
        name: 'Create Audience',
        path: '/builder',
        component: CreateAudience,
        accessKey: 'isAudienceWrite',
        accessMessage: 'Audience Builder',
        fallback: '/dashboard',
      },
      {
        name: 'My Audiences',
        path: '/myaudiences',
        component: MyAudiences,
        accessKey: 'isAudience',
        accessMessage: 'Audience',
        fallback: '/dashboard',
      },
      {
        path: '/insights/:segmentId',
        component: AudienceInsights,
        accessKey: 'isAudienceWrite',
        accessMessage: 'Audience Insights',
        fallback: '/dashboard',
      },
      {
        path: '/my-audience/insights/:segmentId',
        component: AudienceInsights,
        accessKey: 'isAudienceWrite',
        accessMessage: 'Audience Insights',
        fallback: '/dashboard',
      },
    ],
  },*/
  {
    name: 'ScreenIQ',
    icon: ScreenIQSvg,
    isSidenav: true,
    path: '/ooh',
    routes: [
      {
        name: 'Create Plan',
        path: '/planner',
        component: Planner,
        isDefault: true,
        accessKey: 'isScreenIq',
        accessMessage: 'Screen IQ',
        fallback: '/dashboard',
      },
      {
        name: 'My Plans',
        path: '/my-plans',
        component: MyPlans,
        accessKey: 'isScreenIq',
        accessMessage: 'Screen IQ',
        fallback: '/dashboard',
      },
      {
        name: 'My Screens',
        path: '/my-screens',
        component: MyScreens,
        accessKey: 'isScreenIq',
        accessMessage: 'Screen IQ',
        fallback: '/dashboard',
      },
      {
        path: '/insights/:planId',
        component: OOHInsights,
        accessKey: 'isScreenIq',
        accessMessage: 'Screen IQ',
        fallback: '/dashboard',
      },
      {
        path: '/my-screens/screen/:placeId',
        component: EditScreen,
        accessKey: 'isScreenIq',
        accessMessage: 'Screen IQ',
        fallback: '/dashboard',
      },
      {
        path: '/my-screens/screen',
        component: EditScreen,
        accessKey: 'isScreenIq',
        accessMessage: 'Screen IQ',
        fallback: '/dashboard',
      },
      {
        path: '/my-screens/package/:packageId',
        component: EditPackage,
        accessKey: 'isScreenIq',
        accessMessage: 'Screen IQ',
        fallback: '/dashboard',
      },
      {
        name: 'My Activations',
        path: '/my-activations',
        component: MyActivations,
        accessKey: 'isScreenIq',
        accessMessage: 'Screen IQ',
        fallback: '/dashboard',
      },
      {
        path: '/activation/insights/:insightId/:destination',
        component: ActivationInsights,
        accessKey: 'isScreenIq',
        accessMessage: 'Screen IQ',
        fallback: '/dashboard',
      },
      {
        name: 'My POI',
        path: '/poi/packages',
        component: MyPOIPackages,
        accessKey: 'isScreenIq',
        accessMessage: 'Screen IQ',
        fallback: '/dashboard',
      },
      {
        path: '/poi/package/:packageName/:packageId',
        component: MyPOI,
        accessKey: 'isScreenIq',
        accessMessage: 'Screen IQ',
        fallback: '/dashboard',
      },
    ],
  },
  {
    name: 'Measurement',
    icon: MeasurementSvg,
    isSidenav: true,
    path: '/attribution',
    routes: [
      {
        name: 'My Measurements',
        path: '',
        component: MyReports,
        isDefault: true,
        accessKey: 'isAttribution',
        accessMessage: 'Attribution',
        fallback: '/dashboard',
      },
      {
        name: 'Add Measurement',
        path: '/tracker',
        component: Tracker3,
        accessKey: 'isAttributionWrite',
        accessMessage: 'Attribution Tracker',
        fallback: '/dashboard',
      },
      {
        path: '/tracker/:trackerID',
        component: Tracker3,
        accessKey: 'isAttributionWrite',
        accessMessage: 'Attribution Tracker',
        fallback: '/dashboard',
      },
      {
        path: '/report/:pixelId',
        component: ReportNew,
        accessKey: 'isAttribution',
        accessMessage: 'Attribution',
        fallback: '/dashboard',
      },
    ],
  },
  {
    path: '/notAuthorized',
    routes: [
      {
        path: '',
        component: UnAuthorized,
        isDefault: true,
      },
    ],
  },
  // {
  //   name: 'Campaign',
  //   icon: CampaignSvg,
  //   isSidenav: false,
  //   path: '/create-campaign',
  //   component: CreateCampaign
  // },
  // {
  //   name: 'My Data',
  //   icon: MyDataSvg,
  //   isSidenav: true,
  //   path: '/my-data',
  //   routes: [
  //     {
  //       path: '',
  //       component: MyData,
  //       isDefault: true,
  //       accessKey: 'isFirstParty',
  //       accessMessage: 'My Data',
  //       fallback: '/audience'
  //     },
  //     {
  //       path: '/insights/:segmentId',
  //       component: MyDataInsights,
  //       accessKey: 'isFirstParty',
  //       accessMessage: 'My Data',
  //       fallback: '/audience'
  //     }
  //   ]
  // },
  // {
  //   path: '/auth',
  //   routes: [
  //     {
  //       path: '/login',
  //       component: Login
  //     },
  //     {
  //       path: '/forgot-password',
  //       component: ForgotPassword
  //     },
  //     {
  //       path: '/setpassword',
  //       component: ChangePassword
  //     }
  //   ]
  // },
  {
    path: '/admin',
    routes: [
      {
        path: '/account',
        component: Account,
      },
      {
        path: '/users',
        component: Users,
      },
      {
        path: '/profile',
        component: Profile,
      },
    ],
  },
];

export default routes;
