export default function reducer(
  state = {
    states: '',
  },
  action
) {
  switch (action.type) {
    case 'GET_STATES_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_STATES_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        states: action.payload,
      };
    }
    case 'GET_STATES_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        states: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
