export default function reducer(
  state = {
    insightsData: '',
  },
  action
) {
  switch (action.type) {
    case 'GET_INSIGHTS_DATA_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_INSIGHTS_DATA_SUCCESS': {
      let data = [];
      const updatedData = action.payload;
      updatedData.estimatedReach = action.payload.id.estimatedReach;
      updatedData.estimatedImpressions = action.payload.id.estimatedImpressions;
      updatedData.allAudienceReach = action.payload.id.allAudienceReach;
      updatedData.targetAudiencePenetration =
        action.payload.id.targetAudiencePenetration;
      updatedData.targetedAudienceReach =
        action.payload.id.targetedAudienceReach;
      if (action.payload.status) {
        data = action?.payload?.id?.aggregatedInsights?.map((value) => {
          const listData = value;
          listData.data = JSON.parse(value.data);
          return listData;
        });
      }
      updatedData.id = data;
      return {
        ...state,
        changingStatus: 'success',
        insightsData: updatedData,
        latLngUpdate: false,
      };
    }
    case 'GET_INSIGHTS_DATA_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        insightsData: action.payload,
      };
    }
    case 'GET_INSIGHTS_DATA_STATUS': {
      return {
        ...state,
        latLngUpdate: true,
      };
    }
    case 'GET_INSIGHTS_DATA_RESET_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        latLngUpdate: true,
        insightsData: '',
      };
    }
    default: {
      return state;
    }
  }
}
