import { put, takeEvery, call } from 'redux-saga/effects';
import post from '../../postApiCaller';
import { weekDays, entireWeek } from '../../../utils/metaData';

const getDayTimePartingData = (dayTimeData) => {
  if (dayTimeData) {
    switch (dayTimeData.value) {
      case 'entireWeek':
        return entireWeek;
      case 'weekDays':
        return weekDays;
      default:
        return {};
    }
  } else {
    return {};
  }
};

const getSegmentID = (type, filterId) => {
  switch (type) {
    case 'BRAND':
      return `LSB${'IND'}${filterId}`;
    case 'FIRST_PARTY':
      return `LSF${filterId}`;
    case 'CATEGORY':
      return `LSC${'IND'}${filterId}`;
    case 'APPCATEGORY':
      return `LSA${'IND'}${filterId}`;
    case 'APPNAME':
      return `LSA${'IND'}${filterId}`;
    case 'INTEREST':
      return `LSI${'IND'}${filterId}`;
    case 'GEO_BHAVIOUR':
      return `LSM${'IND'}${filterId}`;
    case 'DEVICE_MAKE':
      return `LSM${'IND'}${filterId}`;
    case 'GEOGRAPHY':
      return `${filterId}`;
    default:
      return '';
  }
};
const getSetsData = (campaignTargetList) => {
  const arr = [];
  campaignTargetList.forEach((data) => {
    const obj = {};
    obj.proximityTargetingEnabled = data.isProximityToggle;
    if (data.audience && data.audience.length) {
      obj.audienceList = [];
      data.audience.forEach((audData) => {
        obj.audienceList = [
          ...obj.audienceList,
          {
            segmentId: getSegmentID(audData.type, audData.filterId),
            segmentName: audData.filterName,
            segmentType: audData.type,
          },
        ];
      });
    }
    if (data.fileData && Object.keys(data.fileData).length > 0) {
      const keys = Object.keys(data.fileData);
      const fileObject = {};
      keys.forEach((key) => {
        if (
          data.fileData[key].data.status &&
          data.fileData[key].data.id !== 'Deleted'
        ) {
          fileObject[key] = data.fileData[key].data.id;
        }
      });
      obj.creatives = fileObject;
    }
    if (
      data.isProximityToggle &&
      data.selectedSearchedPlace &&
      data.selectedSearchedPlace.length > 0
    ) {
      obj.proximityPlaces = [];
      data.selectedSearchedPlace.forEach((selectedPlaceData) => {
        obj.proximityPlaces = [
          ...obj.proximityPlaces,
          {
            placeId: selectedPlaceData.placeDBId,
          },
        ];
      });
    }
    arr.push(obj);
  });
  return arr;
};

const getPixelPlaceData = (campaignPlaceCardData, isCampaignPlaceToggle) => {
  let arr = [];
  if (
    isCampaignPlaceToggle &&
    campaignPlaceCardData &&
    campaignPlaceCardData.selectedSearchedPlace &&
    campaignPlaceCardData.selectedSearchedPlace.length > 0
  ) {
    campaignPlaceCardData.selectedSearchedPlace.forEach((campaignPlaceCard) => {
      arr = [
        ...arr,
        {
          brandId: campaignPlaceCard.filterId,
          brandName: campaignPlaceCard.filterName,
          placeId: 8288012,
          radius: campaignPlaceCard.radius,
        },
      ];
    });
  }
  return arr;
};

const getCompetitorsBrandId = (competitorData) => {
  const competitorList = [];
  if (competitorData && competitorData.length > 0) {
    competitorData.forEach((compData) => {
      if (compData) {
        competitorList.push(compData.filterId);
      }
    });
    return competitorList.join(',');
  }
};

const getCampaignOptions = ({ language, device, os, frequency, day, asap }) => {
  const campaignOption = {};
  if (language) {
    campaignOption.language = [language.value];
  }
  if (device) {
    campaignOption.devices = [device.value];
  }
  if (os) {
    campaignOption.os = [os.value];
  }
  if (frequency || day || asap) {
    const obj = {};
    if (frequency) {
      obj.impression = frequency;
    }
    if (day) {
      obj.day = day.value;
    }
    if (asap) {
      obj.asap = asap.value;
    }
    campaignOption.frequency = obj;
  }
  return campaignOption;
};

const API_DATA = (payload) => {
  const { state, createdByUserId, id, selectedAccountId } = payload;
  const {
    os,
    day,
    asap,
    device,
    budget,
    endDate,
    language,
    startDate,
    frequency,
    attrEndDate,
    impressions,
    competitor1,
    competitor2,
    competitor3,
    dayTimeData,
    campaignName,
    attrStartDate,
    campaignTargetList,
    isCampaignPlaceToggle,
    campaignPlaceCardData,
  } = state;

  // get campaign option data
  const campaignOption = getCampaignOptions({
    language,
    device,
    os,
    frequency,
    day,
    asap,
  });

  // get campaign option data
  const dayTimeParting = getDayTimePartingData(dayTimeData);

  // get pixel Place Mapping Data
  const pixelPlaceMappingDataList = getPixelPlaceData(
    campaignPlaceCardData,
    isCampaignPlaceToggle
  );

  // get competitor brand ids
  const competitorBrandIds = getCompetitorsBrandId([
    competitor1,
    competitor2,
    competitor3,
  ]);

  const body = {
    campaignName,
    startDate: startDate.format('DD/MM/YYYY HH:mm:ss'),
    endDate: endDate.format('DD/MM/YYYY HH:mm:ss'),
    campaignOptionEnabled: state.isCampaignOptionToggle,
    createdByUserId,
    createdByAcountId: selectedAccountId,

    // campaign options set Data
    adSets: getSetsData(campaignTargetList),

    // PixelAttribution Data
    pixelAttributionData: {
      pixelName: campaignName,
      createdBy: selectedAccountId,
      userId: id,
      startDate: startDate.format('DD/MM/YYYY HH:mm:ss'),
      endDate: endDate.format('DD/MM/YYYY HH:mm:ss'),
      attributionStartDate: attrStartDate.format('DD/MM/YYYY HH:mm:ss'),
      attributionEndDate: attrEndDate.format('DD/MM/YYYY HH:mm:ss'),
      budgetDataList: [
        {
          channel: 'lifesight',
          budget: budget || 0,
          impression: impressions || 0,
        },
      ],
      totalImpressions: impressions || 0,
    },
  };

  // set campaign options
  if (Object.keys(campaignOption).length > 0) {
    body.campaignOption = campaignOption;
  }

  // set day time parting data
  if (Object.keys(dayTimeParting).length > 0) {
    body.dayTimeParting = dayTimeParting;
  }

  // set Pixel attribution data
  if (pixelPlaceMappingDataList.length > 0) {
    body.pixelAttributionData.pixelPlaceMappingDataList =
      pixelPlaceMappingDataList;
  }

  // set competitor data
  if (competitorBrandIds !== '') {
    body.competitorBrandIds = competitorBrandIds;
  }
  return post(`campaign/new`, 'POST', body, true).then((response) => {
    return response;
  });
};

export const ACTIVATE_CAMPAIGN_SAGA = function* performActivateCampaignSaga() {
  yield takeEvery('ACTIVATE_CAMPAIGN', function* (action) {
    yield put({ type: 'ACTIVATE_CAMPAIGN_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({
        type: 'ACTIVATE_CAMPAIGN_SUCCESS',
        payload: DATA,
      });
    } catch (error) {
      yield put({
        type: 'ACTIVATE_CAMPAIGN_FAILED',
        payload: error,
      });
    }
  });
};
