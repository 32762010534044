export default function reducer(
  state = {
    activateExternalChannel: '',
  },
  action
) {
  switch (action.type) {
    case 'ACTIVATE_EXTERNAL_CHANNEL_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'ACTIVATE_EXTERNAL_CHANNEL_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        activateExternalChannel: action.payload,
      };
    }
    case 'ACTIVATE_EXTERNAL_CHANNEL_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        activateExternalChannel: action.payload,
      };
    }
    case 'ACTIVATE_EXTERNAL_CHANNEL_RESET': {
      return {
        ...state,
        changingStatus: 'reset',
        activateExternalChannel: '',
      };
    }
    default: {
      return state;
    }
  }
}
