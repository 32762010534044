import { all } from 'redux-saga/effects';
// import { LOGIN_SAGA } from "./loginSaga";
import { DISCOVER_AUDIENCE_AUTOCOMPLETE_SAGA } from './autoCompleteDiscoverAudience';
import { AUDIENCE_COUNT_SAGA } from './audienceCount';
import {
  AUDIIENCE_BY_TYPE_SAGA,
  GET_BRANDS_OR_CATEGORIES_TYPE_SAGA,
} from './audienceByType';
import {
  GET_ACTIVATE_CHANNEL_LIST_SAGA,
  GET_ACTIVATE_CHANNEL_FOR_TOKEN_SAGA,
} from './activateChannelList.js';
import { GET_SEGMENT_DEFINITION_SAGA } from './getSegmentDefinition';
import { CREATE_CAMPAIGN_SAGA } from './createCampaign';
import { UPLOAD_FILE_SAGA } from './uploadFiles';
import { ACTIVATE_EXTERNAL_CHANNEL_SAGA } from './activateExternalChannel';
import { SAVE_SEGMENT_DEFINITION_SAGA } from './saveSegmentDefinition';
import { GET_CATEGORIES_LIST_SAGA } from './getCategoriesList';
import { GET_BRANDS_LIST_SAGA } from './getBrandsList';
import { GET_AUDIENCE_BY_BRAND_AND_CATEGORY_SAGA } from './getAudienceByBrandAndCategory';
import { GET_BRAND_AND_CATEGORY_SAGA } from './getBrandAndCategory';
import { GET_STATES_SAGA } from './getStates';
import { GET_CITIES_SAGA } from './getCities';
import { GET_PLACES_CAMPAIGN_MODAL_SAGA } from './getPlacesCreateCampaignMadal';
import { DISCOVER_AUDIENCE_BY_CATEGORY_SAGA } from './discoverAudience/discoverAudienceByCategory';
import { RESET } from './resetSaga';
import { DELETE_FILE_SAGA } from './deleteUploadedFile';
import { GET_INSIGHTS_DATA_BUILDER_SAGA } from './getInsightsData';

// Wrap all sagas in a container
const rootSaga = function* rootSaga() {
  yield all([
    // LOGIN_SAGA(),
    DISCOVER_AUDIENCE_AUTOCOMPLETE_SAGA(),
    AUDIENCE_COUNT_SAGA(),
    AUDIIENCE_BY_TYPE_SAGA(),
    GET_ACTIVATE_CHANNEL_LIST_SAGA(),
    GET_SEGMENT_DEFINITION_SAGA(),
    CREATE_CAMPAIGN_SAGA(),
    UPLOAD_FILE_SAGA(),
    ACTIVATE_EXTERNAL_CHANNEL_SAGA(),
    SAVE_SEGMENT_DEFINITION_SAGA(),
    GET_CATEGORIES_LIST_SAGA(),
    GET_BRANDS_LIST_SAGA(),
    GET_AUDIENCE_BY_BRAND_AND_CATEGORY_SAGA(),
    GET_BRANDS_OR_CATEGORIES_TYPE_SAGA(),
    GET_STATES_SAGA(),
    GET_CITIES_SAGA(),
    GET_PLACES_CAMPAIGN_MODAL_SAGA(),
    DISCOVER_AUDIENCE_BY_CATEGORY_SAGA(),
    DELETE_FILE_SAGA(),
    RESET(),
    GET_ACTIVATE_CHANNEL_FOR_TOKEN_SAGA(),
    GET_INSIGHTS_DATA_BUILDER_SAGA(),
    GET_BRAND_AND_CATEGORY_SAGA(),
  ]);
};

export default rootSaga;
