import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Form } from 'reactstrap';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import InputField from '../InputField';
import { resetPassword } from 'layouts/Landing/newAuthService';
import SLButton from 'atoms/SLButton/SLButton';

const ChangePassword = (props) => {
  const { register, handleSubmit, errors, setError } = useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfPassword, setIsShowConfPassword] = useState(false);
  const togglePassword = () => setIsShowPassword(!isShowPassword);
  const toggleConfPassword = () => setIsShowConfPassword(!isShowConfPassword);

  const onSubmit = (values) => {
    if (values.newPassword !== values.repeatNewPassword) {
      setError('repeatNewPassword', {
        type: 'manual',
        message: 'Password mismatch',
      });
      return;
    }

    const { search } = props.location;
    resetPassword(search, values).then((res) => {
      if (res && res.data && res.data.status) {
        window.Intercom('trackEvent', 'Change password');
        props.history.push('/auth/login');
      } else {
        setErrorMessage(
          res.data.message || 'Some error occurred, Please try again later'
        );
      }
    });
  };

  const passwordSuffix = isShowPassword ? (
    <FaRegEyeSlash
      size={16}
      className="cursor-pointer"
      onClick={togglePassword}
    />
  ) : (
    <FaRegEye size={16} className="cursor-pointer" onClick={togglePassword} />
  );

  const confPasswordSuffix = isShowConfPassword ? (
    <FaRegEyeSlash
      size={16}
      className="cursor-pointer"
      onClick={toggleConfPassword}
    />
  ) : (
    <FaRegEye
      size={16}
      className="cursor-pointer"
      onClick={toggleConfPassword}
    />
  );

  return (
    <div className="mx-4 px-2">
      {errorMessage && (
        <Alert color="danger" toggle={() => setErrorMessage('')}>
          {errorMessage}
        </Alert>
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          type={isShowPassword ? 'text' : 'password'}
          placeholder="New Password"
          name="newPassword"
          suffix={passwordSuffix}
          error={errors.newPassword}
          ref={register({
            required: 'Password is required',
          })}
        />

        <InputField
          type={isShowConfPassword ? 'text' : 'password'}
          placeholder="Repeat New Password"
          name="repeatNewPassword"
          suffix={confPasswordSuffix}
          error={errors.repeatNewPassword}
          ref={register({
            required: 'Re-enter password',
          })}
        />
        <SLButton type="submit" block className="mb-3">
          Update Password
        </SLButton>
      </Form>
    </div>
  );
};

export default ChangePassword;
