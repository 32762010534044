export default function reducer(
  state = {
    cityData: {},
  },
  action
) {
  switch (action.type) {
    case 'GET_CAMPAIGN_CITY_DATA_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_CAMPAIGN_CITY_DATA_SUCCESS': {
      const cityData = action.payload.DATA;
      const updatedCityData = cityData.id.map((data) => {
        return {
          ...data,
          label: data.name,
          value: data.id,
        };
      });
      cityData.id = updatedCityData;
      return {
        ...state,
        changingStatus: 'success',
        cityData: { ...state.cityData, [action.payload.id]: cityData },
      };
    }
    case 'GET_CAMPAIGN_CITY_DATA_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        cityData: action.payload,
      };
    }
    case 'GET_CAMPAIGN_CITY_DATA_RESET': {
      return {
        ...state,
        changingStatus: 'reset',
        cityData: {},
      };
    }
    default: {
      return state;
    }
  }
}
