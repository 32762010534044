export default function reducer(
  state = {
    poiPackage: [],
  },
  action
) {
  switch (action.type) {
    case 'GET_POI_PACKAGE_STARTED': {
      return { ...state, poiPackageStatus: 'ongoing' };
    }
    case 'GET_POI_PACKAGE_SUCCESS': {
      return {
        ...state,
        poiPackageStatus: 'success',
        poiPackage: action.payload,
      };
    }
    case 'GET_POI_PACKAGE_FAILED': {
      return {
        ...state,
        poiPackageStatus: 'failed',
      };
    }
    default: {
      return state;
    }
  }
}
