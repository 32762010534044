import { all } from 'redux-saga/effects';
import { GET_CREATE_CAMPAIGN_BRAND_DATA_SAGA } from './getCreateCampaignBrandDataSaga';
import { GET_CAMPAIGN_STATE_DATA_SAGA } from './getCampaignStateDataSaga';
import { GET_CAMPAIGN_CITY_DATA_SAGA } from './getCampaignCityDataSaga';
import { GET_AUDIENCE_DATA_SAGA } from './getAudienceDataSaga';
import { GET_CAMPAIGN_SEARCH_PLACE_DATA_SAGA } from './getCampaignSearchPlaceDataSaga';
import { GET_ESTIMATED_REACH_SAGA } from './getEstimatedReachSaga';
import { UPLOAD_FILE_SAGA } from './uploadFiles';
import { DELETE_FILE_SAGA } from './deleteFiles';
import { RESET_SAGA } from './resetSaga';
import { ACTIVATE_CAMPAIGN_SAGA } from './activateCampaignSaga';

// Wrap all sagas in a container
const rootSaga = function* rootSaga() {
  yield all([
    RESET_SAGA(),
    UPLOAD_FILE_SAGA(),
    DELETE_FILE_SAGA(),
    GET_AUDIENCE_DATA_SAGA(),
    ACTIVATE_CAMPAIGN_SAGA(),
    GET_ESTIMATED_REACH_SAGA(),
    GET_CAMPAIGN_CITY_DATA_SAGA(),
    GET_CAMPAIGN_STATE_DATA_SAGA(),
    GET_CREATE_CAMPAIGN_BRAND_DATA_SAGA(),
    GET_CAMPAIGN_SEARCH_PLACE_DATA_SAGA(),
  ]);
};

export default rootSaga;
