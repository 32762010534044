// app/axiosInstance.js
import axios from 'axios';
import { API_URL } from 'config/config';

const getAuth = ()=>{
    return localStorage.getItem('token')
}
const axiosInstance = axios.create({
  baseURL: API_URL || undefined, // Replace with your API base URL
  timeout: 10000, // Optional: set a timeout for requests
  headers: {
    'Content-Type': 'application/json',
    Authorization: getAuth()
    // Add any other headers you need
  },
});

export default axiosInstance;
