// ----------------- Start Insight Doughnut Chart Data -----------------
const getDoughnutData = (data) => {
  let total = 0;
  data.forEach((val) => {
    total += val;
  });
  return data.map((val) => {
    return ((val / total) * 100).toFixed(2);
  });
};

const getDataListName = (names) => {
  const list = names.map((data) => {
    return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
  });
  return list;
};

export const getDoughnutChartData = (data) => {
  const dataList = data.data;
  const donutData = {
    labels: getDataListName(dataList.names),
    datasets: [
      {
        backgroundColor: ['#072164', '#396FC9', '#46CDC5'],
        data: getDoughnutData(dataList.counts),
      },
    ],
  };
  return donutData;
};

// ----------------- End Insight Doughnut Chart Data -----------------

// ----------------- Start Insight Segment Title -----------------

export const getInsightSegmentTitle = (segmentType) => {
  switch (segmentType) {
    case 'AGE':
      return 'Age';
    case 'GENDER':
      return 'Gender';
    case 'APP_CATEGORY':
      return 'App Category';
    case 'APP_NAME':
      return 'App Name';
    case 'CATEGORY':
      return 'Category';
    case 'IAB_CATEGORY':
      return 'Interests';
    case 'DEVICE_CARRIER':
      return 'Device Carrier';
    case 'GEO_BEHAVIOUR':
      return 'GEO Behaviour';
    case 'BRAND':
      return 'Brand';
    case 'OOH':
      return 'OOH';
    case 'DEVICE_MODEL':
      return 'Device Model';
    case 'DEVICE_MAKE':
      return 'Device Make';
    case 'HOUSING':
      return 'Housing';
    case 'HOUSEHOLD':
      return 'Household';
    case 'HOME_TYPE':
      return 'Home Type';
    case 'FINANCIAL':
      return 'Financial';
    case 'OCCUPATION':
      return 'Occupation';
    case 'AUTO':
      return 'Auto';
    case 'CURRENT_HOME_VALUE_CODE_ESTIMATE':
      return 'Current Home Value Estimate';
    case 'PURCHASE_BEHAVIOUR':
      return 'Purchase Behaviour';
    default:
      return '';
  }
};

// ----------------- End Insight Segment Title -----------------

// ----------------- Start Filter Value Count ------------------

export const getFilteredValueCount = (values) => {
  if (
    values.changingStatus === 'success' &&
    values.filteredValues.status &&
    values.filteredValues.id &&
    values.filteredValues.id.length > 0
  ) {
    return values.filteredValues.id.length;
  }
  return 0;
};

// ----------------- End Filter Value Count ------------------

// ----------------- Start Include Params --------------------

export const getIncludeExcludeParams = (type, values, count) => {
  const obj = { type: '', includeValues: [], excludeValues: [] };
  switch (type) {
    case 'firstParty': {
      obj.type = 'FIRST_PARTY';
      values.forEach((data) => {
        if (data.isExclude) {
          obj.excludeValues.push(data.filterId);
        } else {
          obj.includeValues.push(data.filterId);
        }
      });
      return obj;
    }
    case 'brand': {
      obj.type = 'BRAND';
      values.forEach((data) => {
        if (data.isExclude) {
          obj.excludeValues.push(data.filterId);
        } else {
          obj.includeValues.push(data.filterId);
        }
      });
      return obj;
    }
    case 'geography': {
      obj.type = 'GEOGRAPHY';
      if (count) {
        values.forEach((data) => {
          if (data.isExclude) {
            obj.excludeValues.push(data.filterId);
          } else {
            obj.includeValues.push(data.filterId);
          }
        });
      } else {
        values.forEach((data) => {
          if (data.level1Id && data.isExclude) {
            obj.excludeValues.push(data.level1Id);
          } else if (data.level2Id && data.isExclude) {
            obj.excludeValues.push(data.level2Id);
          } else if (data.level1Id && data.isExclude === undefined) {
            obj.includeValues.push(data.level1Id);
          } else {
            obj.includeValues.push(data.level2Id);
          }
        });
      }

      return obj;
    }
    case 'category': {
      obj.type = 'CATEGORY';
      values.forEach((data) => {
        if (data.isExclude) {
          obj.excludeValues.push(data.filterId);
        } else {
          obj.includeValues.push(data.filterId);
        }
      });
      return obj;
    }
    case 'appCategory': {
      obj.type = 'APPCATEGORY';
      values.forEach((data) => {
        if (data.isExclude) {
          obj.excludeValues.push(data.filterId);
        } else {
          obj.includeValues.push(data.filterId);
        }
      });
      return obj;
    }
    case 'appName': {
      obj.type = 'APPNAME';
      values.forEach((data) => {
        if (data.isExclude) {
          obj.excludeValues.push(data.filterId);
        } else {
          obj.includeValues.push(data.filterId);
        }
      });
      return obj;
    }
    case 'interest': {
      obj.type = 'INTEREST';
      values.forEach((data) => {
        if (data.isExclude) {
          obj.excludeValues.push(data.filterId);
        } else {
          obj.includeValues.push(data.filterId);
        }
      });
      return obj;
    }
    case 'geoBehaviour': {
      obj.type = 'GEO_BEHAVIOUR';
      values.forEach((data) => {
        if (data.isExclude) {
          obj.excludeValues.push(data.filterId);
        } else {
          obj.includeValues.push(data.filterId);
        }
      });
      return obj;
    }
    case 'deviceMake': {
      obj.type = 'DEVICE_MAKE';
      values.forEach((data) => {
        if (data.isExclude) {
          obj.excludeValues.push(data.filterId);
        } else {
          obj.includeValues.push(data.filterId);
        }
      });
      return obj;
    }
    case 'deviceCarrier': {
      obj.type = 'DEVICE_CARRIER';
      values.forEach((data) => {
        if (data.isExclude) {
          obj.excludeValues.push(data.filterId);
        } else {
          obj.includeValues.push(data.filterId);
        }
      });
      return obj;
    }
    default: {
      return obj;
    }
  }
};

// ----------------- Start Include Params --------------------
