export default function reducer(
  state = {
    audienceCount: '',
  },
  action
) {
  switch (action.type) {
    case 'AUDIENCE_COUNT_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'AUDIENCE_COUNT_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        audienceCount: action.payload,
      };
    }
    case 'AUDIENCE_COUNT_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        audienceCount: action.payload,
      };
    }
    case 'AUDIENCE_COUNT_RESET': {
      return {
        ...state,
        changingStatus: 'reset',
        audienceCount: '',
      };
    }
    default: {
      return state;
    }
  }
}
