import { all, takeLatest, call, put } from 'redux-saga/effects';
import { fetchPoiPackageList } from '../../../ooh/plansService';
import {
  fetchPoiPackagesListAction,
  fetchPoiPackagesListFailedAction,
  fetchPoiPackagesListSuccessAction,
} from './poiPackagesList.reducer';

function* getPoiPackagesList({ payload }) {
  try {
    const poiPackagesListResponse = yield call(
      () => fetchPoiPackageList(payload),
      payload
    );
    yield put(fetchPoiPackagesListSuccessAction(poiPackagesListResponse));
  } catch (error) {
    fetchPoiPackagesListFailedAction();
  }
}

export function* poiPackagesListWatcher() {
  yield all([takeLatest(fetchPoiPackagesListAction, getPoiPackagesList)]);
}
