import { createSlice } from '@reduxjs/toolkit';
import { requestStatusConstants } from '../../../Integration/integration-util';

const initialState = {
  poiPackagesList: {},
  status: requestStatusConstants.INITIAL,
};

const oohSlice = createSlice({
  name: 'ooh',
  initialState,
  reducers: {
    fetchPoiPackagesListAction(state, action) {
      state.poiPackagesList = initialState.poiPackagesList;
      state.status = requestStatusConstants.PROGRESS;
    },
    fetchPoiPackagesListSuccessAction(state, action) {
      state.poiPackagesList = action.payload;
      state.status = requestStatusConstants.SUCCESS;
    },
    fetchPoiPackagesListFailedAction(state, action) {
      state.poiPackagesList = initialState.poiPackagesList;
      state.status = requestStatusConstants.FAILED;
    },
    resetPoiPackagesListAction(state, action) {
      state.poiPackagesList = initialState.poiPackagesList;
      state.status = initialState.status;
    },
  },
});

export const {
  fetchPoiPackagesListAction,
  fetchPoiPackagesListSuccessAction,
  fetchPoiPackagesListFailedAction,
  resetPoiPackagesListAction,
} = oohSlice.actions;
export default oohSlice.reducer;
