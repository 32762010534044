export default function reducer(
  state = {
    searchPlaceData: null,
  },
  action
) {
  switch (action.type) {
    case 'GET_CAMPAIGN_SEARCH_PLACE_DATA_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_CAMPAIGN_SEARCH_PLACE_DATA_SUCCESS': {
      const searchPlace = action.payload.DATA;
      return {
        ...state,
        changingStatus: 'success',
        searchPlaceData: {
          ...state.searchPlaceData,
          [action.payload.id]: searchPlace,
        },
      };
    }
    case 'GET_CAMPAIGN_SEARCH_PLACE_DATA_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        searchPlaceData: action.payload,
      };
    }
    case 'GET_CAMPAIGN_SEARCH_PLACE_DATA_RESET': {
      return {
        ...state,
        changingStatus: 'reset',
        searchPlaceData: null,
      };
    }
    default: {
      return state;
    }
  }
}
