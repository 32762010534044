import { put, takeEvery, call } from 'redux-saga/effects';
import get from '../getApiCaller';

const API_DATA = (payload) => {
  return get(`places/brands?countryId=${payload}`, 'Get', true).then(
    (response) => {
      return response;
    }
  );
};

export const GET_BRANDS_LIST_SAGA = function* perform_loginAction() {
  yield takeEvery('GET_BRANDS_LIST', function* (action) {
    yield put({ type: 'GET_BRANDS_LIST_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({
        type: 'GET_BRANDS_LIST_SUCCESS',
        payload: DATA,
      });
    } catch (error) {
      yield put({
        type: 'GET_BRANDS_LIST_FAILED',
        payload: error,
      });
    }
  });
};
