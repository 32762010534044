import React from 'react';
import { Route } from 'react-router-dom';
import LeadForm from 'components/LeadForm/LeadForm';

const PrivateRoute = ({
  component: Component,
  isAccess,
  fallback,
  accessMessage,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAccess ? (
          <Component {...props} />
        ) : (
          <>
            <LeadForm message={accessMessage} redirectTo={fallback} />
          </>
        )
      }
    />
  );
};

export default PrivateRoute;
