export default function reducer(
  state = {
    audienceData: {},
  },
  action
) {
  switch (action.type) {
    case 'GET_AUDIENCE_DATA_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_AUDIENCE_DATA_SUCCESS': {
      const audienceData = action.payload.DATA;
      const updatedAudienceData =
        audienceData.status &&
        audienceData.id &&
        audienceData.id.length &&
        audienceData.id.map((data) => {
          return {
            ...data,
            label: data.filterName,
            value: data.filterId,
          };
        });
      audienceData.id = updatedAudienceData;
      return {
        ...state,
        changingStatus: 'success',
        audienceData: {
          ...state.audienceData,
          [action.payload.id]: audienceData,
        },
      };
    }
    case 'GET_AUDIENCE_DATA_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        audienceData: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
