import { put, takeEvery, call } from 'redux-saga/effects';
import post from '../postApiCaller';

const API_DATA = (payload) => {
  const {
    pixelName,
    campaignName,
    startDate,
    endDate,
    attributionStartDate,
    attributionEndDate,
    budgetDataList,
    totalBudget,
    totalImpressions,
    competitorBrandIds,
    pixelPlaceMappingDataList,
    countryId,
    createdBy,
    userId,
    creativeGcsPath,
    ioGcsPath,
    activationType,
  } = payload;
  const body = {
    pixelName,
    campaignName,
    startDate,
    endDate,
    attributionStartDate,
    attributionEndDate,
    budgetDataList,
    totalBudget,
    totalImpressions,
    competitorBrandIds,
    pixelPlaceMappingDataList,
    countryId,
    createdBy,
    userId,
    creativeGcsPath,
    ioGcsPath,
  };
  return post(
    `pixel/new/${payload.segmentId}` +
      (payload.activationType ? `?activationType=${activationType}` : ''),
    'POST',
    body,
    true
  ).then((response) => {
    return response;
  });
};

export const CREATE_CAMPAIGN_SAGA = function* perform_audienceCountAction() {
  yield takeEvery('CREATE_CAMPAIGN', function* (action) {
    yield put({ type: 'CREATE_CAMPAIGN_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({ type: 'CREATE_CAMPAIGN_SUCCESS', payload: DATA });
    } catch (error) {
      yield put({ type: 'CREATE_CAMPAIGN_FAILED', payload: error });
    }
  });
};
