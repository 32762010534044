import { all } from 'redux-saga/effects';
import { customAudienceSaga } from './customAudience/customAudienceSaga';
import { oohSaga } from './ooh/oohSaga';
import SagaContainer from '../audience/builder/redux/saga';
import OohSagaContainer from '../ooh/Planner/redux/saga';
import campaignSagaContainer from '../campaign/redux/saga';

export const rootSaga = function* rootSaga() {
  yield all([
    SagaContainer(),
    OohSagaContainer(),
    campaignSagaContainer(),
    customAudienceSaga(),
    oohSaga(),
  ]);
};
