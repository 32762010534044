import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import './LsLoader.scss';

const LsLoader = ({ className, wrapperClassName, style, wrapperStyle }) => {
  return (
    <div className={'ls-spinner ' + wrapperClassName} style={wrapperStyle}>
      <Spinner color="primary" className={className} style={style} />
    </div>
  );
};

LsLoader.defaultProps = {
  className: '',
  wrapperClassName: '',
  style: {},
  wrapperStyle: {},
};

LsLoader.propTypes = {
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  style: PropTypes.object,
  wrapperStyle: PropTypes.object,
};

export default React.memo(LsLoader);
