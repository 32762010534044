import { put, takeLatest, call } from 'redux-saga/effects';
import get from '../../getApiCaller';

const getEndPointUrlWithValue = (
  name,
  type,
  countryId,
  customerId,
  accountId,
  value
) => {
  if (name === 'firstParty') {
    return `audience/filter?countryId=${countryId}&customerId=${customerId}&accountId=${accountId}&text=${value}&type=${type}`;
  }
  return `audience/filter?countryId=${countryId}&text=${value}&type=${type}`;
};

const getEndPointUrl = (name, type, countryId, customerId, accountId) => {
  if (name === 'firstParty') {
    return `audience/filter?countryId=${countryId}&customerId=${customerId}&accountId=${accountId}&type=${type}`;
  }
  return `audience/filter?countryId=${countryId}&type=${type}`;
};

const API_DATA = (payload) => {
  const { countryId, name, value, type, accountId, customerId } = payload;
  if (value) {
    return get(
      `${getEndPointUrlWithValue(
        name,
        type,
        countryId,
        customerId,
        accountId,
        value
      )}`,
      'Get',
      true
    ).then((response) => {
      return response;
    });
  }
  return get(
    `${getEndPointUrl(name, type, countryId, customerId, accountId)}`,
    'Get',
    true
  ).then((response) => {
    return response;
  });
};

export const DISCOVER_AUDIENCE_BY_CATEGORY_SAGA =
  function* perform_discoverAudience() {
    yield takeLatest('DISCOVER_AUDIENCE_BY_CATEGORY', function* (action) {
      yield put({ type: 'DISCOVER_AUDIENCE_BY_CATEGORY_STARTED' });
      try {
        const DATA = yield call(API_DATA.bind(this, action.payload));
        yield put({
          type: 'DISCOVER_AUDIENCE_BY_CATEGORY_SUCCESS',
          payload: {
            DATA,
            type: action.payload.type,
            name: action.payload.name,
          },
        });
      } catch (error) {
        yield put({
          type: 'DISCOVER_AUDIENCE_BY_CATEGORY_FAILED',
          payload: error,
        });
      }
    });
  };
