import fetch from 'cross-fetch';
import { checkStatus, parseJSON } from '../utils/responseHandler';
import { API_URL } from 'config/config';
const URL = API_URL + '/';

// Make an api call
const plannerGetApiCaller = async (url, method = 'get', authorization) => {
  let headers = {};
  if (authorization) {
    headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
    };
  } else {
    headers = {
      'Content-Type': 'application/json',
    };
  }
  return fetch(`${URL}${url}`, {
    method,
    headers,
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error) => {
      throw error;
    });
};

export default plannerGetApiCaller;
