import { put, takeEvery, call } from 'redux-saga/effects';
import post from '../../postApiCaller';
import COUNTRY_MAP from 'commons/countryMap';

const getSegmentListData = (selectedFilter, countryId) => {
  const countryCode = COUNTRY_MAP.find(
    (country) => country['id'] === countryId
  );
  const segmentlist = [];
  selectedFilter.forEach((data) => {
    if (data.type === 'BRAND') {
      segmentlist.push(`LSB${countryCode['code']}${data.filterId}`);
    } else if (data.type === 'FIRST_PARTY') {
      segmentlist.push(`LSF${data.filterId}`);
    } else if (data.type === 'CATEGORY') {
      segmentlist.push(`LSC${countryCode['code']}${data.filterId}`);
    } else if (data.type === 'APPCATEGORY') {
      segmentlist.push(`LSA${countryCode['code']}${data.filterId}`);
    } else if (data.type === 'APPNAME') {
      segmentlist.push(`LSA${countryCode['code']}${data.filterId}`);
    } else if (data.type === 'INTEREST') {
      segmentlist.push(`LSI${countryCode['code']}${data.filterId}`);
    } else if (data.type === 'GEO_BEHAVIOUR') {
      segmentlist.push(`LSG${countryCode['code']}${data.filterId}`);
    } else if (data.type === 'DEVICE_MAKE') {
      segmentlist.push(`LSM${countryCode['code']}${data.filterId}`);
    } else if (data.type === 'GEOGRAPHY') {
      segmentlist.push(`${data.filterId}`);
    } else {
      segmentlist.push('');
    }
  });
  return segmentlist;
};

const API_DATA = (payload) => {
  const { selectedFilters, countryId, period } = payload;
  const segmentList = getSegmentListData(selectedFilters, countryId);
  const body = {
    segmentlist: segmentList,
    period,
  };
  return post('audience/insight/aggregate', 'POST', body, true).then(
    (response) => {
      return response;
    }
  );
};

export const GET_INSIGHTS_DATA_BUILDER_SAGA = function* perform_insightsData() {
  yield takeEvery('GET_INSIGHTS_DATA_BUILDER', function* (action) {
    yield put({ type: 'GET_INSIGHTS_DATA_BUILDER_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({ type: 'GET_INSIGHTS_DATA_BUILDER_SUCCESS', payload: DATA });
      yield put({
        type: 'GET_INSIGHT_PERIOD_UPDATE',
        payload: { period: action.payload.period },
      });
    } catch (error) {
      yield put({ type: 'GET_INSIGHTS_DATA_BUILDER_FAILED', payload: error });
    }
  });
};
