import { put, takeEvery, call } from 'redux-saga/effects';
import get from '../../getApiCaller';

const API_DATA = (payload) => {
  const { countryId } = payload;
  return get(`places/states/${countryId}`, 'GET', true).then((response) => {
    return response;
  });
};

export const GET_CAMPAIGN_STATE_DATA_SAGA =
  function* perform_getCampaignStateData() {
    yield takeEvery('GET_CAMPAIGN_STATE_DATA', function* (action) {
      yield put({ type: 'GET_CAMPAIGN_STATE_DATA_STARTED' });
      try {
        const DATA = yield call(API_DATA.bind(this, action.payload));
        yield put({
          type: 'GET_CAMPAIGN_STATE_DATA_SUCCESS',
          payload: DATA,
        });
      } catch (error) {
        yield put({
          type: 'GET_CAMPAIGN_STATE_DATA_FAILED',
          payload: error,
        });
      }
    });
  };
