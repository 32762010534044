import { put, takeLatest, call } from 'redux-saga/effects';
import plannerGetApiCaller from '../../getApiCaller';

const FILTER_TYPE = {
  ACCEPTED_FORMATS: 'acceptedFormats',
  AIM_SCORE: 'aimScore',
  ASPECT_RATIO: 'aspectRatio',
  BILLBOARDS: 'billBoards',
  BLOCKLIST: 'blocklist',
  FACING_DIRECTION: 'facingDirection',
  MEDIA_CATEGORY_NAME: 'mediaCategoryName',
  MEDIA_FORMAT: 'mediaFormat',
  MEDIA_TYPE: 'mediaType',
  MEDIA_OWNER: 'mediaOwner',
  MEDIA_VEHICLE: 'mediaVehicle',
  MULTI_PANEL_COUNT: 'multiPanelCount',
  POST_CODE: 'postCode',
  PROGRAMMATIC_AVAILABILITY: 'programmaticAvailability',
  ORIENTATION: 'orientation',
  SA2: 'sa2',
  SCREEN_BOOKING_TIME: 'screenBookingTime',
  SCREEN_FORMAT: 'screenFormat',
  SCREEN_OPERATING_HOURS: 'screenOperatingHours',
  SCREEN_TYPE: 'screenType',
  SCREEN_TYPE_LIGHTED: 'screenTypeLighted',
  SCREEN_TYPE_PRINT_DIGITAL: 'screenType_print_digital',
};

const getEndPointUrlWithValue = (
  name,
  type,
  countryId,
  customerId,
  value,
  searchCurrPage,
  size
) => {
  if (
    name === FILTER_TYPE.ACCEPTED_FORMATS ||
    name === FILTER_TYPE.AIM_SCORE ||
    name === FILTER_TYPE.ASPECT_RATIO ||
    name === FILTER_TYPE.BILLBOARDS ||
    name === FILTER_TYPE.FACING_DIRECTION ||
    name === FILTER_TYPE.MEDIA_CATEGORY_NAME ||
    name === FILTER_TYPE.MEDIA_FORMAT ||
    name === FILTER_TYPE.MEDIA_OWNER ||
    name === FILTER_TYPE.MEDIA_TYPE ||
    name === FILTER_TYPE.MEDIA_VEHICLE ||
    name === FILTER_TYPE.MULTI_PANEL_COUNT ||
    name === FILTER_TYPE.ORIENTATION ||
    name === FILTER_TYPE.PROGRAMMATIC_AVAILABILITY ||
    name === FILTER_TYPE.SCREEN_BOOKING_TIME ||
    name === FILTER_TYPE.SCREEN_FORMAT ||
    name === FILTER_TYPE.SCREEN_OPERATING_HOURS ||
    name === FILTER_TYPE.SCREEN_TYPE ||
    name === FILTER_TYPE.SCREEN_TYPE_PRINT_DIGITAL ||
    name === FILTER_TYPE.SCREEN_TYPE_LIGHTED
  ) {
    return `ooh/filter/planner?countryId=${countryId}&customerId=${customerId}&text=${value}&type=${type}`;
  }
  return `ooh/filter/audience?countryId=${countryId}&text=${value}&type=${type}`;
};

const getEndPointUrl = (name, type, countryId, customerId, currPage, size) => {
  if (
    name === FILTER_TYPE.ACCEPTED_FORMATS ||
    name === FILTER_TYPE.AIM_SCORE ||
    name === FILTER_TYPE.ASPECT_RATIO ||
    name === FILTER_TYPE.BILLBOARDS ||
    name === FILTER_TYPE.BLOCKLIST ||
    name === FILTER_TYPE.FACING_DIRECTION ||
    name === FILTER_TYPE.MEDIA_CATEGORY_NAME ||
    name === FILTER_TYPE.MEDIA_FORMAT ||
    name === FILTER_TYPE.MEDIA_OWNER ||
    name === FILTER_TYPE.MEDIA_TYPE ||
    name === FILTER_TYPE.MEDIA_VEHICLE ||
    name === FILTER_TYPE.MULTI_PANEL_COUNT ||
    name === FILTER_TYPE.ORIENTATION ||
    name === FILTER_TYPE.POST_CODE ||
    name === FILTER_TYPE.PROGRAMMATIC_AVAILABILITY ||
    name === FILTER_TYPE.SA2 ||
    name === FILTER_TYPE.SCREEN_BOOKING_TIME ||
    name === FILTER_TYPE.SCREEN_FORMAT ||
    name === FILTER_TYPE.SCREEN_OPERATING_HOURS ||
    name === FILTER_TYPE.SCREEN_TYPE ||
    name === FILTER_TYPE.SCREEN_TYPE_PRINT_DIGITAL ||
    name === FILTER_TYPE.SCREEN_TYPE_LIGHTED
  ) {
    return `ooh/filter/planner?countryId=${countryId}&customerId=${customerId}&type=${type}`;
  } else if (name === 'geography') {
    if (
      +process.env.REACT_APP_SIGHTLINE_USE_OLDER_GEO_API_FOR_HONG_KONG &&
      countryId === 48
    ) {
      return `audience/filter?countryId=${countryId}&type=geography`;
    }
    return `ooh/filter/audience/geography?countryId=${countryId}`;
  } else if (name === 'package') {
    return `screeniq/customPlanner`;
  }
  return `ooh/filter/audience?countryId=${countryId}&type=${type}`;
};

const API_DATA = (payload) => {
  const { countryId, value, type, customerId, name } = payload;
  if (value) {
    return plannerGetApiCaller(
      `${getEndPointUrlWithValue(name, type, countryId, customerId, value)}`,
      'Get',
      true
    ).then((response) => {
      return response;
    });
  }
  return plannerGetApiCaller(
    `${getEndPointUrl(name, type, countryId, customerId)}`,
    'Get',
    true
  ).then((response) => {
    return response;
  });
};

export const FILTER_VALUES_SAGA = function* perform_getFilterValues() {
  yield takeLatest('FILTER_VALUES', function* (action) {
    yield put({ type: 'FILTER_VALUES_STARTED' });
    if (action.payload.unMount) {
      try {
        let status = true;
        const DATA = {
          status,
          id: action.payload.result,
        };
        yield put({
          type: 'FILTER_VALUES_SUCCESS',
          payload: {
            DATA,
            unMount: action.payload.unMount,
            postCodePackages: action.payload.postCodePackages,
          },
        });
      } catch (error) {
        yield put({
          type: 'FILTER_VALUES_FAILED',
          payload: error,
        });
      }
    } else if (action.payload.searchFlag) {
      try {
        const status = true;
        const DATA = {
          status,
          id: action.payload.result,
        };
        yield put({
          type: 'FILTER_VALUES_SUCCESS',
          payload: {
            DATA,
            type: action.payload.name,
            value: action.payload.value,
            searchFlag: action.payload.searchFlag,
            postCodePackages: action.payload.postCodePackages,
          },
        });
      } catch (error) {
        yield put({
          type: 'FILTER_VALUES_FAILED',
          payload: error,
        });
      }
    } else {
      try {
        const DATA = yield call(API_DATA.bind(this, action.payload));
        yield put({
          type: 'FILTER_VALUES_SUCCESS',
          payload: {
            DATA,
            type: action.payload.name,
            value: action.payload.value,
            searchFlag: action.payload.searchFlag,
            postCodePackages: action.payload.postCodePackages,
          },
        });
      } catch (error) {
        yield put({
          type: 'FILTER_VALUES_FAILED',
          payload: error,
        });
      }
    }
  });
};
