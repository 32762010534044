import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

import Login from './Login/Login';
import ForgotPassword from './ManagePassword/ForgotPassword';
import ChangePassword from './ManagePassword/ChangePassword';
import { ReactComponent as JourneysLogoSvg } from 'assets/new-img/journeys-images/journeys-logo.svg';
import landingBackground from 'assets/new-img/journeys-images/login-background.png';
import { isAuthenticatedNew } from 'layouts/Landing/newAuthService';
import './LandingLayout.scss';

const landingBgStyle = {
  backgroundImage: `url(${landingBackground})`,
};

const LandingLayout = (props) => {
  const isAuthenticated = isAuthenticatedNew();
  useEffect(() => {
    isAuthenticated && props.history.push('/dashboard');
  }, [isAuthenticated]);

  document.title = 'Lifesight - Customer Intelligence Platform';

  return (
    <div className="new-landing-page-wrapper" style={landingBgStyle}>
      <Card className="auth-card">
        <h3 className="text-center m-4 pt-3">
          <JourneysLogoSvg />
        </h3>

        <CardBody>
          <Switch>
            <Route
              path="/auth/login"
              render={(props) => <Login {...props} />}
            />
            <Route
              path="/auth/forgot-password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              path="/auth/setpassword"
              render={(props) => <ChangePassword {...props} />}
            />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
        </CardBody>
      </Card>
    </div>
  );
};

export default LandingLayout;
