import { createSlice } from '@reduxjs/toolkit';
import { requestStatusConstants } from '../../../Integration/integration-util';

const initialState = {
  validatePoiPackageNameResponse: {},
  status: requestStatusConstants.INITIAL,
};

const oohSlice = createSlice({
  name: 'ooh',
  initialState,
  reducers: {
    validatePoiPackageNameAction(state, action) {
      state.validatePoiPackageNameResponse =
        initialState.validatePoiPackageNameResponse;
      state.status = requestStatusConstants.PROGRESS;
    },
    validatePoiPackageNameSuccessAction(state, action) {
      state.validatePoiPackageNameResponse = action.payload;
      state.status = requestStatusConstants.SUCCESS;
    },
    validatePoiPackageNameFailedAction(state, action) {
      state.validatePoiPackageNameResponse =
        initialState.validatePoiPackageNameResponse;
      state.status = requestStatusConstants.FAILED;
    },
    resetValidatePoiPackageNameResponseAction(state, action) {
      state.validatePoiPackageNameResponse =
        initialState.validatePoiPackageNameResponse;
      state.status = initialState.status;
    },
  },
});

export const {
  validatePoiPackageNameAction,
  validatePoiPackageNameSuccessAction,
  validatePoiPackageNameFailedAction,
  resetValidatePoiPackageNameResponseAction,
} = oohSlice.actions;
export default oohSlice.reducer;
