import React, { useState } from 'react';
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
} from 'reactstrap';

const InputField = React.forwardRef(
  ({ prefix, suffix, error, ...props }, ref) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <FormGroup>
        <InputGroup
          className={'ls-input-group ' + (isFocused ? 'input-group-focus' : '')}
        >
          {prefix && (
            <InputGroupAddon addonType="prepend">
              <InputGroupText>{prefix}</InputGroupText>
            </InputGroupAddon>
          )}

          <Input
            {...props}
            innerRef={ref}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />

          {suffix && (
            <InputGroupAddon addonType="append">
              <InputGroupText>{suffix}</InputGroupText>
            </InputGroupAddon>
          )}
        </InputGroup>

        <div className="error">{error && error.message}</div>
      </FormGroup>
    );
  }
);

export default InputField;
