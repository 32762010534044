export default function reducer(
  state = {
    stateData: '',
  },
  action
) {
  switch (action.type) {
    case 'GET_CAMPAIGN_STATE_DATA_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_CAMPAIGN_STATE_DATA_SUCCESS': {
      const stateData = action.payload;
      const updatedStateData = stateData.id.map((data) => {
        return {
          ...data,
          label: data.name,
          value: data.id,
        };
      });
      stateData.id = updatedStateData;
      return {
        ...state,
        changingStatus: 'success',
        stateData,
      };
    }
    case 'GET_CAMPAIGN_STATE_DATA_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        stateData: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
