import { put, takeEvery, call } from 'redux-saga/effects';
import get from '../../getApiCaller';

const API_DATA = (payload) => {
  const { countryId, customerId, accountId, value } = payload;
  return get(
    `audience/filter?countryId=${countryId}&customerId=${customerId}&accountId=${accountId}&text=${value}&type=brand,category,geography,appname,appcategory,interest,geo_behaviour,first_party`,
    'Get',
    true
  ).then((response) => {
    return response;
  });
};
export const GET_AUDIENCE_DATA_SAGA = function* perform_getAudienceData() {
  yield takeEvery('GET_AUDIENCE_DATA', function* (action) {
    yield put({ type: 'GET_AUDIENCE_DATA_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({
        type: 'GET_AUDIENCE_DATA_SUCCESS',
        payload: { DATA, id: action.payload.id },
      });
    } catch (error) {
      yield put({
        type: 'GET_AUDIENCE_DATA_FAILED',
        payload: error,
      });
    }
  });
};
