import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { ReactComponent as SidebarHeadingSVG } from 'assets/new-img/journeys-images/sidebar-heading.svg';
import { ReactComponent as JourneySVG } from 'assets/new-img/journeys-images/sidebar/journeys-sidebar-logo.svg';
import { ReactComponent as AccountSvg } from 'assets/new-img/sidebar-opt/Account.svg';
import { ReactComponent as IntercomSvg } from 'assets/new-img/sidebar-opt/Intercom.svg';
import { ReactComponent as ProfileSvg } from 'assets/new-img/sidebar/Profile.svg';
import {
  newLogout,
  setSelectedAccount,
  setAccessData,
} from 'layouts/Landing/newAuthActions';
import { switchAccount } from 'layouts/Landing/newAuthService';
import routes from 'layouts/route-config';
import { NavLink } from 'react-router-dom';
import { Popover, PopoverBody, ListGroup, ListGroupItem } from 'reactstrap';
import { useDetectOutsideClick } from 'reusable/hooks/useDetectOutsideClick';
import { clearToken, clearEmail, setToken } from 'utils/storageUtil';

import './Sidebar.scss';

const Sidebar = ({ userData, selectedAccount, selectedCountry, ...props }) => {
  const sidebarItems = useMemo(() =>
    routes.filter((route) => route.isSidenav === true)
  );
  const [activeItem, setActiveItem] = useState(null);
  const [isNavMini, setIsNavMini] = useState(true);

  const accountRef = useRef(null);
  const profileRef = useRef(null);
  const [isAccount, setIsAccount] = useDetectOutsideClick(accountRef, false);
  const [isProfile, setIsProfile] = useDetectOutsideClick(profileRef, false);
  const toggleAccount = () => setIsAccount(!isAccount);
  const toggleProfile = () => setIsProfile(!isProfile);

  const activePath = props.location.pathname;
  useEffect(() => {
    const activeItem = routes.find((item) => activePath.startsWith(item.path));
    setActiveItem(activeItem);
    if (
      !activeItem ||
      !activeItem.routes ||
      !activeItem.routes.find((r) => !!r.name)
    ) {
      setIsNavMini(true);
    }
  }, [activePath]);

  const handleSidenavClick = (item) => {
    if (item.path === activeItem.path) {
      setIsNavMini(!isNavMini);
    } else {
      setIsNavMini(false);
    }

    if (!item.routes || !item.routes.find((r) => !!r.name)) {
      setIsNavMini(true);
    }

    const selectedLink = item.routes
      ? item.routes.find((r) => r.isDefault === true)
      : item;
    props.history.push(item.routes ? item.path + selectedLink.path : item.path);
  };

  const handleLogout = () => {
    window.Intercom('shutdown');
    window.Intercom('boot', { app_id: 'lcjnbo0d' });
    window.Intercom('trackEvent', 'Log out');
    clearToken();
    clearEmail();
    props.dispatch(newLogout());
  };

  const handleSwitchAccount = (account) => {
    if (selectedAccount && selectedAccount.accountId !== account.accountId) {
      const {
        isAttribution,
        isAudience,
        isOOHPlanning,
        attributionWriteAccess,
        audienceWriteAccess,
        isCIP,
      } = account;
      props.dispatch(
        setAccessData({
          isAttribution,
          isAudience,
          isAttributionWrite: isAttribution && attributionWriteAccess,
          isAudienceWrite: isAudience && audienceWriteAccess,
          isScreenIq: isOOHPlanning,
          isIntegration: isCIP,
        })
      );
      switchAccount(userData.id, account.accountId).then((response) => {
        setToken(response.headers.authorization);
        props.dispatch(setSelectedAccount(account));
      });
      toggleAccount();
      if (
        props.location.pathname.includes('report') ||
        props.location.pathname.includes('tracker') ||
        props.location.pathname.includes('insight')
      ) {
        const defaultPath = activeItem.routes.find((r) => r.isDefault === true);
        props.history.push(activeItem.path + defaultPath.path);
      }
    }
  };

  useLayoutEffect(() => {
    isNavMini
      ? document.body.classList.add('s-mini')
      : document.body.classList.remove('s-mini');
  }, [isNavMini]);

  return (
    <aside className={'d-flex ls-ui-sidebar'}>
      <div className="sidebar-icons d-flex flex-column">
        <div
          className="p-3"
          onClick={() =>
            handleSidenavClick(routes.find((r) => r.path === '/dashboard'))
          }
        >
          <JourneySVG className="side-svg-icon" />
        </div>

        <ul className="sidebar-scroll">
          {sidebarItems.map((item, i) => {
            return (
              <li
                key={i}
                className={
                  activePath.startsWith(item.path) ? 'selected-side' : ''
                }
                onClick={() => handleSidenavClick(item)}
              >
                <item.icon className="side-svg-icon" title={item.name} />
              </li>
            );
          })}
        </ul>

        <div className="mt-auto">
          {selectedCountry && (
            <div className="country-badge">{selectedCountry.code}</div>
          )}

          <AccountSvg className="side-svg-icon" id="account-popover" />
          <ProfileSvg className="side-svg-icon" id="profile-popover" />
          <IntercomSvg className="side-svg-icon" id="ls-intercom-link" />

          <Popover
            placement="right-end"
            isOpen={isAccount}
            toggle={toggleAccount}
            target="account-popover"
            popperClassName="sidenav-popover"
            innerRef={accountRef}
          >
            <PopoverBody>
              {userData && userData.accountDataList && (
                <div>
                  {selectedAccount && (
                    <ListGroup flush>
                      <ListGroupItem className="p-2 font-weight-bold text-primary fs-16">
                        {selectedAccount.name}
                      </ListGroupItem>
                    </ListGroup>
                  )}

                  <ListGroup className="account-list" flush>
                    {userData.accountDataList.map((account) => {
                      const isSelected =
                        selectedAccount &&
                        selectedAccount.accountId === account.accountId
                          ? true
                          : false;
                      return (
                        <ListGroupItem
                          key={account.accountId}
                          className={
                            'p-2 cursor-pointer' +
                            (isSelected ? ' text-primary' : '')
                          }
                          onClick={() => handleSwitchAccount(account)}
                        >
                          {account.name}
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                </div>
              )}
            </PopoverBody>
          </Popover>

          <Popover
            placement="right-end"
            isOpen={isProfile}
            toggle={toggleProfile}
            target="profile-popover"
            popperClassName="sidenav-popover"
            innerRef={profileRef}
          >
            <PopoverBody>
              <div className="p-2 font-weight-bold fs-16">
                {userData && userData.name}
              </div>
              <hr className="my-1" />
              <NavLink
                to={'/admin/profile'}
                className="d-block p-2 text-primary font-weight-bold"
                onClick={toggleProfile}
              >
                Profiles
              </NavLink>
              <NavLink
                to={'/admin/account'}
                className="d-block p-2 text-primary font-weight-bold"
                onClick={toggleProfile}
              >
                Accounts
              </NavLink>
              <NavLink
                to={'/admin/users'}
                className="d-block p-2 text-primary font-weight-bold"
                onClick={toggleProfile}
              >
                Users
              </NavLink>
              <hr className="my-1" />
              <NavLink
                to={'#'}
                className="d-block p-2 text-primary font-weight-bold"
                onClick={() =>
                  window.open(
                    'https://support.lifesight.io/en/',
                    '_blank'
                  )
                }
              >
                Help
              </NavLink>
              <hr className="my-1" />
              <NavLink
                to={'/auth/login'}
                className="d-block p-2 text-primary font-weight-bold"
                onClick={handleLogout}
              >
                Logout
              </NavLink>
            </PopoverBody>
          </Popover>
        </div>
      </div>

      {activeItem && activeItem.routes && (
        <div className="sidebar-pages p-3">
          {/* <div className='sidebar-page-name px-3 py-2'>{activeItem.name}</div> */}

          <div className="pb-3">
            <SidebarHeadingSVG className="side-svg-icon" />
          </div>
          {activeItem.routes
            .filter((r) => !!r.name)
            .map((route, i) => {
              return (
                <NavLink
                  key={i}
                  className="sidebar-page-links px-3"
                  activeClassName="ls-active-link"
                  to={activeItem.path + route.path}
                  exact
                >
                  {route.name}
                </NavLink>
              );
            })}
        </div>
      )}
    </aside>
  );
};

export default Sidebar;
