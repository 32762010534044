import _ from 'lodash';
// ----------------- Start Insight Doughnut Chart Data -----------------
import { FaCheck } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

import { HOME_POSTCODE, HOME_POSTCODE_LABEL, OBJECT, WORK_POSTCODE, WORK_POSTCODE_LABEL } from '../../../commons/constants';
import { API_URL } from '../../../config/config';

import { fetch } from '../../../utils/httpUtil';

export const getDoughnutData = (data) => {
  let total = 0;
  data &&
    data.forEach((val) => {
      total += val;
    });
  return (
    data &&
    data.map((val) => {
      return ((val / total) * 100).toFixed(2);
    })
  );
};

export const getDataListName = (names) => {
  return (
    (names &&
      names.map((data) => {
        return data.charAt(0).toUpperCase() + data.slice(1).toLowerCase();
      })) ??
    []
  );
};
export const getTopTenDataListNames = (dataList, chartType) => {
  let dataListEntry =
    chartType === 'home'
      ? dataList && dataList?.home
      : dataList && dataList?.work;
  return Object.fromEntries(
    Object.entries(dataListEntry)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 10)
  );
};

// Add Experian data code changes
export const getAgeAndGenderData = (dataList, chartType) => {
  return chartType === 'age'
    ? dataList && dataList?.['age']
    : dataList && dataList?.['gender'];
};

export const getDoughnutChartData = (data) => {
  const dataList = data.data;
  return {
    labels: getDataListName(dataList.names),
    datasets: [
      {
        backgroundColor: ['#072164', '#396FC9', '#46CDC5'],
        data: getDoughnutData(dataList.counts),
      },
    ],
  };
};

// ----------------- End Insight Doughnut Chart Data -----------------

// ----------------- Start Insight Segment Title -----------------

export const getInsightSegmentTitle = (segmentType) => {
  switch (segmentType) {
    case 'AGE':
      return 'Age';
    case 'GENDER':
      return 'Gender';
    case 'APP_NAME':
      return 'App Usage';
    case 'APP_CATEGORY':
      return 'App Category';
    case 'CATEGORY':
      return 'Category';
    case 'IAB_CATEGORY':
      return 'Interests';
    case 'DEVICE_CARRIER':
      return 'Device Carrier';
    case 'GEO_BEHAVIOUR':
      return 'GEO Behaviour';
    case 'BRAND':
      return 'Brand';
    case 'OOH':
      return 'OOH';
    case 'DEVICE_MODEL':
      return 'Device Model';
    case 'DEVICE_MAKE':
      return 'Device Make';
    case 'EVENTS':
      return 'Events';
    case 'INTENT':
      return 'Intent';
    case 'ONLINE_BRAND':
      return 'Online Brands';

    default:
      return '';
  }
};

// ----------------- End Insight Segment Title -----------------

// ----------------- Start Filter Value Count ------------------

export const getFilteredValueCount = (values, name) => {
  if (
    values.changingStatus === 'success' &&
    values.filteredValues.status &&
    values.filteredValues.id &&
    values.filteredValues.id.length > 0
  ) {
    if (
      name === 'audiences' &&
      values.type === name &&
      values.value === undefined
    ) {
      return values.filteredValues.count;
    }
    return values.filteredValues.id.length;
  }
  return 0;
};

// ----------------- End Filter Value Count ------------------

// --------------------- Start Circle on map -----------------------

export const createGeoJSONCircle = (center, radiusInKm, points) => {
  if (!points) {
    points = 64;
  }

  const coords = {
    latitude: center[1],
    longitude: center[0],
  };

  const km = radiusInKm;

  const ret = [];
  const distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
  const distanceY = km / 110.574;

  let theta;
  let x;
  let y;
  for (let i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([coords.longitude + x, coords.latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [ret],
          },
        },
      ],
    },
  };
};

// --------------------- End Circle on map -----------------------

// --------------------- Start of Audience SubFilter ---------------

export const getTitleFromAudienceSubFilter = (name) => {
  switch (name) {
    case 'brand':
      return 'Brand Shoppers';
    case HOME_POSTCODE:
      return HOME_POSTCODE_LABEL;
    case WORK_POSTCODE:
      return WORK_POSTCODE_LABEL;
    case 'category':
      return 'Place Category Visitors';
    case 'geoBehaviour':
      return 'Geo Behavioural Personas';
    case 'appCategory':
      return 'App Category';
    case 'demographics':
      return 'Demography';
    case 'age':
      return 'Age';
    case 'gender':
      return 'Gender';
    case 'intent':
      return 'Intent';
    case 'online_brand':
      return 'Online Brands';
    case 'events':
      return 'Events';
    case 'customAudience':
      return 'Custom Audience';
    default:
      return '';
  }
};
// --------------------- End of Audience SubFilter ---------------

// --------------------- Start of Screen Type Filters ---------------

export const getTitleFromScreenTypeFilter = (name) => {
  switch (name) {
    case 'parentScreenTypeFilters':
      return 'Level 1 Categories';
    case 'childScreenTypeFilters':
      return 'Level 2 Categories';
    case 'grandchildScreenTypeFilters':
      return 'Level 3 Categories';
    case 'greatGrandChildScreenTypeFilters':
      return 'Level 4 Categories';
    default:
      return '';
  }
};
// --------------------- End of Screen Type Filters ---------------

// --------------------- Start of Post Code Filters ---------------

export const getTitleFromPostCodeFilter = (name) => {
  switch (name) {
    case 'parentScreenTypeFilters':
      return 'States';
    case 'childScreenTypeFilters':
      return 'Cities';
    case 'grandchildScreenTypeFilters':
      return 'Postal Codes';
    case 'postCodePackages':
      return 'Post Code Packages';
    default:
      return '';
  }
};
// --------------------- End of Post Code Filters ---------------

// --------------------- Start of checked values ------------------

export const getCheckedData = (original, selectedList) => {
  if (
    selectedList.findIndex(
      (obj) => obj.filterId === original.filterId && obj.type === original.type
    ) === -1
  ) {
    return false;
  }
  return true;
};

// --------------------- End of checked values ------------------

// --------------------- Start of get media format list ------------

export const getMediaFormatList = (id) => {
  if (id.length) {
    // const t1 = performance.now();
    let mediaFormatMap = new Map();
    const dataLength = id.length;
    for (let i = 0; i < dataLength; i++) {
      for (let k = 0; k < id[i]['mediaFormats'].length; k++) {
        if (mediaFormatMap.has(id[i]['mediaFormats'][k])) {
          mediaFormatMap.set(
            id[i]['mediaFormats'][k],
            mediaFormatMap.get(id[i]['mediaFormats'][k]) + 1
          );
        } else {
          mediaFormatMap.set(id[i]['mediaFormats'][k], 1);
        }
      }
    }

    const mediaFormatArray = [];
    for (let [key, value] of mediaFormatMap) {
      mediaFormatArray.push({
        label: key,
        value: value,
      });
    }

    mediaFormatArray.sort((a, b) => {
      return b.value - a.value;
    });

    let mediaListData = [];
    // let obj = {
    //   label: "others",
    //   value: 0,
    // };

    // if(mediaFormatArray.length > 10) {
    mediaFormatArray.forEach((item, i) => {
      // if(i < 9) {
      mediaListData.push(item);
      // } else {
      //   obj.value = obj.value + item.value
      // }
    });

    // mediaListData.push(obj);
    // } else {
    // mediaListData = [...mediaFormatArray];
    // }
    return mediaListData;
  }

  return [];
};

// export const getMediaFormatList = (id) => {
//   let mediaFormat = [];
//   if (id.length) {
//     console.log('input', id);
//     const t1 = performance.now();
//     id.forEach((data) => {
//       mediaFormat = [...mediaFormat, ...data.mediaFormats];
//     });
//     const count = {};
//     mediaFormat.forEach((i) => {
//       count[i] = (count[i] || 0) + 1;
//     });
//     const list = Object.keys(count).map((data) => {
//       return {
//         label: data,
//         value: count[data],
//       };
//     });
//     list.sort((a, b) => {
//       return b.value - a.value;
//     });
//     const mediaListData = [];
//     let obj = {
//       label: "others",
//       value: 0,
//     };
//     list.forEach((data, index) => {
//       if (index < 9) {
//         mediaListData.push(data);
//       } else {
//         obj = {
//           label: "others",
//           value: obj.value + data.value,
//         };
//       }
//     });
//     mediaListData.push(obj);
//     const t2 = performance.now();
//     console.log('pp', t2-t1);
//     console.log('output', mediaListData);
//     return mediaListData;
//   }
//   return [];
// };

export const getArcColor = (mediaFormats, data, colors) => {
  mediaFormats &&
    mediaFormats.map((item, i) => {
      return (item.color = colors[i]);
    });
  const temp =
    mediaFormats &&
    mediaFormats.filter((item) => {
      return item.label === data.data;
    });
  return temp[0] && temp[0].color;
};

export const getColorAccoringToFormat = (mediaFormats, data, colorsList) => {
  mediaFormats &&
    mediaFormats.map((item, i) => {
      return (item.colorClassName = colorsList[i]);
    });
  const temp =
    mediaFormats &&
    mediaFormats.filter((item) => {
      return item.label === data.label;
    });
  return temp[0] && temp[0].colorClassName;
};

export const newMethod = (
  type,
  values,
  expressions = [],
  excludeAudienceForScreenFilter,
  allPostCodeData
) => {
  const obj = { type: '', includeValues: [] };
  switch (type) {
    case 'sa2': {
      obj.type = 'SA2';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'blocklist': {
      obj.type = 'BLOCKLIST';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'billBoards': {
      obj.type = 'BILLBOARD';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'programmaticAvailability': {
      obj.type = 'PROGRAMMATIC_AVAILABILITY';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'screenType_print_digital': {
      obj.type = 'MEDIA_TYPE_PRINT_DIGITAL';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'screenTypeLighted': {
      obj.type = 'MEDIA_TYPE_LIGHTED';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'multiPanelCount': {
      obj.type = 'MULTI_PANEL_COUNT';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'geography': {
      obj.type = 'GEOGRAPHY';
      let uniqueValues = [
        ...new Map(
          values.map((item) =>
            item['level1Id']
              ? [item['level1Id'], item]
              : [item['level2Id'], item]
          )
        ).values(),
      ];
      uniqueValues.forEach((data) => {
        if (data.level1Id) {
          obj.includeValues.push(data.level1Id);
        } else {
          obj.includeValues.push(data.level2Id);
        }
      });
      return [obj];
    }
    case 'mediaFormat': {
      obj.type = 'MEDIA_FORMAT';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId.toString());
      });
      return [obj];
    }
    case 'mediaOwner': {
      obj.type = 'MEDIA_OWNER';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId.toString());
      });
      return [obj];
    }
    case 'audiences': {
      if (!excludeAudienceForScreenFilter) {
        obj.type = 'AUDIENCES';
        obj.expressions = expressions;
        values.forEach((data) => {
          obj.includeValues.push(data.filterName);
        });
      }
      return [obj];
    }
    case 'interests': {
      const poiPackageObj = { type: 'POI_PACKAGE', includeValues: [] };
      obj.type = 'POI';
      values.forEach((value) => {
        if (value.type === 'POI_PACKAGE') {
          poiPackageObj.includeValues.push(value.filterId.toString());
        } else {
          obj.includeValues.push(value.filterId.toString());
        }
      });
      const objects = [];
      poiPackageObj.includeValues.length > 0 && objects.push(poiPackageObj);
      obj.includeValues.length > 0 && objects.push(obj);
      return objects;
    }
    case 'postCode': {
      obj.type = 'GEO_TYPE';
      obj.parentIds = [];
      obj.childIds = [];
      obj.grandChildIds = [];
      if (values?.postCodePackages?.length) {
        let screenParentIds = [];
        let screenTypeGrandChildIds = [];
        values.postCodePackages.forEach((postCodePackage) => {
          screenParentIds = [
            ...screenParentIds,
            ...postCodePackage.screenParentIds,
          ];
          screenTypeGrandChildIds = [
            ...screenTypeGrandChildIds,
            ...postCodePackage.screenTypeGrandChildIds,
          ];
        });

        let parentIds = [];
        values['parentScreenTypeFilters'].forEach((parentID) => {
          parentIds.push(parentID.filterId.toString());
        });

        const allIncludedGrandChildFilters = _.uniqBy(
          [
            ...allPostCodeData
              .filter(
                (postCodeData) =>
                  postCodeData.title === 'grandchildScreenTypeFilters'
              )[0]
              .data.filter((grandChildFilter) =>
                screenTypeGrandChildIds.includes(grandChildFilter.filterId)
              ),
          ],
          'filterId'
        );
        const allRelatedChildIds = allIncludedGrandChildFilters.map(
          (grandChildFilter) => grandChildFilter.screenParentId
        );
        const allIncludedChildFilters = _.uniqBy(
          [
            ...allPostCodeData
              .filter(
                (postCodeData) =>
                  postCodeData.title === 'childScreenTypeFilters'
              )[0]
              .data.filter((childFilter) =>
                allRelatedChildIds.includes(childFilter.filterId)
              ),
          ],
          'filterId'
        );
        const allRelatedParentIds = allIncludedChildFilters.map((childFilter) =>
          childFilter.screenParentId.toString()
        );
        obj.parentIds = [...new Set([...allRelatedParentIds, ...parentIds])];

        let childIds = [];
        values['childScreenTypeFilters'].forEach((parentID) => {
          childIds.push(parentID.filterId.toString());
        });
        obj.childIds = [...new Set([...screenParentIds, ...childIds])];

        let grandChildIds = [];
        values['grandchildScreenTypeFilters'].forEach((parentID) => {
          grandChildIds.push(parentID.filterId.toString());
        });
        obj.grandChildIds = [
          ...new Set([...screenTypeGrandChildIds, ...grandChildIds]),
        ];
      } else {
        values['parentScreenTypeFilters'].forEach((parentID) => {
          obj.parentIds.push(parentID.filterId.toString());
        });
        values['childScreenTypeFilters'].forEach((parentID) => {
          obj.childIds.push(parentID.filterId.toString());
        });
        values['grandchildScreenTypeFilters'].forEach((parentID) => {
          obj.grandChildIds.push(parentID.filterId.toString());
        });
      }
      return [obj];
    }
    case 'screenType': {
      obj.type = 'SCREEN_TYPE';
      obj.parentIds = [
        ...(values['parentScreenTypeFilters']?.map(({ filterId }) =>
          filterId.toString()
        ) ?? []),
      ];
      obj.childIds = [
        ...(values['childScreenTypeFilters']?.map(({ filterId }) =>
          filterId.toString()
        ) ?? []),
      ];
      obj.grandChildIds = [
        ...(values['grandchildScreenTypeFilters']?.map(({ filterId }) =>
          filterId.toString()
        ) ?? []),
      ];
      obj.greatGrandChildIds = [
        ...(values['greatGrandChildScreenTypeFilters']?.map(({ filterId }) =>
          filterId.toString()
        ) ?? []),
      ];
      return [obj];
    }
    case 'screenFormat': {
      obj.type = 'SCREEN_FORMAT';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId.toString());
      });
      return [obj];
    }
    case 'facingDirection': {
      obj.type = 'FACING_DIRECTION';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId.toString());
      });
      return [obj];
    }
    case 'orientation': {
      obj.type = 'ORIENTATION';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId.toString());
      });
      return [obj];
    }
    case 'aspectRatio': {
      obj.type = 'ASPECT_RATIO';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId.toString());
      });
      return [obj];
    }
    case 'acceptedFormats': {
      obj.type = 'ACCEPTED_FORMATS';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId.toString());
      });
      return [obj];
    }
    case 'screenBookingTime': {
      obj.type = 'SCREEN_BOOKING_TIME';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId.toString());
      });
      return [obj];
    }
    case 'screenOperatingHours': {
      const operatingTimes = [];
      obj.includeValues = [];
      obj.type = 'SCREEN_OPERATING_HOURS';
      values &&
        values?.forEach((item) => {
          let tempObj = {};
          tempObj.day = item.filterId;
          tempObj.openingHours = item.openingHours;
          tempObj.closingHours = item.closingHours;
          operatingTimes.push(tempObj);
        });
      obj.operatingTimes = operatingTimes;
      return [obj];
    }
    case 'mediaVehicle': {
      obj.type = 'MEDIA_VEHICLE';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId.toString());
      });
      return [obj];
    }
    case 'mediaCategoryName': {
      obj.type = 'MEDIA_CATEGORY_NAME';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId.toString());
      });
      return [obj];
    }
    case 'mediaType': {
      obj.type = 'MEDIA_TYPE';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId.toString());
      });
      return [obj];
    }
    case 'aimScore': {
      obj.type = 'AIM_SCORE';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId.toString());
      });
      return [obj];
    }
    default: {
      return [obj];
    }
  }
};
export const getPayloadForSavePlan = (
  type,
  values,
  expressions = [],
  distance
) => {
  const obj = { type: '', includeValues: [] };
  switch (type) {
    case 'blocklist': {
      obj.type = 'BLOCKLIST';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'sa2': {
      obj.type = 'SA2';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'billBoards': {
      obj.type = 'BILLBOARD';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'programmaticAvailability': {
      obj.type = 'PROGRAMMATIC_AVAILABILITY';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'screenType_print_digital': {
      obj.type = 'MEDIA_TYPE_PRINT_DIGITAL';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }

    case 'screenTypeLighted': {
      obj.type = 'MEDIA_TYPE_LIGHTED';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }

    case 'multiPanelCount': {
      obj.type = 'MULTI_PANEL_COUNT';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'geography': {
      obj.type = 'GEOGRAPHY';
      let uniqueValues = [
        ...new Map(values.map((item) => [item.filterId, item])).values(),
      ];
      uniqueValues.forEach((data) => {
        if (data.level1Id) {
          obj.includeValues.push(data.filterId);
        } else {
          obj.includeValues.push(data.filterId);
        }
      });
      return [obj];
    }
    case 'mediaFormat': {
      obj.type = 'MEDIA_FORMAT';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'mediaOwner': {
      obj.type = 'MEDIA_OWNER';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'audiences': {
      obj.type = 'AUDIENCES';
      obj.expressions = expressions;
      values.forEach((data) => {
        obj.includeValues.push(data.filterName);
      });
      return [obj];
    }
    case 'interests': {
      obj.type = 'POI';
      obj.brandIds = [];
      obj.categoryIds = [];
      const poiPackage = { type: 'POI_PACKAGE', includeValues: [] };
      values.forEach((data) => {
        if (data.type === 'BRAND') {
          obj.brandIds.push(data.filterId);
        } else if (data.type === 'CATEGORY') {
          obj.categoryIds.push(data.filterId);
        } else {
          poiPackage.includeValues.push(data.filterId);
        }
      });
      obj.distance = distance;
      const objects = [];
      (obj.brandIds.length > 0 || obj.categoryIds.length > 0) &&
        objects.push(obj);
      if (poiPackage.includeValues.length > 0) {
        poiPackage.distance = distance;
        objects.push(poiPackage);
      }
      return objects;
    }
    case 'postCode': {
      obj.type = 'GEO_TYPE';
      const IDs = [];
      values['parentScreenTypeFilters'].forEach((parentID) => {
        IDs.push(parentID.filterId);
      });
      values['childScreenTypeFilters'].forEach((parentID) => {
        IDs.push(parentID.filterId);
      });
      values['grandchildScreenTypeFilters'].forEach((parentID) => {
        IDs.push(parentID.filterId);
      });
      obj.includeValues.push(...IDs);
      return [obj];
    }
    case 'screenType': {
      obj.type = 'SCREEN_TYPE';
      const IDs = [];
      values['parentScreenTypeFilters']?.forEach((parentID) => {
        IDs.push(parentID.filterId);
      });
      values['childScreenTypeFilters']?.forEach((parentID) => {
        IDs.push(parentID.filterId);
      });
      values['grandchildScreenTypeFilters']?.forEach((parentID) => {
        IDs.push(parentID.filterId);
      });
      values['greatGrandChildScreenTypeFilters']?.forEach(
        (greatGrandChildFilter) => {
          IDs.push(greatGrandChildFilter.filterId);
        }
      );
      obj.includeValues.push(...IDs);
      return [obj];
    }
    case 'screenFormat': {
      obj.type = 'SCREEN_FORMAT';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'package': {
      obj.type = 'PACKAGE';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'facingDirection': {
      obj.type = 'FACING_DIRECTION';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'orientation': {
      obj.type = 'ORIENTATION';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'aspectRatio': {
      obj.type = 'ASPECT_RATIO';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'acceptedFormats': {
      obj.type = 'ACCEPTED_FORMATS';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'screenBookingTime': {
      obj.type = 'SCREEN_BOOKING_TIME';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return obj;
    }
    case 'screenOperatingHours': {
      const temp = [];
      values?.forEach((item) => {
        let tempObj = {};
        tempObj.day = item.filterId;
        tempObj.openingHours = item.openingHours;
        tempObj.closingHours = item.closingHours;
        temp.push(tempObj);
      });
      return temp;
    }
    case 'mediaVehicle': {
      obj.type = 'MEDIA_VEHICLE';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'mediaCategoryName': {
      obj.type = 'MEDIA_CATEGORY_NAME';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'mediaType': {
      obj.type = 'MEDIA_TYPE';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    case 'aimScore': {
      obj.type = 'AIM_SCORE';
      values.forEach((data) => {
        obj.includeValues.push(data.filterId);
      });
      return [obj];
    }
    default: {
      return [obj];
    }
  }
};

export const getRequestBody = (
  includedValue,
  expressions,
  searchText,
  fromSavePlan,
  distance,
  excludeAudienceForScreenFilter,
  allPostCodeData
) => {
  const keys = Object.keys(includedValue);
  const bodyParams = [];
  keys.forEach((data) => {
    (fromSavePlan
      ? getPayloadForSavePlan(data, includedValue[data], expressions, distance)
      : newMethod(
        data,
        includedValue[data],
        expressions,
        excludeAudienceForScreenFilter,
        allPostCodeData
      )
    )?.forEach((param) => bodyParams.push(param));
  });
  return bodyParams?.filter((item) => item?.type !== '');
};

export const getTitles = (title, countryId, distance) => {
  const obj = { mappedTitle: '', mappedName: '', mappedTitleForUI: '' };
  switch (title) {
    case 'SA2':
      obj.mappedTitle = 'SA2';
      obj.mappedName = 'sa2';
      obj.mappedTitleForUI = 'SA2';
      return obj;
    case 'BLOCKLIST':
      obj.mappedTitle = 'BLOCKLIST';
      obj.mappedName = 'blocklist';
      obj.mappedTitleForUI = 'BLOCKLIST';
      return obj;
    case 'BILLBOARD':
      obj.mappedTitle = 'BILLBOARD';
      obj.mappedName = 'billBoards';
      obj.mappedTitleForUI = 'SCREENS';
      return obj;
    case 'SCREENS':
      obj.mappedTitle = 'BILLBOARD';
      obj.mappedName = 'billBoards';
      obj.mappedTitleForUI = 'SCREENS';
      return obj;

    case 'GEOGRAPHY':
      obj.mappedTitle = 'GEOGRAPHY';
      obj.mappedName = 'geography';
      obj.mappedTitleForUI = 'GEOGRAPHY';
      return obj;

    case 'GEO_TYPE':
      obj.mappedTitle = 'POST_CODE';
      obj.mappedName = 'postCode';
      obj.mappedTitleForUI = 'POST CODE';
      return obj;

    case 'SCREEN PRODUCTS':
      obj.mappedTitle = 'PACKAGE';
      obj.mappedName = 'package';
      obj.mappedTitleForUI = 'PACKAGE';
      return obj;

    case 'MEDIA FORMAT':
      obj.mappedTitle = 'MEDIA_FORMAT';
      obj.mappedName = 'mediaFormat';
      obj.mappedTitleForUI = 'MEDIA FORMAT';
      return obj;
    case 'MEDIA_FORMAT':
      obj.mappedTitle = 'MEDIA_FORMAT';
      obj.mappedName = 'mediaFormat';
      obj.mappedTitleForUI = 'MEDIA FORMAT';
      return obj;

    case 'MEDIA OWNER':
      obj.mappedTitle = 'MEDIA_OWNER';
      obj.mappedName = 'mediaOwner';
      obj.mappedTitleForUI = 'MEDIA OWNER';
      return obj;
    case 'MEDIA_OWNER':
      obj.mappedTitle = 'MEDIA_OWNER';
      obj.mappedName = 'mediaOwner';
      obj.mappedTitleForUI = 'MEDIA OWNER';
      return obj;

    case 'AUDIENCES':
      obj.mappedTitle = 'AUDIENCES';
      obj.mappedName = 'audiences';
      obj.mappedTitleForUI = 'AUDIENCES';
      return obj;

    case 'POINTS OF INTEREST':
      obj.mappedTitle = 'POI';
      obj.mappedName = 'interests';
      obj.mappedTitleForUI = 'POINTS OF INTEREST';
      return obj;
    case 'POI':
      obj.mappedTitle = 'POI';
      obj.mappedName = 'interests';
      obj.mappedTitleForUI = `POINTS OF INTEREST [With Radius -${distance}]`;
      return obj;
    case 'SCREEN TYPE':
      obj.mappedTitle = 'SCREEN_TYPE';
      obj.mappedName = 'screenType';
      obj.mappedTitleForUI = 'SCREEN TYPE';
      return obj;
    case 'SCREEN_TYPE':
      obj.mappedTitle = 'SCREEN_TYPE';
      obj.mappedName = 'screenType';
      obj.mappedTitleForUI = 'SCREEN TYPE';
      obj.mappedTitleForUI = countryId === 106 ? 'ENVIRONMENT' : 'SCREEN TYPE';
      return obj;

    case 'SCREEN FORMAT':
      obj.mappedTitle = 'SCREEN_FORMAT';
      obj.mappedName = 'screenFormat';
      obj.mappedTitleForUI = 'SCREEN FORMAT';
      return obj;
    case 'SCREEN_FORMAT':
      obj.mappedTitle = 'SCREEN_FORMAT';
      obj.mappedName = 'screenFormat';
      obj.mappedTitleForUI = 'SCREEN FORMAT';
      return obj;

    case 'PACKAGE':
      obj.mappedTitle = 'PACKAGE';
      obj.mappedName = 'package';
      obj.mappedTitleForUI = 'SCREEN PRODUCTS';
      return obj;

    case 'FACING DIRECTION':
      obj.mappedTitle = 'FACING_DIRECTION';
      obj.mappedName = 'facingDirection';
      obj.mappedTitleForUI = 'FACING DIRECTION';
      return obj;
    case 'FACING_DIRECTION':
      obj.mappedTitle = 'FACING_DIRECTION';
      obj.mappedName = 'facingDirection';
      obj.mappedTitleForUI = 'FACING DIRECTION';
      return obj;

    case 'ORIENTATION':
      obj.mappedTitle = 'ORIENTATION';
      obj.mappedName = 'orientation';
      obj.mappedTitleForUI = 'ORIENTATION';
      return obj;

    case 'ASPECT RATIO':
      obj.mappedTitle = 'ASPECT_RATIO';
      obj.mappedName = 'aspectRatio';
      obj.mappedTitleForUI = 'ASPECT RATIO';
      return obj;
    case 'ASPECT_RATIO':
      obj.mappedTitle = 'ASPECT_RATIO';
      obj.mappedName = 'aspectRatio';
      obj.mappedTitleForUI = 'ASPECT RATIO';
      return obj;

    case 'ACCEPTED FORMATS':
      obj.mappedTitle = 'ACCEPTED_FORMATS';
      obj.mappedName = 'acceptedFormats';
      obj.mappedTitleForUI = 'ACCEPTED FORMATS';
      return obj;
    case 'ACCEPTED_FORMATS':
      obj.mappedTitle = 'ACCEPTED_FORMATS';
      obj.mappedName = 'acceptedFormats';
      obj.mappedTitleForUI = 'ACCEPTED FORMATS';
      return obj;

    case 'SCREEN BOOKING TIME':
      obj.mappedTitle = 'SCREEN_BOOKING_TIME';
      obj.mappedName = 'screenBookingTime';
      obj.mappedTitleForUI = 'SCREEN BOOKING TIME';
      return obj;
    case 'SCREEN_BOOKING_TIME':
      obj.mappedTitle = 'SCREEN_BOOKING_TIME';
      obj.mappedName = 'screenBookingTime';
      obj.mappedTitleForUI = 'SCREEN BOOKING TIME';
      return obj;

    case 'SCREEN OPERATING HOURS':
      obj.mappedTitle = 'SCREEN_OPERATING_HOURS';
      obj.mappedName = 'screenOperatingHours';
      obj.mappedTitleForUI = 'SCREEN OPERATING HOURS';
      return obj;
    case 'SCREEN_OPERATING_HOURS':
      obj.mappedTitle = 'SCREEN_OPERATING_HOURS';
      obj.mappedName = 'screenOperatingHours';
      obj.mappedTitleForUI = 'SCREEN OPERATING HOURS';
      return obj;

    case 'MEDIA VEHICLE':
      obj.mappedTitle = 'MEDIA_VEHICLE';
      obj.mappedName = 'mediaVehicle';
      obj.mappedTitleForUI = 'MEDIA VEHICLE';
      return obj;
    case 'MEDIA_VEHICLE':
      obj.mappedTitle = 'MEDIA_VEHICLE';
      obj.mappedName = 'mediaVehicle';
      obj.mappedTitleForUI = 'MEDIA VEHICLE';
      return obj;

    case 'MEDIA CATEGORY NAME':
      obj.mappedTitle = 'MEDIA_CATEGORY_NAME';
      obj.mappedName = 'mediaCategoryName';
      obj.mappedTitleForUI = 'MEDIA CATEGORY NAME';
      return obj;
    case 'MEDIA_CATEGORY_NAME':
      obj.mappedTitle = 'MEDIA_CATEGORY_NAME';
      obj.mappedName = 'mediaCategoryName';
      obj.mappedTitleForUI = 'MEDIA CATEGORY NAME';
      return obj;

    case 'MEDIA TYPE':
      obj.mappedTitle = 'MEDIA_TYPE';
      obj.mappedName = 'mediaType';
      obj.mappedTitleForUI = 'MEDIA TYPE';
      return obj;

    case 'MEDIA_TYPE':
      obj.mappedTitle = 'MEDIA_TYPE';
      obj.mappedName = 'mediaType';
      obj.mappedTitleForUI = 'MEDIA TYPE';
      return obj;

    case 'AIM SCORE':
      obj.mappedTitle = 'AIM_SCORE';
      obj.mappedName = 'aimScore';
      obj.mappedTitleForUI = 'AIM SCORE';
      return obj;
    case 'AIM_SCORE':
      obj.mappedTitle = 'AIM_SCORE';
      obj.mappedName = 'aimScore';
      obj.mappedTitleForUI = 'AIM SCORE';
      return obj;

    case 'SCREEN TYPE - STATIC/DIGITAL':
    case 'MEDIA_TYPE_PRINT_DIGITAL':
      obj.mappedTitle = 'MEDIA_TYPE_PRINT_DIGITAL';
      obj.mappedName = 'screenType_print_digital';
      obj.mappedTitleForUI = 'SCREEN TYPE - STATIC/DIGITAL';
      return obj;

    case 'SCREEN TYPE - LIGHTED':
    case 'MEDIA_TYPE_LIGHTED':
      obj.mappedTitle = 'MEDIA_TYPE_LIGHTED';
      obj.mappedName = 'screenTypeLighted';
      obj.mappedTitleForUI = 'SCREEN TYPE - LIGHTED';
      return obj;

    case 'MULTI PANEL COUNT':
    case 'MULTI_PANEL_COUNT':
      obj.mappedTitle = 'MULTI_PANEL_COUNT';
      obj.mappedName = 'multiPanelCount';
      obj.mappedTitleForUI = 'MULTI PANEL COUNT';
      return obj;

    case 'PROGRAMMATIC AVAILABILITY':
    case 'PROGRAMMATIC_AVAILABILITY':
      obj.mappedTitle = 'PROGRAMMATIC_AVAILABILITY';
      obj.mappedName = 'programmaticAvailability';
      obj.mappedTitleForUI = 'PROGRAMMATIC AVAILABILITY';
      return obj;

    default:
      return obj;
  }
};

export const getFiltersForIndia = (filters) => {
  const indiaFilters = filters.filter(
    (item) =>
      ![
        'FACING DIRECTION',
        'ORIENTATION',
        'ASPECT RATIO',
        'ACCEPTED FORMATS',
        'SCREEN BOOKING TIME',
        'SCREEN OPERATING HOURS',
      ].includes(item.title)
  );
  // changing title 'SCREEN TYPE' to 'ENVIRONMENT' for India ==> 106
  return indiaFilters.map((obj) =>
    obj.title === 'SCREEN TYPE' ? { ...obj, title: 'ENVIRONMENT' } : obj
  );
};

export const getRadius = (value) => {
  if (value >= 1000) {
    return `${hasMoreThanOneDecimalPlace(value / 1000)} km`;
  }
  return `${value} m`;
};

const hasMoreThanOneDecimalPlace = (number) =>
  number?.toString()?.split('.')[1]?.length > 1
    ? number.toFixed(1)
    : number.toString();

export const getDSPApiURL = (url, campaignIds) => {
  campaignIds.forEach((campaignId, index) => {
    url = index
      ? `${url}&campaignIds=${campaignId}`
      : `${url}?campaignIds=${campaignId}`;
  });
  return url;
};

export const getMediaOwnerData = (oohNameList, countryId) => {
  return [
    {
      title: 'Media Owner',
      value: oohNameList?.mediaOwner ?? '-',
    },
    {
      title: 'Screen Name',
      value: oohNameList?.oohName ?? '-',
    },
    {
      title: 'Screen ID',
      value: oohNameList?.screenId ?? '-',
    },
    {
      title: countryId === 106 ? 'Environment' : 'Screen Type',
      value: oohNameList?.screenTypeChild ?? '-',
    },
    {
      title: 'Address',
      value: oohNameList?.address ?? '-',
    },
  ];
};

export const getDimensionData = (oohNameList) => {
  return [
    {
      title: 'Resolution',
      value:
        oohNameList?.screenWidthInPx && oohNameList?.screenHeightInPx
          ? `${oohNameList?.screenWidthInPx} px x ${oohNameList?.screenHeightInPx} px`
          : `-`,
    },
    {
      title: 'Size',
      value:
        oohNameList?.screenWidthInCm && oohNameList?.screenHeightInCm
          ? `${oohNameList?.screenWidthInCm} cm x ${oohNameList?.screenHeightInCm} cm`
          : `-`,
    },
  ];
};

export const getCreativeDetailsData = (oohNameList) => {
  return [
    {
      title: 'Images',
      Icon: getCreativeDetailIcon(oohNameList?.imagesAllowed),
    },
    {
      title: 'VAST',
      Icon: getCreativeDetailIcon(oohNameList?.vastAllowed),
    },
    {
      title: 'Video',
      Icon: getCreativeDetailIcon(oohNameList?.videoAllowed),
    },
    {
      title: 'Audio',
      Icon: getCreativeDetailIcon(oohNameList?.audioAllowed),
    },
    {
      title: 'HTML',
      Icon: getCreativeDetailIcon(oohNameList?.htmlAllowed),
    },
    {
      title: '3D',
      Icon: getCreativeDetailIcon(oohNameList['3dAllowed']),
    },
  ];
};

const getCreativeDetailIcon = (mediaTypeAllowed) => {
  return mediaTypeAllowed
    ? FaCheck
    : mediaTypeAllowed === false
      ? MdClose
      : 'noData';
};

export const getAPIUrls = (searchParameters, countryId, customerId) => {
  const apiUrls = [];
  searchParameters.forEach((searchParameter) => {
    switch (searchParameter.type) {
      case 'BILLBOARD': {
        apiUrls.push(
          fetch(
            API_URL,
            `/ooh/filter/planner?countryId=${countryId}&customerId=${customerId}&type=billboard,billboard_package`
          )
        );
        break;
      }
      case 'GEOGRAPHY': {
        apiUrls.push(
          fetch(
            API_URL,
            `/ooh/filter/audience/geography?countryId=${countryId}`
          )
        );
        break;
      }
      case 'GEO_TYPE': {
        apiUrls.push(
          fetch(
            API_URL,
            `/ooh/filter/planner?countryId=${countryId}&customerId=${customerId}&type=geo_type`
          )
        );
        break;
      }
      case 'MEDIA_OWNER': {
        apiUrls.push(
          fetch(
            API_URL,
            `/ooh/filter/planner?countryId=${countryId}&customerId=${customerId}&type=media_owner`
          )
        );
        break;
      }
      case 'POI': {
        apiUrls.push(fetch(API_URL, `/ooh/poi?countryId=${countryId}`));
        break;
      }
      case 'SCREEN_TYPE': {
        apiUrls.push(
          fetch(
            API_URL,
            `/ooh/filter/planner?countryId=${countryId}&customerId=${customerId}&type=screen_type`
          )
        );
        break;
      }
      case 'FACING_DIRECTION': {
        apiUrls.push(
          fetch(
            API_URL,
            `/ooh/filter/planner?countryId=${countryId}&customerId=${customerId}&type=facing_direction`
          )
        );
        break;
      }
      case 'ORIENTATION': {
        apiUrls.push(
          fetch(
            API_URL,
            `/ooh/filter/planner?countryId=${countryId}&customerId=${customerId}&type=orientation`
          )
        );
        break;
      }
      case 'SCREEN_FORMAT': {
        apiUrls.push(
          fetch(
            API_URL,
            `/ooh/filter/planner?countryId=${countryId}&customerId=${customerId}&type=screen_format`
          )
        );
        break;
      }
      case 'ASPECT_RATIO': {
        apiUrls.push(
          fetch(
            API_URL,
            `/ooh/filter/planner?countryId=${countryId}&customerId=${customerId}&type=aspect_ratio`
          )
        );
        break;
      }
      case 'ACCEPTED_FORMATS': {
        apiUrls.push(
          fetch(
            API_URL,
            `/ooh/filter/planner?countryId=${countryId}&customerId=${customerId}&type=accepted_formats`
          )
        );
        break;
      }
      case 'SCREEN_BOOKING_TIME': {
        apiUrls.push(
          fetch(API_URL, `/ooh/filter/screenBookingTime/${countryId}`)
        );
        break;
      }
      case 'SCREEN_OPERATING_HOURS': {
        apiUrls.push(
          fetch(
            API_URL,
            `/ooh/filter/planner?countryId=${countryId}&customerId=${customerId}&type=screen_operating_hours`
          )
        );
        break;
      }
      default:
        break;
    }
  });
  return apiUrls;
};

export const getOpenFilterName = (type) => {
  switch (type) {
    case 'MEDIA_OWNER':
      return 'mediaOwner';
    case 'SCREEN_TYPE':
      return 'screenType';
    case 'SCREEN_FORMAT':
      return 'screenFormat';
    case 'BILLBOARD':
      return 'billBoards';
    case 'GEOGRAPHY':
      return 'geography';
    case 'GEO_TYPE':
      return 'postCode';
    case 'POI':
      return 'interests';
    case 'FACING_DIRECTION':
      return 'facingDirection';
    case 'ORIENTATION':
      return 'orientation';
    case 'ASPECT_RATIO':
      return 'aspectRatio';
    case 'ACCEPTED_FORMATS':
      return 'acceptedFormats';
    case 'SCREEN_BOOKING_TIME':
      return 'screenBookingTime';
    case 'SCREEN_OPERATING_HOURS':
      return 'screenOperatingHours';
    default:
      break;
  }
};

export const getAllSearchParameters = (allSearchParameters) => {
  const allFilters = allSearchParameters?.reduce(
    (searchParameters, screenFormatFilter) => {
      screenFormatFilter.searchParameters.forEach((param) => {
        const { type, ...rest } = param;
        if (!searchParameters[type]) {
          searchParameters[type] = { type };
        }
        Object.keys(rest).forEach((key) => {
          if (!searchParameters[type][key]) {
            searchParameters[type][key] = [];
          }
          searchParameters[type][key] = [
            ...new Set([...searchParameters[type][key], ...rest[key]]),
          ];
        });
      });
      return searchParameters;
    },
    {}
  );
  return [...Object.values(allFilters)];
};

export const isObject = (value) =>
  typeof value === OBJECT && value !== null && !Array.isArray(value);
