export default function reducer(
  state = {
    saveSegmentDefinition: '',
  },
  action
) {
  switch (action.type) {
    case 'SAVE_SEGMENT_DEFINITION_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'SAVE_SEGMENT_DEFINITION_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        saveSegmentDefinition: action.payload,
      };
    }
    case 'SAVE_SEGMENT_DEFINITION_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        saveSegmentDefinition: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
