import { put, takeEvery, call } from 'redux-saga/effects';
import post from '../postFormData';

const API_DATA = (payload) => {
  let formData = new FormData(); //formdata object
  formData.append('file', payload.file); //append the values with key, value pair
  return post(
    `audience/pixel/upload/${payload.saveSegmentDefinition.saveSegmentDefinition.id.id}`,
    'POST',
    formData,
    true
  ).then((response) => {
    return response;
  });
};

export const UPLOAD_FILE_SAGA = function* perform_uploadAction() {
  yield takeEvery('UPLOAD_FILE', function* (action) {
    yield put({ type: `${action.payload.type}_STARTED` });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({ type: `${action.payload.type}_SUCCESS`, payload: DATA });
    } catch (error) {
      yield put({ type: `${action.payload.type}_FAILED`, payload: error });
    }
  });
};
