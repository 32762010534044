import { put, takeEvery, call } from 'redux-saga/effects';
import post from '../postApiCaller';

const API_DATA = (payload) => {
  return post('audience/segment/definition/count', 'POST', payload, true).then(
    (response) => {
      return response;
    }
  );
};

export const AUDIENCE_COUNT_SAGA = function* perform_audienceCountAction() {
  yield takeEvery('AUDIENCE_COUNT', function* (action) {
    yield put({ type: 'AUDIENCE_COUNT_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({ type: 'AUDIENCE_COUNT_SUCCESS', payload: DATA });
    } catch (error) {
      yield put({ type: 'AUDIENCE_COUNT_FAILED', payload: error });
    }
  });
};
