import { put, takeEvery, call } from 'redux-saga/effects';
import get from '../../getApiCaller';

const getBrandIds = (brand) => {
  return brand.filterId;
};

const getCityIds = (cityData) => {
  const cityIds =
    cityData &&
    cityData.length &&
    cityData.map((city) => {
      return `cityIds=${city.id}`;
    });
  return cityIds;
};

const getStateIds = (stateData) => {
  const stateIds =
    stateData &&
    stateData.length &&
    stateData.map((state) => {
      return `stateIds=${state.id}`;
    });
  return stateIds;
};

const API_DATA = (payload) => {
  const { countryId, cardData, recaptchaCode } = payload;
  const { state, brand, city } = cardData;

  const brandId = getBrandIds(brand);
  const cityIds = getCityIds(city);
  const stateIds = getStateIds(state);
  return get(
    `places/search?brandId=${brandId}&country=${countryId}${
      stateIds && stateIds.length ? `&${stateIds.join('&')}` : ''
    }${
      cityIds && cityIds.length ? `&${cityIds.join('&')}` : ''
    }&gtoken=${recaptchaCode}`,
    'GET',
    payload,
    true
  ).then((response) => {
    return response;
  });
};

export const GET_CAMPAIGN_SEARCH_PLACE_DATA_SAGA =
  function* perform_getCampaignSearchPlaceData() {
    yield takeEvery('GET_CAMPAIGN_SEARCH_PLACE_DATA', function* (action) {
      yield put({ type: 'GET_CAMPAIGN_SEARCH_PLACE_DATA_STARTED' });
      try {
        const DATA = yield call(API_DATA.bind(this, action.payload));
        if (DATA.status && action.payload.cardId !== 'campaignOption') {
          yield put({
            type: 'GET_ESTIMATED_REACH',
            payload: action.payload,
          });
        }
        yield put({
          type: 'GET_CAMPAIGN_SEARCH_PLACE_DATA_SUCCESS',
          payload: { DATA, id: action.payload.cardId },
        });
      } catch (error) {
        yield put({
          type: 'GET_CAMPAIGN_SEARCH_PLACE_DATA_FAILED',
          payload: error,
        });
      }
    });
  };
