import { createSlice } from '@reduxjs/toolkit';
import { requestStatusConstants } from '../../../Integration/integration-util';

const initialState = {
  uploadPoiPackageResponse: {},
  status: requestStatusConstants.INITIAL,
};

const oohSlice = createSlice({
  name: 'ooh',
  initialState,
  reducers: {
    uploadPoiPackageAction(state, action) {
      state.uploadPoiPackageResponse = initialState.uploadPoiPackageResponse;
      state.status = requestStatusConstants.PROGRESS;
    },
    uploadPoiPackageSuccessAction(state, action) {
      state.uploadPoiPackageResponse = action.payload;
      state.status = requestStatusConstants.SUCCESS;
    },
    uploadPoiPackageFailedAction(state, action) {
      state.uploadPoiPackageResponse = initialState.uploadPoiPackageResponse;
      state.status = requestStatusConstants.FAILED;
    },
    resetUploadPoiPackageResponseAction(state, action) {
      state.uploadPoiPackageResponse = initialState.uploadPoiPackageResponse;
      state.status = initialState.status;
    },
  },
});

export const {
  uploadPoiPackageAction,
  uploadPoiPackageSuccessAction,
  uploadPoiPackageFailedAction,
  resetUploadPoiPackageResponseAction,
} = oohSlice.actions;
export default oohSlice.reducer;
