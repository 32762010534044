import { put, takeLatest, call } from 'redux-saga/effects';
import plannerGetApiCaller from '../../getApiCaller';

const API_DATA = (payload) => {
  return plannerGetApiCaller(`screeniq/customPlanner`, 'Get', true).then(
    (response) => {
      return response;
    }
  );
};

export const GET_SCREEN_PRODUCTS = function* perform_getScreenProducts() {
  yield takeLatest('GET_SCREEN_PRODUCTS', function* (action) {
    yield put({ type: 'GET_SCREEN_PRODUCTS_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({ type: 'GET_SCREEN_PRODUCTS_SUCCESS', payload: DATA });
    } catch (error) {
      yield put({ type: 'GET_SCREEN_PRODUCTS_FAILED', payload: error });
    }
  });
};
