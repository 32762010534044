import { all, takeLatest, call, put } from 'redux-saga/effects';
import {
  fetchOOHSegmentsAction,
  fetchOOHSegmentsFailedAction,
  fetchOOHSegmentsSuccessAction,
} from './oohSegments.reducer';
import { getOOHSegments } from '../../../attribution/ReportNew/reportService';

function* getOOHSegmentsData({ payload }) {
  try {
    const oohSegmentsResponse = yield call(
      () => getOOHSegments(payload.pixelId, payload.startTime, payload.endTime),
      payload
    );
    yield put(fetchOOHSegmentsSuccessAction(oohSegmentsResponse.data.id));
  } catch (error) {
    fetchOOHSegmentsFailedAction();
  }
}

export function* oohSegmentsWatcher() {
  yield all([takeLatest(fetchOOHSegmentsAction, getOOHSegmentsData)]);
}
