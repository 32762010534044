// Overview
export const CAMPAIGN_METRICS = 'CAMPAIGN_METRICS';
export const SET_FRAC_SNAPSHOT = 'SET_FRAC_SNAPSHOT';
export const LIFT_STUDY = 'LIFT_STUDY';

// Campaign
export const VISITS_DATA = 'VISITS_DATA';
export const FRACTIONAL_DATA = 'FRACTIONAL_DATA';
export const FREQUENCY_DATA = 'FREQUENCY_DATA';
export const COHORT_ANALYSIS = 'COHORT_ANALYSIS';

// Audiences
export const DEMOGRAPHIC_DATA = 'DEMOGRAPHIC_DATA';
export const DEVICE_METRICS_DATA = 'DEVICE_METRICS_DATA';
export const DRIVEN_VISITS_DATA = 'DRIVEN_VISITS_DATA';
export const TOP_SEGMENTS_DATA = 'TOP_SEGMENTS_DATA';
export const ATTRIBUTED_HOME_WORK_LOCATIONS_DATA =
  'ATTRIBUTED_HOME_WORK_LOCATIONS_DATA';
export const ATTRIBUTED_AGE_GENDER_DATA = 'ATTRIBUTED_AGE_GENDER_DATA';

//OOH Audiences
export const TOP_OOH_SEGMENTS_DATA = 'TOP_OOH_SEGMENTS_DATA';
export const OOH_HOME_WORK_LOCATIONS_DATA = 'OOH_HOME_WORK_LOCATIONS_DATA';
export const OOH_DEMOGRAPHIC_DATA = 'OOH_DEMOGRAPHIC_DATA';

// Places
export const TOP_PLACES = 'TOP_PLACES';
export const LEADTIME_CONVERSION = 'LEADTIME_CONVERSION';
export const DWELL_TIME = 'DWELL_TIME';
export const VISIT_SHARE_ANALYSIS = 'VISIT_SHARE_ANALYSIS';
export const TOP_BILLBOARDS = 'TOP_BILLBOARDS';
export const DISTANCE_DATA = 'DISTANCE_DATA';

// Compare Filters
export const CAMPAIGN_FILTERS = 'CAMPAIGN_FILTERS';
export const AUDIENCE_FILTERS = 'AUDIENCE_FILTERS';
export const OOH_AUDIENCE_FILTERS = 'OOH_AUDIENCE_FILTERS';

export const PLACES_FILTERS = 'PLACES_FILTERS';

export const CLEAR_REPORT = 'CLEAR_REPORT';

// Audience Cohort analysis
export const PROFILE_COHORT_ANALYSIS = 'PROFILE_COHORT_ANALYSIS';
export const SEGMENT_COHORT_ANALYSIS = 'SEGMENT_COHORT_ANALYSIS';

//DSP Reported Data
export const DSP_REPORTED_CAMPAIGN_DELIVERY_DATA =
  'DSP_REPORTED_CAMPAIGN_DELIVERY_DATA';
export const DSP_REPORTED_IMPRESSIONS_DATA = 'DSP_REPORTED_IMPRESSIONS_DATA';

export const OBSERVED_REACH_FREQUENCY_DATA = 'OBSERVED_REACH_FREQUENCY_DATA';

export const BILLBOARD_REACH_FREQUENCY_DATA = 'BILLBOARD_REACH_FREQUENCY_DATA';
