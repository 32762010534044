export const FLUX_ICON_WHITE = `${require('../assets/images/flux_icon_white.svg')}`;
export const PROFILE_PIC = `${require('../assets/images/profile_pic.svg')}`;
export const MAP_TRACKER = `${require('../assets/images/map_tracker.svg')}`;
export const SIDE_BAR = `${require('../assets/images/side_bar.svg')}`;
export const SIDE_TV = `${require('../assets/images/side_tv.svg')}`;
export const ICON5 = `${require('../assets/images/icon5.svg')}`;
export const ICON7 = `${require('../assets/images/icon7.svg')}`;

// Campaign Options card

export const CHECK_ICON = `${require('../assets/images/check_icon.svg')}`;

// Campaign Summary card

export const ACTIVATE_ICON = `${require('../assets/images/activate_icon.svg')}`;

// Campaign Target Card

export const PENCIL_ICON = `${require('../assets/images/pencil_icon.svg')}`;
export const BANNER_ICON = `${require('../assets/images/banner_icon.svg')}`;
export const CREATIVE_TAG_ICON = `${require('../assets/images/creative_tag_icon.svg')}`;
export const NATIVE_ICON = `${require('../assets/images/native_icon.svg')}`;
export const VIDEO_ICON = `${require('../assets/images/video_icon.svg')}`;
export const CAMPAIGN_CROSS_ICON = `${require('../assets/images/campaign_cross_icon.svg')}`;

// Campaign Index

export const CIRCLE_PLUS_ICON = `${require('../assets/images/circle_plus_icon.svg')}`;

// Marker Images

export const BLUE_MARKER = `${require('../assets/images/blue_marker.svg')}`;
export const AQUA_MARKER = `${require('../assets/images/aqua_marker.svg')}`;
