import { put, takeEvery, call } from 'redux-saga/effects';
import get from '../getApiCaller';

const API_DATA = (payload) => {
  return get(
    `audience/segment/definition?accountId=${payload}`,
    'GET',
    true
  ).then((response) => {
    return response;
  });
};

export const GET_SEGMENT_DEFINITION_SAGA = function* perform_loginAction() {
  yield takeEvery('GET_SEGMENT_DEFINITION', function* (action) {
    yield put({ type: 'GET_SEGMENT_DEFINITION_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({
        type: 'GET_SEGMENT_DEFINITION_SUCCESS',
        payload: DATA,
      });
    } catch (error) {
      yield put({
        type: 'GET_SEGMENT_DEFINITION_FAILED',
        payload: error,
      });
    }
  });
};
