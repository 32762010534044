export default function reducer(
  state = {
    audienceByBrandAndCategory: '',
  },
  action
) {
  switch (action.type) {
    case 'GET_AUDIENCE_BY_BRAND_AND_CATEGORY_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_AUDIENCE_BY_BRAND_AND_CATEGORY_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        audienceByBrandAndCategory: action.payload,
      };
    }
    case 'GET_AUDIENCE_BY_BRAND_AND_CATEGORY_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        audienceByBrandAndCategory: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
