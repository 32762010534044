export default function reducer(
  state = {
    myActivationsList: [],
    loading: false,
  },
  action
) {
  switch (action.type) {
    case 'SAVE_MY_ACTIVATIONS_STARTED': {
      return { ...state, loading: true };
    }
    case 'SAVE_MY_ACTIVATIONS_SUCCESS': {
      return {
        ...state,
        myActivationsList: action.payload?.id,
        loading: false,
      };
    }
    case 'SAVE_MY_ACTIVATIONS_FAILED': {
      return {
        ...state,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
}
