import { put, takeLatest, call } from 'redux-saga/effects';
import plannerGetApiCaller from '../../getApiCaller';

const POI_PACKAGE_API_DATA = () =>
  plannerGetApiCaller(`ooh/poiPackage`, 'GET', true).then(
    (response) => response?.id?.oohPlannerDataList ?? []
  );

export const GET_POI_PACKAGE_SAGA = function* perform_getPoiPackage() {
  yield takeLatest('GET_POI_PACKAGE_FILTER_VALUES', function* () {
    yield put({ type: 'GET_POI_PACKAGE_STARTED' });
    try {
      const poiPackageResponse = yield call(POI_PACKAGE_API_DATA.bind(this));
      yield put({
        type: 'GET_POI_PACKAGE_SUCCESS',
        payload: poiPackageResponse,
      });
    } catch (error) {
      yield put({
        type: 'GET_POI_PACKAGE_FAILED',
        payload: error,
      });
    }
  });
};
