export default function reducer(
  state = {
    activateCampaignResponse: '',
  },
  action
) {
  switch (action.type) {
    case 'ACTIVATE_CAMPAIGN_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'ACTIVATE_CAMPAIGN_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        activateCampaignResponse: action.payload,
      };
    }
    case 'ACTIVATE_CAMPAIGN_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        activateCampaignResponse: action.payload,
      };
    }
    case 'ACTIVATE_CAMPAIGN_RESET': {
      return {
        ...state,
        changingStatus: 'RESET',
        activateCampaignResponse: '',
      };
    }
    default: {
      return state;
    }
  }
}
