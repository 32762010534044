import { put, takeEvery, call } from 'redux-saga/effects';
import get from '../getApiCaller';

const API_DATA = (payload) => {
  return get(
    `audience/filter?countryId=${payload.countryId}&text=${
      payload.value
    }&type=brand${payload.disableCategory ? '' : ',category'}`,
    'Get',
    true
  ).then((response) => {
    return response;
  });
};

export const GET_AUDIENCE_BY_BRAND_AND_CATEGORY_SAGA =
  function* perform_loginAction() {
    yield takeEvery('GET_AUDIENCE_BY_BRAND_AND_CATEGORY', function* (action) {
      yield put({ type: 'GET_AUDIENCE_BY_BRAND_AND_CATEGORY_STARTED' });
      try {
        const DATA = yield call(API_DATA.bind(this, action.payload));
        yield put({
          type: 'GET_AUDIENCE_BY_BRAND_AND_CATEGORY_SUCCESS',
          payload: DATA,
        });
      } catch (error) {
        yield put({
          type: 'GET_AUDIENCE_BY_BRAND_AND_CATEGORY_FAILED',
          payload: error,
        });
      }
    });
  };
