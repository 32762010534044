import { put, takeEvery, call } from 'redux-saga/effects';
import post from '../../postApiCaller';
import { getIncludeParams } from '../../../utils/helper';

const API_DATA = (payload) => {
  const { countryId, accountId, createdBy, customerId, updatedBy, cardData } =
    payload;
  const searchParameters = getIncludeParams(cardData.brand);
  const body = {
    accountId,
    countryId,
    createdBy,
    customerId,
    ruleType: 'CUSTOM',
    frequency: 'ONCE',
    limitAudience: 100,
    name: 'abc',
    period: 90,
    radius: 10,
    searchParameters,
    updatedBy,
  };
  return post(`audience/segment/definition/count`, 'POST', body, true).then(
    (response) => {
      return response;
    }
  );
};

export const GET_ESTIMATED_REACH_SAGA =
  function* perform_getEstimatedReachSaga() {
    yield takeEvery('GET_ESTIMATED_REACH', function* (action) {
      yield put({ type: 'GET_ESTIMATED_REACH_STARTED' });
      try {
        const DATA = yield call(API_DATA.bind(this, action.payload));
        yield put({
          type: 'GET_ESTIMATED_REACH_SUCCESS',
          payload: { DATA, id: action.payload.cardId },
        });
      } catch (error) {
        yield put({
          type: 'GET_ESTIMATED_REACH_FAILED',
          payload: { error, id: action.payload.cardId },
        });
      }
    });
  };
