import { put, takeEvery, call } from 'redux-saga/effects';
import plannerPostApiCaller from '../../postApiCaller';

const getSaveMediaPlanBody = (latLngData) => {
  if (
    latLngData.changingStatus === 'success' &&
    latLngData.latLngData.status &&
    latLngData.latLngData.id &&
    latLngData.latLngData.id.length > 0
  ) {
    const list = [];
    latLngData.latLngData.id.forEach((val) => {
      list.push({ oohId: val.oohId, placeId: val.placeId });
    });
    return list;
  }
  return [];
};

const API_DATA = (payload) => {
  const { countryId, customerId, mediaPlanName, latLngData } = payload;
  const body = getSaveMediaPlanBody(latLngData);
  return plannerPostApiCaller(
    `ooh/saveplan/${countryId}/${customerId}/${mediaPlanName}`,
    'POST',
    body,
    true
  ).then((response) => {
    return response;
  });
};

export const SAVE_MEDIA_PLAN_SAGA = function* perform_saveMediaPlan() {
  yield takeEvery('SAVE_MEDIA_PLAN', function* (action) {
    yield put({ type: 'SAVE_MEDIA_PLAN_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({ type: 'SAVE_MEDIA_PLAN_SUCCESS', payload: DATA });
    } catch (error) {
      yield put({ type: 'SAVE_MEDIA_PLAN_FAILED', payload: error });
    }
  });
};
