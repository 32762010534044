export default function reducer(
  state = {
    uploadFile: {},
  },
  action
) {
  switch (action.type) {
    case 'UPLOAD_FILE_STARTED': {
      return {
        ...state,
        changingStatus: 'ongoing',
        uploadFile: {
          ...state.uploadFile,
          [action.payload.id]: {
            ...state.uploadFile[action.payload.id],
            [action.payload.name]: { loading: true },
          },
        },
      };
    }
    case 'UPLOAD_FILE_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        uploadFile: {
          ...state.uploadFile,
          [action.payload.id]: {
            ...state.uploadFile[action.payload.id],
            [action.payload.name]: {
              data: action.payload.DATA,
              file: action.payload.file,
              loading: false,
            },
          },
        },
      };
    }
    case 'UPLOAD_FILE_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        uploadFile: {
          ...state.uploadFile,
          [action.payload.id]: {
            ...state.uploadFile[action.payload.id],
            [action.payload.name]: {
              data: action.payload.error,
              file: action.payload.file,
            },
          },
        },
      };
    }
    case 'DELETE_FILE_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'DELETE_FILE_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        uploadFile: {
          ...state.uploadFile,
          [action.payload.id]: {
            ...state.uploadFile[action.payload.id],
            [action.payload.name]: {
              data: action.payload.DATA,
              file: action.payload.file,
            },
          },
        },
      };
    }
    case 'DELETE_FILE_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        uploadFile: {
          ...state.uploadFile,
        },
      };
    }
    case 'UPLOAD_FILE_RESET': {
      return {
        ...state,
        changingStatus: 'reset',
        uploadFile: {},
      };
    }
    default: {
      return state;
    }
  }
}
