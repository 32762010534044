import { combineReducers } from 'redux';
import poiCountReducer from './poiCount/poiCount.reducer';
import poiListReducer from './poiList/poiList.reducer';
import poiPackagesCountReducer from './poiPackagesCount/poiPackagesCount.reducer';
import poiPackagesListReducer from './poiPackagesList/poiPackagesList.reducer';
import uploadPoiPackageReducer from './uploadPoiPackage/uploadPoiPackage.reducer';
import deletePoiPackageReducer from './deletePoiPackage/deletePoiPackage.reducer';
import validatePoiPackageNameReducer from './validatePoiPackageName/validatePoiPackageName.reducer';

const oohModuleReducer = combineReducers({
  poiCount: poiCountReducer,
  poiList: poiListReducer,
  poiPackagesCount: poiPackagesCountReducer,
  poiPackagesList: poiPackagesListReducer,
  uploadPoiPackageResponse: uploadPoiPackageReducer,
  deletePoiPackageResponse: deletePoiPackageReducer,
  validatePoiPackageNameResponse: validatePoiPackageNameReducer,
});

export default oohModuleReducer;
