import { put, takeEvery, call } from 'redux-saga/effects';
import get from '../getApiCaller';

const API_DATA = (payload) => {
  const { file, segmentId } = payload;
  return get(
    `audience/pixel/file/${segmentId}?filename=${file.name}`,
    'DELETE',
    payload,
    true
  ).then((response) => {
    return response;
  });
};

export const DELETE_FILE_SAGA = function* perform_deleteFileAction() {
  yield takeEvery('DELETE_FILE', function* (action) {
    yield put({ type: 'DELETE_FILE_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      DATA.name = action.payload.name;
      yield put({ type: 'DELETE_FILE_SUCCESS', payload: DATA });
    } catch (error) {
      yield put({ type: 'DELETE_FILE_FAILED', payload: error });
    }
  });
};
