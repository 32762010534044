import React from 'react';
// import { ReactComponent as AdColony } from 'assets/new-img/tiles/adcolony.svg';
import { ReactComponent as Adform } from 'assets/new-img/tiles/adform.svg';
import { ReactComponent as Adobe } from 'assets/new-img/tiles/adobe.svg';
import { ReactComponent as AdobeAdvertisingCloud } from 'assets/new-img/tiles/adobe-advertising-cloud.svg';
// import { ReactComponent as Affle } from 'assets/new-img/tiles/affle.svg';
import { ReactComponent as AmazonS3 } from 'assets/new-img/tiles/s3.svg';
import { ReactComponent as Amobee } from 'assets/new-img/tiles/amobee.svg';
// import { ReactComponent as AppNexus } from 'assets/new-img/tiles/AppNexus.svg';
import { ReactComponent as CustomDSP } from 'assets/new-img/tiles/custom_dsp.svg';
import { ReactComponent as DV360 } from 'assets/new-img/tiles/dv360.svg';
import { ReactComponent as Facebook } from 'assets/new-img/tiles/facebook.svg';
import { ReactComponent as FileUpload } from 'assets/new-img/tiles-opt/file-upload.svg';
import { ReactComponent as GoogleCloudService } from 'assets/new-img/tiles/gcs.svg';
import { ReactComponent as GoogleAdManager } from 'assets/new-img/tiles/google-ad-manager.svg';
import { ReactComponent as GoogleAds } from 'assets/new-img/tiles/google-ads.svg';
import { ReactComponent as GoogleMarketingPlatform } from 'assets/new-img/tiles/google-mar.svg';
import { ReactComponent as Hubspot } from 'assets/new-img/tiles/hubspot.svg';
import { ReactComponent as InMobi } from 'assets/new-img/tiles/inmobi.svg';
import { ReactComponent as Instagram } from 'assets/new-img/tiles/instagram.svg';
import { ReactComponent as Intercom } from 'assets/new-img/tiles-opt/intercom.svg';
import { ReactComponent as JavaScript } from 'assets/new-img/tiles-opt/javascript.svg';
// import { ReactComponent as Kargo } from 'assets/new-img/tiles/kargo.svg';
import { ReactComponent as MediaMath } from 'assets/new-img/tiles/mediamath.svg';
// import { ReactComponent as MInsights } from 'assets/new-img/tiles/minsights.svg';
// import { ReactComponent as MPlatform } from 'assets/new-img/tiles/mplatform.svg';
import { ReactComponent as Quantcast } from 'assets/new-img/tiles/quantcast.svg';
import { ReactComponent as Rubicon } from 'assets/new-img/tiles/rubicon.svg';
// import { ReactComponent as ReactNative } from 'assets/new-img/tiles-opt/react-native.svg';
import { ReactComponent as Salesforce } from 'assets/new-img/tiles/salesforce.svg';
// import { ReactComponent as SpotAd } from 'assets/new-img/tiles/spotad.svg';
import { ReactComponent as TradeDesk } from 'assets/new-img/tiles/thetradedesk.svg';
import { ReactComponent as Twitter } from 'assets/new-img/tiles/twitter.svg';
import { ReactComponent as Verizon } from 'assets/new-img/tiles/verizon.svg';
import { ReactComponent as YouTube } from 'assets/new-img/tiles/youtube.svg';
import { ReactComponent as AdobeAudienceManager } from 'assets/new-img/tiles/adobe-audience-manager.svg';
import { ReactComponent as Linkedin } from 'assets/new-img/tiles/linkedin.svg';
import { ReactComponent as Tiktok } from 'assets/new-img/tiles/tiktok.svg';
import { ReactComponent as VentesAvenue } from 'assets/new-img/tiles/ventes-avenue.svg';
import { ReactComponent as Vserve } from 'assets/new-img/tiles/vserve.svg';
import { ReactComponent as HiveStack } from 'assets/new-img/tiles/hivestack.svg';
import { ReactComponent as Yahoo } from 'assets/new-img/tiles/yahoo.svg';
import { ReactComponent as AproOTT } from 'assets/new-img/tiles/apro-ott.svg';
import { ReactComponent as AproSocial } from 'assets/new-img/tiles/apro-social.svg';
import { ReactComponent as AproProgram } from 'assets/new-img/tiles/apro-program.svg';
import { ReactComponent as Xandr } from 'assets/new-img/tiles/xandr.svg';
import { ReactComponent as StackAdapt } from 'assets/new-img/tiles/stackAdapt.svg';
import { ReactComponent as Vistar } from 'assets/new-img/tiles/vistar.svg';
import { FAILED, INITIAL, PROGRESS, SUCCESS } from '../commons/constants';

export const mainTabs = [
  { id: 'source', name: 'SOURCES' },
  { id: 'destination', name: 'DESTINATIONS' },
];

export const secondaryTabs = [
  {
    id: 'setup',
    name: 'Setup Source',
    icon: <span>1</span>,
  },
  {
    id: 'schema',
    name: 'Schema Mapping',
    icon: <span>2</span>,
  },
];

export const iconMapping = {
  // 'adcolony': AdColony,
  adform: Adform,
  adobe: Adobe,
  'adobe advertising cloud': AdobeAdvertisingCloud,
  linkedin: Linkedin,
  'adobe audience manager': AdobeAudienceManager,
  tiktok: Tiktok,
  'ttd 1st party seat': TradeDesk,
  vserve: Vserve,
  'ventes avenue': VentesAvenue,
  amobee: Amobee,
  // 'affle': Affle,
  // 'app nexus': AppNexus,
  bigquery: CustomDSP,
  'custom dsp': CustomDSP,
  dv360: DV360,
  'google ads': GoogleAds,
  'media math': MediaMath,
  youtube: YouTube,
  instagram: Instagram,
  // 'kargo': Kargo,
  'google marketing platform': GoogleMarketingPlatform,
  s3: AmazonS3,
  // 'spotad': SpotAd,
  'google ads manager': GoogleAdManager,
  'google ads manager (ddp seat)': GoogleAdManager,
  'google campaign manager 360': GoogleAdManager,
  facebook: Facebook,
  'facebook events': Facebook,
  'facebook custom audiences': Facebook,
  inmobi: InMobi,
  quantcast: Quantcast,
  verizon: Verizon,
  gcs: GoogleCloudService,
  js: JavaScript,
  'upload file': FileUpload,
  // 'react-native': ReactNative,
  intercom: Intercom,
  salesforce: Salesforce,
  hubspot: Hubspot,
  twitter: Twitter,
  // 'mplatform': MPlatform,
  rubicon: Rubicon,
  'trade desk': TradeDesk,
  hivestack: HiveStack,
  yahoo: Yahoo,
  'vserv apro-ott': AproOTT,
  'vserv apro-programmatic': AproProgram,
  'vserv apro-social': AproSocial,
  'trade desk ooh': TradeDesk,
  'yahoo ooh': Yahoo,
  xandr: Xandr,
  'stack adapt': StackAdapt,
  vistar: Vistar,
};

export const tileMap = {
  adcolony: 'adcolony',
  adform: 'adform',
  adobe: 'adobe',
  linkedin: 'linkedin',
  tiktok: 'tiktok',
  'ttd 1st party seat': 'ttd',
  vserve: 'vserve',
  amobee: 'amobee',
  affle: 'affle',
  xandr: 'xandr',
  'custom dsp': 'customdsp',
  dv360: 'dv360',
  'google ads': 'gads',
  'media math': 'mediamath',
  youtube: 'youtube',
  instagram: 'instagram',
  kargo: 'kargo',
  'google marketing platform': 'gmp',
  spotad: 'spotad',
  'google ads manager': 'gadsmanager',
  'google ads manager (ddp seat)': 'gadsmanager',
  'google campaign manager 360': 'gadsmanager',
  facebook: 'facebook',
  'facebook events': 'facebook',
  'facebook custom audiences': 'facebook',
  inmobi: 'inmobi',
  quantcast: 'quantcast',
  verizon: 'verizon',
  gcs: 'gcs',
  twitter: 'twitter',
  mplatform: 'mplatform',
  rubicon: 'rubicon',
  'trade desk': 'ttd',
  yahoo: 'yahoo',
  'stack adapt': 'stackadapt',
  vistar: Vistar,
};

export const reshapeTiles = (tiles) => {
  const newTiles = tiles.reduce((r, a) => {
    a.icon = iconMapping[a.name.toLowerCase()];
    if (!r[a.category]) {
      r[a.category] = [a];
    } else {
      r[a.category].push(a);
    }
    return r;
  }, {});

  return Object.keys(newTiles).map((key) => {
    return { category: key, tiles: newTiles[key] };
  });
};

export const doohRequiredFields = [
  'oohid',
  'latitude',
  'longitude',
  'radius',
  'ad_start_time_utc',
  'ad_play_time',
  'ad_duration_in_seconds',
  'creative',
  'lineid',
];
export const poiRequiredFields = [
  'poi_id',
  'name',
  'latitude',
  'longitude',
  'radius',
];

export const descriptions = {
  'File Upload': {
    description:
      'Our file upload system is a secure data uploading system that allows users to safely upload and encrypt their customer using the browser.',
    usedFor: [
      'Onboarding customer data',
      'Enriching customer data with 3rd party attributes',
      'Building unified customer profiles',
    ],
    required: [
      'Access the customer data file',
      'Permission to upload the file to a 3rd party platform',
    ],
    code: false,
  },
  Website: {
    description:
      'Our JavaScript tracking system is a powerful data collection SDK that is able to track events and attributes about your website visitors and web application customers.',
    usedFor: [
      'Tracking user activity on your website',
      'Onboarding customer data to data warehouse',
    ],
    required: [
      'Access to the code for your website',
      'Ability to write JavaScript code to integrate the website with our library',
      'If you’re not comfortable with that, generally the person who built your website will know what to do. We have helpful guides, but if you’re not a programmer you may want to start with one of our other types of sources',
    ],
    code: true,
  },
  Advertising: {
    description:
      'Our Mediamath integration allows advertisers to onboard campaign data from multiple campaigns for a variety of use cases',
    usedFor: [
      'Building retargeting audiences of users exposed to the ads',
      'Measuring the impact of ads on conversions',
      'Delivering unified campaign analytics with insights',
    ],
    required: [],
    code: true,
  },
  'Cloud Storage': {
    description:
      'Our Amazon S3 integration allows users to connect and onboard  their customer data from their Amazon S3 cloud storage location.',
    usedFor: [
      'Onboarding customer data',
      'Enriching customer data with 3rd party attributes',
      'Building unified customer profiles',
    ],
    required: [],
    code: false,
  },

  CRM: {
    description:
      'Our Mediamath integration allows advertisers to onboard campaign data from multiple campaigns for a variety of use cases',
    usedFor: [
      'Building retargeting audiences of users exposed to the ads',
      'Measuring the impact of ads on conversions',
      'Delivering unified campaign analytics with insights',
    ],
    required: [],
    code: false,
  },
};

export const intHeaderConfig = [
  { id: 'dataSourceName', label: 'Integration Name' },
  { id: 'dataType', label: 'Type' },
  { id: 'categoryType', label: 'Category' },
  { id: 'status', label: 'Status' },
  { id: 'action', label: 'Action' },
];

export const requestStatusConstants = {
  INITIAL: INITIAL,
  PROGRESS: PROGRESS,
  SUCCESS: SUCCESS.toUpperCase(),
  FAILED: FAILED,
};

export const FieldMappings = {
  'Key Prefix': 'Key Prefix',
  'Bucket Region': 'Bucket Region',
  'Secret Token': 'Secret Token',
  'Bucket Name': 'Bucket Name',
  'Access Key': 'Access Key',
  'GCS Credential': 'GCS Credential',
  'TTD Account Manager': 'TTD Account Manager',
  'Partner Seat ID': 'Partner Seat ID',
  'Partner Name': 'Partner Name',
  'Adform Advertiser ID': 'Adform Advertiser ID',
  'Adform Client Division ID': 'Adform Client Division ID',
  'Account Token ID': 'Account Token ID',
  'Member ID': 'Member ID',
  'Advertiser ID': 'Advertiser ID',
  'Account ID': 'Account ID',
  'Campaign ID': 'Campaign ID',
  'Ad Set ID': 'Ad Set ID',
  'Advertiser Secret Key': 'Advertiser Secret Key',
  'Advertiser Access Key': 'Advertiser Access Key',
  CID: 'CID',
  'Campaign Group ID': 'Campaign Group ID',
  'Seat ID': 'Seat ID',
  'Partner ID': 'Partner ID',
  'CPM Override': 'CPM Override',
  'INVOICE CURRENCY': 'INVOICE CURRENCY',
  'ADVERTISER SEAT ID': 'ADVERTISER SEAT ID',
  'PARTNER SEAT ID': 'PARTNER SEAT ID',
  'Ad ID': 'Ad ID',
  secretKey: 'secretKey',
  keyPrefix: 'keyPrefix',
  bucketRegion: 'bucketRegion',
  bucketName: 'bucketName',
  awsSecretKey: 'awsSecretKey',
  awsAccessKey: 'awsAccessKey',
  accessKey: 'accessKey',
};
