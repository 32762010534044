import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Alert, Form } from 'reactstrap';
import { FaUserEdit, FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { GoKey } from 'react-icons/go';
import InputField from '../InputField';
import { loginNew } from 'layouts/Landing/newAuthService';
import { setToken, setEmail } from 'utils/storageUtil';
import './Login.scss';
import SLButton from 'atoms/SLButton/SLButton';

const Login = (props) => {
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const togglePassword = () => setIsShowPassword(!isShowPassword);

  const onSubmit = (values) => {
    loginNew(values).then((res) => {
      if (res && res.data?.status) {
        const { name, email } = res.data.id;
        setToken(res.headers.authorization);
        setEmail(email);
        setErrorMessage('');
        props.history.push('/dashboard');
        window.Intercom('update', { name: name, email: email });
        // window.lifesight.identify({userId: email, email, attributes: { name }});
      } else {
        setErrorMessage(
          (res && res?.data?.message) || 'Please try again later'
        );
      }
    });
  };

  const openSupportChat = () => {
    window.Intercom('show');
  };

  const passwordSuffix = isShowPassword ? (
    <FaRegEyeSlash
      size={16}
      className="cursor-pointer"
      onClick={togglePassword}
    />
  ) : (
    <FaRegEye size={16} className="cursor-pointer" onClick={togglePassword} />
  );

  return (
    <div>
      {errorMessage && (
        <Alert
          color="danger"
          className="mx-4 px-2"
          toggle={() => setErrorMessage('')}
        >
          {errorMessage}
        </Alert>
      )}

      <Form onSubmit={handleSubmit(onSubmit)} className="mx-4 px-2">
        <InputField
          type="email"
          placeholder="Email"
          name="email"
          prefix={<FaUserEdit size={16} />}
          error={errors.email}
          ref={register({
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i,
              message: 'Invalid Email address',
            },
          })}
        />

        <InputField
          type={isShowPassword ? 'text' : 'password'}
          placeholder="Password"
          name="password"
          prefix={<GoKey size={16} />}
          suffix={passwordSuffix}
          error={errors.password}
          ref={register({
            required: 'Password is required',
          })}
        />

        <SLButton type="submit" block className="mb-3 login-btn">
          Login
        </SLButton>
      </Form>

      <div className="clearfix mx-4 px-2">
        <Link to="#" className="float-left link" onClick={openSupportChat}>
          Support
        </Link>
        <Link to="/auth/forgot-password" className="float-right link">
          Forgot Password?
        </Link>
      </div>

      <div className="mt-5 text-center">
        Don&apos;t have any account?{' '}
        <Link to="#" onClick={openSupportChat} className="link">
          Request for an account.
        </Link>
      </div>
    </div>
  );
};

export default Login;
