import { put, takeEvery, call } from 'redux-saga/effects';
import post from '../../postApiCaller';
import COUNTRY_MAP from 'commons/countryMap';

const getSegmentList = (payload) => {
  const countryCode = COUNTRY_MAP.find(
    (country) => country['id'] === payload.countryId
  );
  const { idList, oohId, placeId } = payload;
  if (placeId && oohId) {
    return [`LSO${countryCode['code']}${placeId}`];
  }
  return idList.map((data) => {
    return `LSO${countryCode['code']}${data}`;
  });
};

const API_DATA_FOR_METRICS = (payload) => {
  const segmentList = getSegmentList(payload);

  const body = {
    segmentList: segmentList,
    isoohinsight: true,
    expressions: payload.expressions,
  };
  return post(
    `audience/insight/metrics?customerId=${payload.customerId}`,
    'POST',
    body,
    true
  ).then((response) => {
    return response;
  });
};

export const GET_INSIGHTS_METRICS_SAGA = function* perform_metricsData() {
  yield takeEvery('GET_INSIGHTS_METRICS', function* (action) {
    yield put({ type: 'GET_INSIGHTS_METRICS_STARTED' });
    try {
      const DATA = yield call(API_DATA_FOR_METRICS.bind(this, action.payload));
      // DATA.singleInsight = action.payload.singleInsight;
      // DATA.oohName = action.payload.oohName;
      yield put({ type: 'GET_INSIGHTS_METRICS_SUCCESS', payload: DATA });
    } catch (error) {
      yield put({ type: 'GET_INSIGHTS_METRICS_FAILED', payload: error });
    }
  });
};
