import { createAsyncThunk } from "@reduxjs/toolkit"
import axiosInstance from "services/axios"
import { ROUTE } from "store/serverAPIRoute"

export const getPostcode = createAsyncThunk(ROUTE.GET_POSTCODE_SEARCH, 
    async ({ params }, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.get(ROUTE.GET_POSTCODE_SEARCH, { params })
        return data.data
    } catch (e) {
        rejectWithValue(e)
    }
})