import {
  LS_USER_LOADING,
  LS_USER_SUCCESS,
  LS_USER_FAILURE,
  LS_CUSTOMER_LOADING,
  LS_CUSTOMER_SUCCESS,
  LS_CUSTOMER_FAILURE,
  LS_SELECTED_ACCOUNT,
  LS_ACCESS_DATA,
  LS_LOGOUT,
  LS_UPDATE_UNAUTHORIZED_ERROR_MESSAGE,
  LS_RESET_UNAUTHORIZED_ERROR_MESSAGE,
} from './newAuthConstants';

const initialState = {
  isLoggedIn: false,
  isUserLoading: true,
  userError: '',
  userData: null,
  isCustomerLoading: true,
  customerError: '',
  customerData: null,
  selectedAccount: null,
  access: {},
};

export default function (state, action) {
  state = state || initialState;

  switch (action.type) {
    case LS_USER_LOADING:
      return {
        ...state,
        isUserLoading: true,
        userError: '',
      };

    case LS_USER_SUCCESS:
      return {
        ...state,
        isUserLoading: false,
        userData: action.data,
        userError: '',
      };

    case LS_USER_FAILURE:
      return {
        ...state,
        isUserLoading: false,
        userError: action.error,
      };

    case LS_CUSTOMER_LOADING:
      return {
        ...state,
        isCustomerLoading: true,
        customerError: '',
      };

    case LS_CUSTOMER_SUCCESS:
      return {
        ...state,
        isCustomerLoading: false,
        customerData: action.data,
        customerError: '',
      };

    case LS_CUSTOMER_FAILURE:
      return {
        ...state,
        isCustomerLoading: false,
        customerError: action.error,
      };

    case LS_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccount: action.data,
      };

    case LS_ACCESS_DATA:
      return {
        ...state,
        access: { ...state.access, ...action.data },
      };

    case LS_LOGOUT:
      return initialState;

    case LS_UPDATE_UNAUTHORIZED_ERROR_MESSAGE:
      return {
        ...state,
        unAuthorizedErrorMessage: action.data,
      };

    case LS_RESET_UNAUTHORIZED_ERROR_MESSAGE:
      return {
        ...state,
        unAuthorizedErrorMessage: '',
      };

    default:
      return state;
  }
}
