import { put, takeLatest, call } from 'redux-saga/effects';
import plannerGetApiCaller from '../../getApiCaller';

const API_DATA = (payload) => {
  const { countryId } = payload;
  return plannerGetApiCaller(
    `ooh/poi?countryId=${countryId}`,
    'Get',
    true
  ).then((response) => {
    return response;
  });
};

export const POI_FILTER_VALUES_SAGA = function* perform_getPoiFilterValues() {
  yield takeLatest('POI_FILTER_VALUES', function* (action) {
    yield put({ type: 'FILTER_VALUES_STARTED' });
    if (action.payload.searchFlag) {
      try {
        let status = true;
        const DATA = {
          status,
          id: action.payload.result,
        };

        yield put({
          type: 'FILTER_VALUES_SUCCESS',
          payload: {
            DATA,
            type: action.payload.name,
            value: action.payload.value,
            searchFlag: action.payload.searchFlag,
          },
        });
      } catch (error) {
        yield put({
          type: 'FILTER_VALUES_FAILED',
          payload: error,
        });
      }
    } else {
      try {
        const DATA = yield call(API_DATA.bind(this, action.payload));
        yield put({
          type: 'FILTER_VALUES_SUCCESS',
          payload: { DATA, type: action.payload.name },
        });
      } catch (error) {
        yield put({
          type: 'FILTER_VALUES_FAILED',
          payload: error,
        });
      }
    }
  });
};
