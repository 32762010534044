import { all, takeLatest, call, put } from 'redux-saga/effects';
import { deletePackage } from '../../../ooh/plansService';
import {
  deletePoiPackageAction,
  deletePoiPackageFailedAction,
  deletePoiPackageSuccessAction,
} from './deletePoiPackage.reducer';

function* deletePoiPackage({ payload }) {
  try {
    const deletePoiPackageResponse = yield call(
      () => deletePackage(payload),
      payload
    );
    yield put(deletePoiPackageSuccessAction(deletePoiPackageResponse));
  } catch (error) {
    deletePoiPackageFailedAction();
  }
}

export function* deletePoiPackageWatcher() {
  yield all([takeLatest(deletePoiPackageAction, deletePoiPackage)]);
}
