import { call, put, takeLatest } from 'redux-saga/effects';
import plannerGetApiCaller from '../../getApiCaller';

const API_DATA = (payload) => {
  const { customerId, accountId, countryId } = payload;
  return plannerGetApiCaller(
    `ooh/activation/list?customerId=${customerId}&accountId=${accountId}&countryId=${countryId}`,
    'GET',
    true
  ).then((response) => {
    return response;
  });
};

export const SAVE_MY_ACTIVATIONS_SAGA = function* perform_setMyActivations() {
  yield takeLatest('SAVE_MY_ACTIVATIONS', function* (action) {
    yield put({ type: 'SAVE_MY_ACTIVATIONS_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({ type: 'SAVE_MY_ACTIVATIONS_SUCCESS', payload: DATA });
    } catch (error) {
      yield put({ type: 'SAVE_MY_ACTIVATIONS_FAILED', payload: error });
    }
  });
};
