import {
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  LOG_OUT_SUCCESS,
  EMAIL_DATA,
  SET_SELECTED_ACCOUNT,
} from '../constants/actionType';

var initialState = {
  loginSuccess: null,
  loginFailure: null,
  isLoading: false,
  accountData: null,
  selectedAccountId: null,
};

export default function reducer(state, action) {
  state = state || initialState;

  switch (action.type) {
    case LOG_IN_SUCCESS:
      return Object.assign({}, state, {
        loginSuccess: action.data,
        loginFailure: null,
        isLoading: false,
      });

    case LOG_IN_FAILURE:
      return Object.assign({}, state, {
        loginFailure: action.error || 'Something went wrong.',
        loginSuccess: null,
        isLoading: false,
        accountData: null,
        selectedAccountId: null,
      });

    case LOG_OUT_SUCCESS:
      return initialState;
    // return Object.assign({}, state, {});

    case EMAIL_DATA:
      return Object.assign({}, state, {
        accountData: action.data,
      });

    case SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccountId: action.data,
      };

    default:
      return state;
  }
}
