export default function reducer(
  state = {
    categories: '',
  },
  action
) {
  switch (action.type) {
    case 'GET_CATEGORIES_LIST_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'GET_CATEGORIES_LIST_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        categories: action.payload,
      };
    }
    case 'GET_CATEGORIES_LIST_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        categories: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
