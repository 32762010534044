import { all } from 'redux-saga/effects';
import { LOGIN_SAGA } from './loginSaga';
import { FILTER_VALUES_SAGA } from './getFilterValues/index';
import { GET_LAT_LNG_SAGA, GET_LAT_LNG_RESET_SAGA } from './getLatLng/index';
import {
  GET_INSIGHTS_DATA_SAGA,
  GET_INSIGHTS_DATA_RESET_SAGA,
} from './getInsightsData/index';
import { GET_INSIGHTS_METRICS_SAGA } from './getMetricsData/index';
import { POI_FILTER_VALUES_SAGA } from './getPoiFilterValues/index';
import { SAVE_MEDIA_PLAN_SAGA } from './saveMediaPlan/index';
import { SAVE_MY_ACTIVATIONS_SAGA } from './setMyActicvations';
import { GET_POI_POINTS } from './getPOIPointsData';
import { GET_SCREEN_PRODUCTS } from './getScreenProductsData';
import { GET_POI_PACKAGE_SAGA } from './getPoiPackage/index';

// Wrap all sagas in a container
const rootSaga = function* rootSaga() {
  yield all([
    LOGIN_SAGA(),
    FILTER_VALUES_SAGA(),
    GET_LAT_LNG_SAGA(),
    GET_LAT_LNG_RESET_SAGA(),
    POI_FILTER_VALUES_SAGA(),
    GET_INSIGHTS_DATA_SAGA(),
    GET_INSIGHTS_DATA_RESET_SAGA(),
    GET_INSIGHTS_METRICS_SAGA(),
    SAVE_MEDIA_PLAN_SAGA(),
    SAVE_MY_ACTIVATIONS_SAGA(),
    GET_POI_POINTS(),
    GET_SCREEN_PRODUCTS(),
    GET_POI_PACKAGE_SAGA(),
  ]);
};

export default rootSaga;
