import { put, takeEvery, call } from 'redux-saga/effects';
import get from '../../getApiCaller';

const API_DATA = (payload) => {
  const { fileData } = payload;
  const { id } = fileData;
  return get(`campaign/creative/delete?filepath=${id}`, 'DELETE', true).then(
    (response) => {
      return response;
    }
  );
};

export const DELETE_FILE_SAGA = function* performDeleteAction() {
  yield takeEvery('DELETE_FILE', function* (action) {
    yield put({ type: `DELETE_FILE_STARTED` });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({
        type: `DELETE_FILE_SUCCESS`,
        payload: {
          DATA,
          id: action.payload.cardId,
          name: action.payload.name,
        },
      });
    } catch (error) {
      yield put({
        type: `DELETE_FILE_FAILED`,
        payload: {
          error,
          id: action.payload.cardId,
          name: action.payload.name,
        },
      });
    }
  });
};
