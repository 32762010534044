import { API_URL } from 'config/config';
import { fetch, fetchZipFile } from 'utils/httpUtil';

const BASE_URL = API_URL;

// Report Home
export function getPixelInfo(pixelId) {
  return fetch(BASE_URL, `/pixel/${pixelId}`);
}

export function getChannels(pixelId) {
  return fetch(BASE_URL, `/attr/${pixelId}/channel`);
}

export function getCustomValues(pixelId) {
  return fetch(BASE_URL, `/attr/${pixelId}/customvalues/exposed`);
}

// export function downloadReport(pixelId, visitType) {
//   return BASE_URL + `/attr/${pixelId}/export/excel/${visitType}`;
// }

// Overview
export function getCampaignMetrics(pixelId, start, end) {
  return fetch(BASE_URL, `/attr/v2/${pixelId}/metrices?st=${start}&et=${end}`);
}

export function getVisitLift(pixelId) {
  return fetch(BASE_URL, `/pixel/${pixelId}/lift`);
}

export function fetchV2Snapshot(pixelId, start, end, fracField) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/snapshot/exposed/v2?st=${start}&et=${end}&fracField=${fracField}`
  );
}

// Campaign, Audiences
export function getV2ImpressionClickVisits(
  pixelId,
  start,
  end,
  visitType,
  channelValues,
  customValues
) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/${visitType}/visitsdata?st=${start}&et=${end}${getChannelsQueryString(
      channelValues
    )}tz=GMT%2B8&${getCustomValueQueryString(customValues)}`
  );
}

// Campaign
export function getFractionalAttributionV2(
  pixelId,
  start,
  end,
  visitType,
  fracField
) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/fractional/${visitType}/v2?st=${start}&et=${end}&fracField=${fracField}`
  );
}

export function getImpressionFrequency(
  pixelId,
  start,
  end,
  visitType,
  channelValues,
  customValues
) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/adfreqvisitpattern/${visitType}?st=${start}&et=${end}${getChannelsQueryString(
      channelValues
    )}${getCustomValueQueryString(customValues)}`
  );
}

export function fetchCohortAnalysis(
  pixelId,
  start,
  end,
  visitType,
  channelValues
) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/cohert/${visitType}?st=${start}&et=${end}${getChannelsQueryString(
      channelValues
    )}`
  );
}

// Audiences
export function getDemographic(
  pixelId,
  start,
  end,
  visitType,
  channelValues,
  customValues
) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/visits/${visitType}/gender?st=${start}&et=${end}${getChannelsQueryString(
      channelValues
    )}${getCustomValueQueryString(customValues)}`
  );
}

export function getOOHDemographic(
  pixelId,
  countryId,
  channelValues,
  customValues
) {
  return fetch(
    BASE_URL,
    `/attr/ooh/${pixelId}/genderAndAge?countryId=${countryId}`
  );
}

export function getDeviceMetrics(
  pixelId,
  start,
  end,
  visitType,
  channelValues,
  customValues
) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/visits/${visitType}/devicemetrics?st=${start}&et=${end}${getChannelsQueryString(
      channelValues
    )}${getCustomValueQueryString(customValues)}`
  );
}

export function getDrivenVisits(pixelId, start, end, visitType, customValues) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/${visitType}/drivenvisitsdata?st=${start}&et=${end}&tz=GMT%2B8&${getCustomValueQueryString(
      customValues
    )}`
  );
}

// Places
export function getTopPlaces(
  pixelId,
  start,
  end,
  visitType,
  channelValues,
  customValues
) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/visits/${visitType}/places?st=${start}&et=${end}${getChannelsQueryString(
      channelValues
    )}${getCustomValueQueryString(customValues)}`
  );
}

export function getTopInsights(
  pixelId,
  start,
  end,
  visitType,
  channelValues,
  customValues,
  isAttributed
) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/userVisitInsights/${visitType}?st=${start}&et=${end}&isAttributed=${isAttributed}${getChannelsQueryString(
      channelValues
    )}tz=GMT%2B8&${getCustomValueQueryString(customValues)}`
  );
}

export function getLeadTimeToConversion(
  pixelId,
  start,
  end,
  visitType,
  channelValues,
  customValues
) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/visits/${visitType}/leadtimetoconversion?st=${start}&et=${end}${getChannelsQueryString(
      channelValues
    )}${getCustomValueQueryString(customValues)}`
  );
}

export function getDwellTime(
  pixelId,
  start,
  end,
  visitType,
  channelValues,
  customValues
) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/visits/${visitType}/dwelltime?st=${start}&et=${end}${getChannelsQueryString(
      channelValues
    )}${getCustomValueQueryString(customValues)}`
  );
}

export function getVisitShareAnalysis(pixelId, start, end) {
  return fetch(
    BASE_URL,
    `/attr/visitShareAnalysis/${pixelId}?st=${start}&et=${end}`
  );
}

export function getTopBillboards(pixelId, start, end) {
  return fetch(
    BASE_URL,
    `/attr/topBillboards/${pixelId}?st=${start}&et=${end}`
  );
}

export function getDistance(pixelId, start, end, visitType, countryId) {
  return fetch(
    BASE_URL,
    `/attr/v2/${pixelId}/visits/${visitType}/distance?startTime=${start}&endTime=${end}&countryId=${countryId}`
  );
}

// export function getDistance(pixelId, start, end, visitType) {
//   return fetch(BASE_URL, `/attr/${pixelId}/visits/${visitType}/distance?startTime=${start}&endTime=${end}`);
// }

export function getTopSegments(pixelId, start, end, visitType) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/${visitType}/topsegments?st=${start}&et=${end}`
  );
}
export function getVisitLiftStats(pixelId) {
  return fetch(BASE_URL, `/pixel/visitlift/stats/${pixelId}`);
}

export function getHomeWorkHex(pixelId) {
  return fetch(BASE_URL, `/attr/${pixelId}`);
}

function getCustomValueQueryString(customValues) {
  if (customValues === undefined) {
    return '';
  }

  let queryString = '';
  let lastString = '';
  for (const cv of customValues) {
    lastString = '';
    lastString = btoa(cv);
    queryString += 'customValues=' + lastString + '&';
  }
  return queryString;
}

function getChannelsQueryString(channelValues) {
  if (channelValues === undefined) {
    return '';
  }

  let queryString = '&';
  for (const cv of channelValues) {
    queryString += 'channels=' + cv + '&';
  }
  return queryString;
}

// Audience Cohort Analysis
// Audience Profile cohort Analysis
export function getAudienceProfileCohortAnalysis({
  pixelId,
  startTime,
  endTime,
  profileFilterXAxis,
  profileFilterYAxis,
}) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/crosses/?st=${startTime}&et=${endTime}&x-axis=${profileFilterXAxis}&y-axis=${profileFilterYAxis}`
  );
}

// Audience Segment cohort Analysis
export function getAudienceSegmentCohortAnalysis({
  pixelId,
  startTime,
  endTime,
  segmentFilterXAxis,
  segmentFilterYAxis,
}) {
  return fetch(
    BASE_URL,
    `/attr/${pixelId}/audience/crosses/?st=${startTime}&et=${endTime}&x-axis=${segmentFilterXAxis}&y-axis=${segmentFilterYAxis}`
  );
}

export function getOOHSegments(pixelId, start, end) {
  return fetch(
    BASE_URL,
    `/attr/ooh/${pixelId}/topsegments?st=${start}&et=${end}`
  );
}

export function getHomeWorkLocations(pixelId, visitType) {
  return fetch(BASE_URL, `/attr/ooh/homework/${pixelId}?type=${visitType}`);
}
export function getAgeGenderData(pixelId, moduleType) {
  return fetch(
    BASE_URL,
    `/${moduleType === 'plan_insights' ? 'screeniq' : 'pixel'}/experian-age-gender/insight?${moduleType === 'plan_insights' ? 'planId' : 'pixelId'}=${pixelId}`
  );
}

export const getReportsData = (pixelId) =>
  fetchZipFile(`${BASE_URL}/attr/v2/${pixelId}/export/excel`);

// Reach & Frequency
export function fetchReachFrequency(pixelId) {
  return fetch(BASE_URL, `/attr/state-wise-reach-metric/${pixelId}`);
}

export function fetchBillboardLevelReachFrequency(pixelId) {
  return fetch(BASE_URL, `/attr/billboard-wise-reach-metric/${pixelId}`);
}
