import { put, takeEvery, call } from 'redux-saga/effects';
import get from '../getApiCaller';

const API_DATA = (payload) => {
  return get(
    `places/cities?${
      payload.enableSearchString ? payload.value : 'stateIds=' + payload
    }`,
    'GET',
    true
  ).then((response) => {
    return response;
  });
};

export const GET_CITIES_SAGA = function* perform_loginAction() {
  yield takeEvery('GET_CITIES', function* (action) {
    yield put({ type: 'GET_CITIES_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({
        type: 'GET_CITIES_SUCCESS',
        payload: DATA,
      });
    } catch (error) {
      yield put({
        type: 'GET_CITIES_FAILED',
        payload: error,
      });
    }
  });
};
