import fetch from 'cross-fetch';
import { checkStatus, parseJSON } from '../utils/responseHandler';
import { API_URL } from 'config/config';
const URL = API_URL + '/';
// import { URL } from "../common/constants";

// const constant = {
//   URL: "API URL"
// };

// Make an api call
export default async (url, method = 'post', body, autherization) => {
  var headers = {};
  if (autherization) {
    headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
    };
  } else {
    headers = {
      'Content-Type': 'application/json',
    };
  }
  return fetch(`${URL}${url}`, {
    method,
    body: JSON.stringify(body),
    headers: headers,
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error) => {
      throw error;
    });
};
