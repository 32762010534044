import { createSlice } from '@reduxjs/toolkit';
import { requestStatusConstants } from '../../../Integration/integration-util';

const initialState = {
  poiList: [],
  status: requestStatusConstants.INITIAL,
};

const oohSlice = createSlice({
  name: 'ooh',
  initialState,
  reducers: {
    fetchPoiListAction(state, action) {
      state.poiList = initialState.poiList;
      state.status = requestStatusConstants.PROGRESS;
    },
    fetchPoiListSuccessAction(state, action) {
      state.poiList = action.payload;
      state.status = requestStatusConstants.SUCCESS;
    },
    fetchPoiListFailedAction(state, action) {
      state.poiList = initialState.poiList;
      state.status = requestStatusConstants.FAILED;
    },
    resetPoiListAction(state, action) {
      state.poiList = initialState.poiList;
      state.status = initialState.status;
    },
  },
});

export const {
  fetchPoiListAction,
  fetchPoiListSuccessAction,
  fetchPoiListFailedAction,
  resetPoiListAction,
} = oohSlice.actions;
export default oohSlice.reducer;
