import {
  LS_USER_LOADING,
  LS_USER_SUCCESS,
  LS_USER_FAILURE,
  LS_CUSTOMER_LOADING,
  LS_CUSTOMER_SUCCESS,
  LS_CUSTOMER_FAILURE,
  LS_SELECTED_ACCOUNT,
  LS_ACCESS_DATA,
  LS_LOGOUT,
  LS_UPDATE_UNAUTHORIZED_ERROR_MESSAGE,
  LS_RESET_UNAUTHORIZED_ERROR_MESSAGE,
} from './newAuthConstants';

import {
  fetchUserData,
  fetchCustomerData,
} from 'layouts/Landing/newAuthService';

export function setUserCustomerData(email) {
  return (dispatch) => {
    dispatch(setUserLoading());
    dispatch(setCustomerLoading());

    fetchUserData(email).then((res) => {
      if (res && res.data && res.data.id) {
        const userData = res.data.id;
        let selectedAccount = null;
        const { customerId, selectedAccountId, accountDataList } = userData;
        if (
          selectedAccountId &&
          accountDataList &&
          Array.isArray(accountDataList)
        ) {
          selectedAccount = accountDataList.find(
            (ac) => ac.accountId === selectedAccountId
          );
          if (!selectedAccount) {
            selectedAccount = accountDataList[0];
          }
          const {
            isAttribution,
            isAudience,
            isOOHPlanning,
            attributionWriteAccess,
            audienceWriteAccess,
            isCIP,
          } = selectedAccount;
          dispatch(
            setAccessData({
              isAttribution,
              isAudience,
              isAttributionWrite: isAttribution && attributionWriteAccess,
              isAudienceWrite: isAudience && audienceWriteAccess,
              isScreenIq: isOOHPlanning,
              isIntegration: isCIP,
            })
          );
        }

        dispatch(setUserSuccess(userData));
        dispatch(setSelectedAccount(selectedAccount));

        fetchCustomerData(customerId).then((response) => {
          if (response && response.data && response.data.id) {
            const customerData = response.data.id;
            const { isFirstPartyData, adminAudienceData } = customerData;
            const audienceAccess =
              adminAudienceData && Array.isArray(adminAudienceData)
                ? adminAudienceData[0]
                : {};
            const {
              isExternalActivation = false,
              isLifesighActivation = false,
            } = audienceAccess;
            dispatch(
              setAccessData({
                isFirstParty: isFirstPartyData,
                isExtActivation: isExternalActivation,
                isLsActivation: isLifesighActivation,
                isActivation: isExternalActivation || isLifesighActivation,
              })
            );
            dispatch(setCustomerSuccess(customerData));
          } else {
            dispatch(setCustomerFailure('Error fetching customer data'));
          }
        });
      } else {
        dispatch(setUserFailure('Error fetching user data'));
      }
    });
  };
}

export function newLogout() {
  return {
    type: LS_LOGOUT,
  };
}

export function setUserLoading() {
  return {
    type: LS_USER_LOADING,
  };
}

export function setUserSuccess(data) {
  return {
    type: LS_USER_SUCCESS,
    data,
  };
}

export function setUserFailure(error) {
  return {
    type: LS_USER_FAILURE,
    error,
  };
}

export function setCustomerLoading() {
  return {
    type: LS_CUSTOMER_LOADING,
  };
}

export function setCustomerSuccess(data) {
  return {
    type: LS_CUSTOMER_SUCCESS,
    data,
  };
}

export function setCustomerFailure(error) {
  return {
    type: LS_CUSTOMER_FAILURE,
    error,
  };
}

export function setSelectedAccount(data) {
  return {
    type: LS_SELECTED_ACCOUNT,
    data,
  };
}

export function setAccessData(data) {
  return {
    type: LS_ACCESS_DATA,
    data,
  };
}

export const updateUnAuthorizedErrorMessage = (data) => {
  return {
    type: LS_UPDATE_UNAUTHORIZED_ERROR_MESSAGE,
    data,
  };
};

export function resetErrorMessage() {
  return {
    type: LS_RESET_UNAUTHORIZED_ERROR_MESSAGE,
  };
}
