export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const EMAIL_DATA = 'EMAIL_DATA';
export const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT';

export const ENTITY_FAILURE = 'ENTITY_FAILURE';
export const ENTITY_CREATE = 'ENTITY_CREATE';
export const ENTITY_UPDATE = 'ENTITY_UPDATE';
export const ENTITY_FETCH = 'ENTITY_FETCH';
export const ENTITY_DELETE = 'ENTITY_DELETE';
export const SELECT_ENTITY_ITEM = 'SELECT_ENTITY_ITEM';
export const CLEAR_ENTITY_LIST = 'CLEAR_ENTITY_LIST';

export const FETCH_SOMETHING = 'FETCH_SOMETHING';

export const COUNTRIES_DATA = 'COUNTRIES_DATA';
export const FETCH_ACCOUNT_DETAILS = 'FETCH_ACCOUNT_DETAILS';
export const FETCH_USER_DATA = 'FETCH_USER_DATA';

export const FETCH_ACCOUNT_DATA = 'FETCH_ACCOUNT_DATA';
export const ADD_ACCOUNT_DATA = 'ADD_ACCOUNT_DATA';
export const EXISTING_ACCOUNT_DATA = 'EXISTING_ACCOUNT_DATA';
export const ADD_USER_DATA = 'ADD_USER_DATA';
export const ADD_CUSTOMER_DATA = 'ADD_CUSTOMER_DATA';
export const EXISTING_USER_DATA = 'EXISTING_USER_DATA';

export const UNMOUNT = 'UNMOUNT';
