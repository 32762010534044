import { put, takeEvery, call } from 'redux-saga/effects';
import post from '../postApiCaller';

const API_DATA = (payload) => {
  return post('audience/segment/definition', 'POST', payload, true).then(
    (response) => {
      return response;
    }
  );
};

export const SAVE_SEGMENT_DEFINITION_SAGA =
  function* perform_audienceCountAction() {
    yield takeEvery('SAVE_SEGMENT_DEFINITION', function* (action) {
      yield put({ type: 'SAVE_SEGMENT_DEFINITION_STARTED' });
      try {
        const DATA = yield call(API_DATA.bind(this, action.payload));
        yield put({ type: 'SAVE_SEGMENT_DEFINITION_SUCCESS', payload: DATA });
      } catch (error) {
        yield put({ type: 'SAVE_SEGMENT_DEFINITION_FAILED', payload: error });
      }
    });
  };
