import { put, takeEvery, call } from 'redux-saga/effects';
import get from '../getApiCaller';

const BRAND_AND_CATEGORY_API_DATA = (payload) => {
  const { countryId } = payload;
  return get(`ooh/poi?countryId=${countryId}`, 'Get', true).then((response) => {
    return response;
  });
};

export const GET_BRAND_AND_CATEGORY_SAGA =
  function* perform_getBrandAndCategory() {
    yield takeEvery('GET_BRAND_AND_CATEGORY', function* (action) {
      yield put({ type: 'GET_AUDIENCE_BY_BRAND_AND_CATEGORY_STARTED' });
      try {
        const DATA = yield call(
          BRAND_AND_CATEGORY_API_DATA.bind(this, action.payload)
        );
        yield put({
          type: 'GET_AUDIENCE_BY_BRAND_AND_CATEGORY_SUCCESS',
          payload: DATA,
        });
      } catch (error) {
        yield put({
          type: 'GET_AUDIENCE_BY_BRAND_AND_CATEGORY_FAILED',
          payload: error,
        });
      }
    });
  };
