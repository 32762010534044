import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import history from 'utils/history';
import { ReactComponent as HumanShape } from 'assets/img/leadform/human-shape.svg';
import { ReactComponent as LockShape } from 'assets/img/leadform/lock-shape.svg';
import { ReactComponent as RocketShape } from 'assets/img/leadform/rocket-shape.svg';
import './LeadForm.scss';
import SLButton from 'atoms/SLButton/SLButton';

const LeadForm = ({ message, redirectTo, closeLeadForm }) => {
  const handleClose = () => {
    if (closeLeadForm) {
      closeLeadForm();
    } else {
      history.push(redirectTo);
      window.Intercom('hide');
    }
  };

  const requestAccess = () => {
    window.Intercom('showNewMessage', `Requesting access for ${message}`);
    if (closeLeadForm) {
      closeLeadForm();
    } else {
      history.push(redirectTo);
    }

    // window.Intercom('onShow', () => {
    //   const sendButton = document.querySelector('[aria-label="Send a message…"]');
    //   if(sendButton && message) {
    //     console.log('bt', sendButton)
    //     sendButton.click()
    //   }
    // })
  };

  return (
    <Modal isOpen={true} centered className="leadform-modal">
      <ModalBody>
        <div className="mt-3 text-center">
          <h3 className="leadform-title mx-3">
            Oops! You don&apos;t seem to have access here
          </h3>
          <div className="leadform-image mx-auto my-4">
            <HumanShape size={28} className="human-shape" />
            <LockShape size={28} className="lock-shape" />
            <RocketShape size={28} className="rocket-shape" />
          </div>
          <p className="leadform-text my-4">
            No worries! Just request your account manager to enable this
            feature.
          </p>
          <div className="d-flex justify-content-between leadform-btn-group">
            <SLButton outline onClick={handleClose} title="Close"></SLButton>
            <SLButton onClick={requestAccess} title="Request Access"></SLButton>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LeadForm;
