import { put, takeEvery, call } from 'redux-saga/effects';
import post from '../postApiCaller';
import { getIncludeExcludeParams } from '../../utils/helper';

const getRequestBody = (includeExcludeValues) => {
  const keys = Object.keys(includeExcludeValues);
  const bodyParams = [];
  keys.forEach((data) => {
    const params = getIncludeExcludeParams(data, includeExcludeValues[data]);
    bodyParams.push(params);
  });
  return bodyParams;
};

const API_DATA = (payload) => {
  const { countryId, includeExcludeValues } = payload;
  let body = [];
  if (includeExcludeValues) {
    body = getRequestBody(includeExcludeValues);
  } else {
    body = payload.body;
  }
  return post(`audience/filter/latlong/${countryId}`, 'Post', body, true).then(
    (response) => {
      return response;
    }
  );
};

export const AUDIIENCE_BY_TYPE_SAGA = function* perform_loginAction() {
  yield takeEvery('DISCOVER_AUDIIENCE_BY_TYPE', function* (action) {
    yield put({ type: 'DISCOVER_AUDIIENCE_BY_TYPE_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      DATA.filterName = action.payload.filterName;
      if (DATA.id === undefined) {
        DATA.id = [];
      }
      yield put({
        type: 'DISCOVER_AUDIIENCE_BY_TYPE_SUCCESS',
        payload: DATA,
      });
      yield put({
        type: 'GET_INSIGHTS_DATA_BUILDER_STATUS',
      });
    } catch (error) {
      yield put({
        type: 'DISCOVER_AUDIIENCE_BY_TYPE_FAILED',
        payload: error,
      });
    }
  });
};

export const GET_BRANDS_OR_CATEGORIES_TYPE_SAGA =
  function* perform_loginAction() {
    yield takeEvery('GET_BRANDS_OR_CATEGORIES', function* (action) {
      yield put({ type: 'GET_BRANDS_OR_CATEGORIES_STARTED' });
      try {
        const DATA = yield call(API_DATA.bind(this, action.payload.url));
        DATA.filterName = action.payload.filterName;
        yield put({
          type: 'GET_BRANDS_OR_CATEGORIES_SUCCESS',
          payload: DATA,
        });
      } catch (error) {
        yield put({
          type: 'GET_BRANDS_OR_CATEGORIES_FAILED',
          payload: error,
        });
      }
    });
  };
