import React, { Suspense, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Switch, Redirect } from 'react-router-dom';
import Sidebar from 'layouts/Sidebar/Sidebar';
import LsLoader from 'atoms/LsLoader/LsLoader';
import { isAuthenticatedNew } from 'layouts/Landing/newAuthService';
import { getEmail } from 'utils/storageUtil';
import { setUserCustomerData } from 'layouts/Landing/newAuthActions';
import routes from 'layouts/route-config';
import PrivateRoute from 'layouts/PrivateRoute';
import COUNTRY_MAP from 'commons/countryMap';
import './MainLayout.scss';

const MainLayout = (props) => {
  const email = getEmail();
  useEffect(() => {
    props.dispatch(setUserCustomerData(email));
  }, [email, props?.selectedAccount?.accountId]);

  const selectedCountry = useMemo(() => {
    return props.selectedAccount
      ? COUNTRY_MAP.find((c) => c.id === props.selectedAccount.countryId)
      : null;
  }, [props.selectedAccount]);

  if (!isAuthenticatedNew()) {
    props.history.push('/auth/login');
    return null;
  }

  const getRoutes = (routes, layout = '') => {
    return routes
      .filter((r) => r.path !== '/auth')
      .map((route, i) => {
        const isAccess = route.accessKey ? props.access[route.accessKey] : true;
        if (route.routes) {
          return getRoutes(route.routes, route.path);
        }

        return (
          <PrivateRoute
            key={i}
            exact={true}
            path={layout + route.path}
            isAccess={isAccess}
            accessMessage={route.accessMessage}
            fallback={route.fallback}
            component={route.component}
          />
        );
      });
  };

  // console.log('com', getRoutes(routes));

  return (
    <div className="new-main-page-wrapper">
      <Sidebar {...props} selectedCountry={selectedCountry} />

      <main className="main-content">
        {props.isUserLoading && <LsLoader wrapperClassName="vh-100" />}

        {/*{!navigator.onLine &&
          <div>You are offline. Please connect to network and try again</div>
        }*/}

        {props.userError && <div>Something went wrong. Try again later</div>}

        {!(props.isUserLoading || props.userError) && (
          <Suspense fallback={<LsLoader wrapperClassName="vh-100" />}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/" to="/dashboard" />
            </Switch>
          </Suspense>
        )}
      </main>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isUserLoading: state.authorization.isUserLoading,
  isCustomerLoading: state.authorization.isCustomerLoading,
  userError: state.authorization.userError,
  userData: state.authorization.userData,
  selectedAccount: state.authorization.selectedAccount,
  access: state.authorization.access,
});

export default connect(mapStateToProps)(MainLayout);
