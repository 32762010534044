import React from 'react';
import { Button } from 'reactstrap';

const SLButton = (props) => {
  const {
    className,
    size,
    dataDismiss,
    children,
    title,
    onClick,
    outline,
    disabled,
    id,
    style,
    block,
    type,
    textCapitalize,
  } = props;

  return (
    <Button
      id={id}
      outline={outline}
      size={size}
      block={block}
      style={style}
      color="secondary"
      className={
        textCapitalize ? `text-uppercase  ${className}` : `${className}`
      }
      onClick={onClick && onClick}
      data-dismiss={dataDismiss}
      type={type}
      disabled={disabled}
    >
      {children}
      {title}
    </Button>
  );
};

export default SLButton;
