export const TTD = 'ttd';
export const DOOH = 'dooh';
export const OOH = 'ooh';
export const PDOH = 'PDOH';
export const DIGITAL = 'DIGITAL';
export const FOOTFALL = 'FOOTFALL';
export const NO_FOOTFALL = 'NO_FOOTFALL';
export const HIVESTACK = 'hivestack';
export const FACEBOOK = 'facebook';
export const AMOBEE = 'amobee';
export const TWITTER = 'twitter';
export const GOOGLEMP = 'gmp';
export const PlanDeleteMessage = 'Plan deleted successfully.';
export const SCREEN_FORMAT = 'screenFormat';
export const PACKAGE = 'PACKAGE';
export const isTTD = 'isTTD';
export const isHiveStack = 'isHiveStack';
export const OBJECT = 'object';
export const POSTCODE = 'PostCode';
export const PACKAGES = 'packages';
export const GCS = 'GCS';
export const GCS_CREDENTIAL = 'GCS Credential';
export const DESTINATION = 'destination';
export const WEBSITE = 'Website';
export const ADVERTISING = 'Advertising';
export const INITIAL = 'INITIAL';
export const PROGRESS = 'PROGRESS';
export const FAILED = 'FAILED';
export const SUCCESS = 'SUCCESS';
export const pending = {
    pending: true,
    fulfilled: false,
    rejected: false
}
export const fulfilled = {
    pending: false,
    fulfilled: true,
    rejected: false
}
export const rejected = {
    pending: false,
    fulfilled: false,
    rejected: true
}

export const HOME_POSTCODE = 'home_postcode';
export const WORK_POSTCODE = 'work_postcode';
export const HOME_POSTCODE_LABEL = 'Home Postcode';
export const WORK_POSTCODE_LABEL = 'Work Postcode';


export const POSTCODES=[{
    value: WORK_POSTCODE,
    label: 'Work PostCode',
    data: [],
    isPostCode: true
  },
  {
    value: HOME_POSTCODE,
    label: 'Home PostCode',
    data: [],
    isPostCode: true
  }]