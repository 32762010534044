import { createSlice } from '@reduxjs/toolkit';
import { requestStatusConstants } from '../../../Integration/integration-util';

const initialState = {
  deletePoiPackageResponse: {},
  status: requestStatusConstants.INITIAL,
};

const oohSlice = createSlice({
  name: 'ooh',
  initialState,
  reducers: {
    deletePoiPackageAction(state, action) {
      state.deletePoiPackageResponse = initialState.deletePoiPackageResponse;
      state.status = requestStatusConstants.PROGRESS;
    },
    deletePoiPackageSuccessAction(state, action) {
      state.deletePoiPackageResponse = action.payload;
      state.status = requestStatusConstants.SUCCESS;
    },
    deletePoiPackageFailedAction(state, action) {
      state.deletePoiPackageResponse = initialState.deletePoiPackageResponse;
      state.status = requestStatusConstants.FAILED;
    },
    resetDeletePoiPackageResponseAction(state, action) {
      state.deletePoiPackageResponse = initialState.deletePoiPackageResponse;
      state.status = initialState.status;
    },
  },
});

export const {
  deletePoiPackageAction,
  deletePoiPackageSuccessAction,
  deletePoiPackageFailedAction,
  resetDeletePoiPackageResponseAction,
} = oohSlice.actions;
export default oohSlice.reducer;
