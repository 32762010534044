import { createSlice } from '@reduxjs/toolkit';
import { requestStatusConstants } from '../../../Integration/integration-util';

const initialState = {
  poiPackagesCount: 0,
  status: requestStatusConstants.INITIAL,
};

const oohSlice = createSlice({
  name: 'ooh',
  initialState,
  reducers: {
    fetchPoiPackagesCountAction(state, action) {
      state.poiPackagesCount = initialState.poiPackagesCount;
      state.status = requestStatusConstants.PROGRESS;
    },
    fetchPoiPackagesCountSuccessAction(state, action) {
      state.poiPackagesCount = action.payload;
      state.status = requestStatusConstants.SUCCESS;
    },
    fetchPoiPackagesCountFailedAction(state, action) {
      state.poiPackagesCount = initialState.poiPackagesCount;
      state.status = requestStatusConstants.FAILED;
    },
    resetPoiPackagesCountAction(state, action) {
      state.poiPackagesCount = initialState.poiPackagesCount;
      state.status = initialState.status;
    },
  },
});

export const {
  fetchPoiPackagesCountAction,
  fetchPoiPackagesCountFailedAction,
  fetchPoiPackagesCountSuccessAction,
  resetPoiPackagesCountAction,
} = oohSlice.actions;
export default oohSlice.reducer;
