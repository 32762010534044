import fetch from 'cross-fetch';
import { checkStatus, parseJSON } from '../utils/responseHandler';
import { API_URL } from 'config/config';
const URL = API_URL + '/';

// Make an api call
const plannerPostApiCaller = async (
  url,
  method = 'post',
  body,
  autherization
) => {
  let headers = {};
  if (autherization) {
    headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token'),
    };
  } else {
    headers = {
      'Content-Type': 'application/json',
    };
  }
  return fetch(`${URL}${url}`, {
    method,
    body: JSON.stringify(body),
    headers,
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((error) => {
      throw error;
    });
};
export default plannerPostApiCaller;
