import axios from 'axios';
import { getToken } from './storageUtil';
import store from 'store/store';

export function fetch(url, endpoint) {
  store.dispatch({
    type: 'API_PROCESS',
  });
  return axios.get(url + endpoint, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getToken(),
    },
  });
}

export function store2(url, data) {
  store.dispatch({
    type: 'API_PROCESS',
  });
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getToken(),
    },
  });
}
//for file download
export function store3(url, data) {
  store.dispatch({
    type: 'API_PROCESS',
  });
  return axios.post(url, data, {
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      Authorization: getToken(),
    },
    responseType: 'arraybuffer',
  });
}

export function getFileContent(url) {
  store.dispatch({
    type: 'API_PROCESS',
  });
  return axios.get(url, {
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      Authorization: getToken(),
    },
    responseType: 'arraybuffer',
  });
}

export function update(url, data) {
  store.dispatch({
    type: 'API_PROCESS',
  });
  return axios.put(url, data, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getToken(),
    },
  });
}

export function destroy(url, endpoint) {
  store.dispatch({
    type: 'API_PROCESS',
  });
  return axios.delete(url + endpoint, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getToken(),
    },
  });
}

export function store1(url, data) {
  store.dispatch({
    type: 'API_PROCESS',
  });
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getToken(),
    },
  });
}

export const fetchZipFile = (url) => {
  store.dispatch({
    type: 'API_PROCESS',
  });
  return axios({
    method: 'GET',
    url: url,
    responseType: 'arraybuffer',
    headers: {
      Authorization: getToken(),
    },
  });
};
