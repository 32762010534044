import { put, takeLatest, call } from 'redux-saga/effects';
import get from '../getApiCaller';

const API_DATA = (payload) => {
  const { countryId, customerId, accountId, value } = payload;
  return get(
    `audience/filter?countryId=${countryId}&customerId=${customerId}&accountId=${accountId}&text=${value}&type=brand,category,geography,appname,appcategory,interest,geo_behaviour,first_party`,
    'Get',
    true
  ).then((response) => {
    return response;
  });
};

export const DISCOVER_AUDIENCE_AUTOCOMPLETE_SAGA =
  function* perform_loginAction() {
    yield takeLatest('DISCOVER_AUDIENCE_AUTOCOMPLETE', function* (action) {
      yield put({ type: 'DISCOVER_AUDIENCE_AUTOCOMPLETE_STARTED' });
      try {
        const DATA = yield call(API_DATA.bind(this, action.payload));
        yield put({
          type: 'DISCOVER_AUDIENCE_AUTOCOMPLETE_SUCCESS',
          payload: DATA,
        });
      } catch (error) {
        yield put({
          type: 'DISCOVER_AUDIENCE_AUTOCOMPLETE_FAILED',
          payload: error,
        });
      }
    });
  };
