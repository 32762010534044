import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import axios from 'axios';
import history from 'utils/history';
import { clearEmail, clearToken } from 'utils/storageUtil';
import rootReducer from './rootReducer';
import { logoutSuccess } from 'auth/authAction';
import createSagaMiddleware from 'redux-saga';
// import SagaContainer from 'audience/builder/redux/saga';
// import OohSagaContainer from 'ooh/Planner/redux/saga';
// import campaignSagaContainer from 'campaign/redux/saga';
import { rootSaga } from './rootSaga';
import { updateUnAuthorizedErrorMessage } from '../layouts/Landing/newAuthActions';

const middleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const customMiddleWare = (store) => (next) => (action) => {
  // console.log("Middleware triggered:", action);
  if (action.type === 'API_PROCESS') {
    axios.interceptors.response.use(
      (response) => {
        return response ? response : next(action);
      },
      (error) => {
        // Do something with response error
        if (error.response) {
          console.log('error', error.response);
          if (error.response.status === 401) {
            store.dispatch(logoutSuccess());
            clearToken();
            clearEmail();
            history.push('/auth/login');
          } else if (error.response.status === 403) {
            if (window.location.pathname.includes('dashboard')) {
              localStorage.setItem('isDashboardPopUp', 'true');
            }
            store.dispatch(updateUnAuthorizedErrorMessage(error.response.data));
            history.push('/notAuthorized');
          } else {
            return error.response;
          }
        }
      }
    );
  }
  next(action);
};

// Enable Redux DevTool in development
const composeEnhancers =
  process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
      : compose
    : compose;

const middlewares = [
  thunkMiddleware,
  customMiddleWare,
  sagaMiddleware,
  middleware,
];

if (process.env.NODE_ENV === `production`) {
  middlewares.push(logger);
}

const store = composeEnhancers(applyMiddleware(...middlewares))(createStore)(
  rootReducer
);

// sagaMiddleware.run(SagaContainer);
// sagaMiddleware.run(OohSagaContainer);
// sagaMiddleware.run(campaignSagaContainer);
sagaMiddleware.run(rootSaga);

export default store;
