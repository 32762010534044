export default function reducer(
  state = {
    poiPoints: [],
  },
  action
) {
  switch (action.type) {
    case 'GET_POI_POINTS_STARTED': {
      return {
        ...state,
        changingStatus: 'ongoing',
      };
    }
    case 'GET_POI_POINTS_SUCCESS': {
      return {
        ...state,
        poiPoints: action?.payload?.id ?? [],
        changingStatus: 'success',
      };
    }
    case 'GET_POI_POINTS_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
      };
    }
    default: {
      return state;
    }
  }
}
