import { all, takeLatest, call, put } from 'redux-saga/effects';
import { fetchPoiList } from '../../../ooh/plansService';
import {
  fetchPoiListAction,
  fetchPoiListFailedAction,
  fetchPoiListSuccessAction,
} from './poiList.reducer';

function* getPoiList({ payload }) {
  try {
    const poiListResponse = yield call(() => fetchPoiList(payload), payload);
    yield put(fetchPoiListSuccessAction(poiListResponse));
  } catch (error) {
    fetchPoiListFailedAction();
  }
}

export function* poiListWatcher() {
  yield all([takeLatest(fetchPoiListAction, getPoiList)]);
}
