import { combineReducers } from 'redux';
import campaignPageBrandData from './getCreateCampaignBrandDataReducer';
import stateData from './getCampaignStateDataReducer';
import cityData from './getCampaignCityDataReducer';
import searchPlaceData from './getCampaignSearchPlaceDataReducer';
import audienceData from './getAudienceDataReducer';
import estimatedReach from './getEstimatedReachReducer';
import uploadFile from './uploadFileReducer';
import activateCampaignResponse from './activateCampignReducer';

// Wrap all reducers in a container
export default combineReducers({
  campaignPageBrandData,
  stateData,
  cityData,
  searchPlaceData,
  audienceData,
  estimatedReach,
  uploadFile,
  activateCampaignResponse,
});
