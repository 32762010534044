import { put, takeEvery, call } from 'redux-saga/effects';
import get from '../getApiCaller';
import getAppUrlCaller from '../getApiCallerApp';

const API_DATA = (payload) => {
  return get(`audience/channel/list/${payload}`, 'GET', true).then(
    (response) => {
      return response;
    }
  );
};

export const GET_ACTIVATE_CHANNEL_LIST_SAGA = function* perform_loginAction() {
  yield takeEvery('GET_ACTIVATE_CHANNEL_LIST', function* (action) {
    yield put({ type: 'GET_ACTIVATE_CHANNEL_LIST_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({
        type: 'GET_ACTIVATE_CHANNEL_LIST_SUCCESS',
        payload: DATA,
      });
    } catch (error) {
      yield put({
        type: 'GET_ACTIVATE_CHANNEL_LIST_FAILED',
        payload: error,
      });
    }
  });
};

const API_DATA_TOKEN = (payload) => {
  return getAppUrlCaller(`channelData.json`, 'GET', true).then((response) => {
    return response;
  });
};

export const GET_ACTIVATE_CHANNEL_FOR_TOKEN_SAGA =
  function* perform_loginAction() {
    yield takeEvery('GET_ACTIVATE_CHANNEL_LIST_FOR_TOKEN', function* (action) {
      yield put({ type: 'GET_ACTIVATE_CHANNEL_LIST_FOR_TOKEN_STARTED' });
      try {
        const DATA = yield call(API_DATA_TOKEN.bind(this));
        yield put({
          type: 'GET_ACTIVATE_CHANNEL_LIST_FOR_TOKEN_SUCCESS',
          payload: DATA,
        });
      } catch (error) {
        yield put({
          type: 'GET_ACTIVATE_CHANNEL_LIST_FOR_TOKEN_FAILED',
          payload: error,
        });
      }
    });
  };
