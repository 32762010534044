import { put, takeEvery, call } from 'redux-saga/effects';
import get from '../../getApiCaller';

const getStateIds = (stateData) => {
  const data = stateData.map((state) => {
    return `stateIds=${state.id}`;
  });
  return data;
};

const API_DATA = (payload) => {
  const { stateData } = payload;
  const stateIds = getStateIds(stateData);
  return get(
    `places/cities?${stateIds.length && stateIds.join('&')}`,
    'GET',
    payload,
    true
  ).then((response) => {
    return response;
  });
};

export const GET_CAMPAIGN_CITY_DATA_SAGA =
  function* perform_getCampaignCityData() {
    yield takeEvery('GET_CAMPAIGN_CITY_DATA', function* (action) {
      yield put({ type: 'GET_CAMPAIGN_CITY_DATA_STARTED' });
      try {
        const DATA = yield call(API_DATA.bind(this, action.payload));
        yield put({
          type: 'GET_CAMPAIGN_CITY_DATA_SUCCESS',
          payload: { DATA, id: action.payload.id },
        });
      } catch (error) {
        yield put({
          type: 'GET_CAMPAIGN_CITY_DATA_FAILED',
          payload: error,
        });
      }
    });
  };
