import { put, takeEvery, call } from 'redux-saga/effects';
import plannerPostApiCaller from '../../postApiCaller';
import COUNTRY_MAP from 'commons/countryMap';

const getSegmentList = (payload) => {
  const countryCode = COUNTRY_MAP.find(
    (country) => country['id'] === payload.countryId
  );
  const { idList, oohId, placeId } = payload;
  if (placeId && oohId) {
    return [`LSO${countryCode['code']}${placeId}`];
  }
  return idList.map((data) => {
    return `LSO${countryCode['code']}${data}`;
  });
};

const API_DATA = (payload) => {
  const segmentList = getSegmentList(payload);

  const body = {
    segmentlist: segmentList,
    isoohinsight: true,
    expressions: payload.expressions,
  };
  return plannerPostApiCaller(
    'audience/insight/aggregate',
    'POST',
    body,
    true
  ).then((response) => {
    return response;
  });
};

export const GET_INSIGHTS_DATA_SAGA = function* perform_insightsData() {
  yield takeEvery('GET_INSIGHTS_DATA', function* (action) {
    yield put({ type: 'GET_INSIGHTS_DATA_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      DATA.singleInsight = action?.payload?.singleInsight;
      DATA.oohName = action?.payload?.oohName;
      yield put({ type: 'GET_INSIGHTS_DATA_SUCCESS', payload: DATA });
    } catch (error) {
      yield put({ type: 'GET_INSIGHTS_DATA_FAILED', payload: error });
    }
  });
};

export const GET_INSIGHTS_DATA_RESET_SAGA = function* perform_insightsData() {
  yield takeEvery('GET_INSIGHTS_DATA_RESET', function* (action) {
    yield put({
      type: 'GET_INSIGHTS_DATA_RESET_SUCCESS',
      payload: action.payload,
    });
    // try {
    //   const DATA = yield call(API_DATA.bind(this, action.payload));
    //   DATA.singleInsight = action.payload.singleInsight;
    //   DATA.oohName = action.payload.oohName;
    //   yield put({ type: "GET_INSIGHTS_DATA_SUCCESS", payload: DATA });
    // } catch (error) {
    //   yield put({ type: "GET_INSIGHTS_DATA_FAILED", payload: error });
    // }
  });
};
