import { all, takeLatest, call, put } from 'redux-saga/effects';
import { uploadPackage } from '../../../ooh/plansService';
import {
  uploadPoiPackageAction,
  uploadPoiPackageFailedAction,
  uploadPoiPackageSuccessAction,
} from './uploadPoiPackage.reducer';

function* uploadPoiPackage({ payload }) {
  try {
    const uploadPoiPackageResponse = yield call(
      () => uploadPackage(payload),
      payload
    );
    yield put(uploadPoiPackageSuccessAction(uploadPoiPackageResponse));
  } catch (error) {
    uploadPoiPackageFailedAction();
  }
}

export function* uploadPoiPackageWatcher() {
  yield all([takeLatest(uploadPoiPackageAction, uploadPoiPackage)]);
}
