import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Alert, Form } from 'reactstrap';
import { BiChevronsLeft, BiMailSend } from 'react-icons/bi';
import InputField from '../InputField';
import { sendPasswordResetLink } from 'layouts/Landing/newAuthService';
import SLButton from 'atoms/SLButton/SLButton';
import '../Login/Login.scss';

const ForgotPassword = () => {
  const { register, handleSubmit, errors } = useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);

  const onSubmit = (values) => {
    console.log('submit', values);
    sendPasswordResetLink(values.email).then((res) => {
      if (res && res.data && res.data.status && res.data.id) {
        setIsEmailSent(true);
        setErrorMessage('');
      } else {
        setErrorMessage(res.data.id.message || 'Please try again later');
      }
    });
  };

  return (
    <div className="mx-4 px-2">
      {!isEmailSent ? (
        <>
          {errorMessage && (
            <Alert color="danger" toggle={() => setErrorMessage('')}>
              {errorMessage}
            </Alert>
          )}

          <p className="text-center">
            Enter your registered Email Address to reset password
          </p>

          <Form onSubmit={handleSubmit(onSubmit)} className="">
            <InputField
              type="email"
              placeholder="Email"
              name="email"
              error={errors.email}
              ref={register({
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i,
                  message: 'Invalid Email address',
                },
              })}
            />

            <SLButton type="submit" block className="mb-3">
              Send Password Reset Link
            </SLButton>
          </Form>
        </>
      ) : (
        <div className="text-center">
          <BiMailSend size={100} style={{ color: '#6bd098' }} />
          <p>
            Password reset link has been sent to your email. Please check your
            mailbox!
          </p>
        </div>
      )}

      <div className="text-center mt-5">
        <Link to="/auth/login" className="link">
          <BiChevronsLeft size={18} /> Go back to Login
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
