import { put, takeLatest, call } from 'redux-saga/effects';
import { getRequestBody } from 'ooh/Planner/utils/helper';
import plannerPostApiCaller from '../../postApiCaller';

const hasMoreThanOneDecimalPlace = (number) =>
  number?.toString()?.split('.')[1]?.length > 1
    ? number.toFixed(1)
    : number.toString();

const getAdditionalParams = (includedValue, audienceScore, distance) => {
  const AUDIENCE_PRESENT =
    includedValue &&
    Object.hasOwn(includedValue, 'audiences') &&
    includedValue.audiences.length;
  const DISTANCE_PRESENT =
    includedValue &&
    Object.hasOwn(includedValue, 'interests') &&
    includedValue.interests.length;
  if (AUDIENCE_PRESENT && DISTANCE_PRESENT) {
    return `&audienceScore=${audienceScore}&distance=${hasMoreThanOneDecimalPlace(distance / 1000)}`;
  }
  if (AUDIENCE_PRESENT) {
    return `&audienceScore=${audienceScore}`;
  }
  if (DISTANCE_PRESENT) {
    return `&distance=${hasMoreThanOneDecimalPlace(distance / 1000)}`;
  }
  return '';
};

const API_DATA = (payload) => {
  const {
    allPostCodeData,
    audienceScore,
    countryId,
    customerId,
    distance,
    includedValue,
    excludeAudienceForScreenFilter,
    expressions,
    searchText,
  } = payload;
  if (includedValue) {
    const body = getRequestBody(
      includedValue,
      expressions,
      searchText,
      false,
      null,
      excludeAudienceForScreenFilter,
      allPostCodeData
    );
    const additionalParams = getAdditionalParams(
      includedValue,
      audienceScore,
      distance
    );
    return plannerPostApiCaller(
      `ooh/include?countryId=${countryId}&customerId=${customerId}${additionalParams}`,
      'Post',
      body,
      true
    ).then((response) => response);
  }
  return plannerPostApiCaller(
    `ooh/include?countryId=${countryId}&customerId=${customerId}`,
    'Post',
    [],
    true
  ).then((response) => response);
};

export const GET_LAT_LNG_SAGA = function* perform_getLatLngSaga() {
  yield takeLatest('GET_LAT_LNG', function* (action) {
    yield put({ type: 'GET_LAT_LNG_STARTED' });
    try {
      const DATA = yield call(API_DATA.bind(this, action.payload));
      yield put({
        type: 'GET_LAT_LNG_SUCCESS',
        payload: {
          DATA,
          type: action.payload.name,
          poiDistance: action.payload.distance,
        },
      });
      yield put({
        type: 'GET_INSIGHTS_DATA_STATUS',
      });
    } catch (error) {
      yield put({
        type: 'GET_LAT_LNG_FAILED',
        payload: error,
      });
    }
  });
};

export const GET_LAT_LNG_RESET_SAGA = function* perform_getLatLngSaga() {
  yield takeLatest('GET_LAT_LNG_RESET', function* (action) {
    yield put({ type: 'GET_LAT_LNG_RESET_SUCCESS', payload: action.payload });
  });
};
