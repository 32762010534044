export default function reducer(
  state = {
    audienceByType: '',
  },
  action
) {
  switch (action.type) {
    case 'DISCOVER_AUDIIENCE_BY_TYPE_STARTED': {
      return { ...state, changingStatus: 'ongoing' };
    }
    case 'DISCOVER_AUDIIENCE_BY_TYPE_SUCCESS': {
      return {
        ...state,
        changingStatus: 'success',
        audienceByType: { ...state.audienceByType, ...action.payload },
      };
    }
    case 'DELETE_DISCOVER_AUDIIENCE_BY_TYPE': {
      return {
        ...state,
        changingStatus: 'success',
        audienceByType: action.payload,
      };
    }
    case 'DISCOVER_AUDIIENCE_BY_TYPE_FAILED': {
      return {
        ...state,
        changingStatus: 'failed',
        audienceByType: action.payload,
      };
    }
    case 'DISCOVER_AUDIIENCE_BY_TYPE_RESET': {
      return {
        ...state,
        changingStatus: 'reset',
        audienceByType: '',
      };
    }
    default: {
      return state;
    }
  }
}
